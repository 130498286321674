import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {AuthorInfo, BasicPopoverItem, BindingSearchCriteria, BindingSearchRestEndpoint, Dictionary, GeneralType, ImportTime, InternationalizedString, PreferencesDto} from "../../apina-digiweb";
import {NgForm} from "@angular/forms";
import {IPopoverItemWithGeneralType, SearchReferenceData, SearchService, ValueWithLabel} from "./search.service";
import {IPopoverItem, PopoverFormatter} from "../popover-picker/popover-picker.component";
import {LocalDate} from "@js-joda/core";
import {Observable, of, Subscription} from "rxjs";
import {BoolWrapper} from "../../utils/observable-utils";
import {map, shareReplay, switchMap} from "rxjs/operators";
import {isAfter, parseISODate} from "../../utils/date";
import * as _ from "lodash";
import {NavigationService} from "../navigation.service";
import {LoggingService} from "../logging.service";
import {ErrorService} from "../error.service";
import {SettingsService} from "../settings.service";
import {AccountService} from "../account/account.service";
import {BasicInfoService} from "../basic-info.service";
import {TranslateService} from "@ngx-translate/core";
import {IPopoverItemProvider, IPopoverResult} from "../popover-picker/popover-picker-async.component";
import {BasicInMemoryProvider} from "../popover-picker/in-memory-provider";

@Component({
    selector: "app-binding-search-form",
    template: `
        <form #form="ngForm">
            <div class="form-group row mb-0">

                <!-- hakulause -->
                <div class="col-12 mb-3">
                    <div class="d-flex justify-content-between">
                        <input type="text" class="form-control" [(ngModel)]="criteria.query" id="query" name="query"
                               attr.aria-label="{{'form.search.text-search'|translate}}"
                               appAutofocus appValidQuerySyntax [placeholder]="'form.search.text-search' | translate" style="z-index: 2">

                        <button class="btn btn-kk-blue btn-block ms-3" attr.aria-label="{{'form.search.submit'|translate}}" style="width: 9rem; max-width: 20%;"
                                (click)="doSubmit($event)"
                                [disabled]="form.invalid" [ngbTooltip]="'form.search.submit' | translate"><i class="fa fa-search fa-lg fa-fw"></i>&nbsp;{{'form.search.submit'|translate}}</button>
                        <button type="button" class="btn btn-kk-gray ms-3" attr.aria-label="{{'search.tip.toggle'|translate}}" (click)="toggleHelp($event)"
                                [ngbTooltip]="'search.tip.toggle' | translate"><i class="fa fa-question fa-lg"></i></button>
                    </div>

                    <div *ngIf="showHelp" class="help-block" [innerHTML]="'form.help.term' | translate"></div>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-12">
                    <div class="child-relationship" style="z-index: 1"><i></i></div>

                    <label class="checkbox-inline me-3 mb-0">
                        <input type="checkbox" [(ngModel)]="criteria.requireAllKeywords" name="allKeywords"> {{'form.require-all' | translate}}
                    </label>
                    <label class="checkbox-inline me-3 mb-0">
                        <input type="checkbox" [(ngModel)]="criteria.fuzzy" name="fuzzy"> {{'form.fuzzy' | translate}}
                    </label>
                    <span class="labelish me-2" translate>form.query-targets.prefix</span>
                    <label class="checkbox-inline me-3 mb-0">
                        <input type="checkbox" [(ngModel)]="criteria.queryTargetsOcrText" [disabled]="!criteria.queryTargetsMetadata"
                               name="queryTargetsOcrText"> {{'form.query-targets-ocr-text' | translate}}
                    </label>

                    <label class="checkbox-inline me-3 mb-0">
                        <input type="checkbox" [(ngModel)]="criteria.queryTargetsMetadata" [disabled]="!criteria.queryTargetsOcrText"
                               name="queryTargetsMetadata"> {{'form.query-targets-metadata' | translate}}
                    </label>

                    <div *ngIf="showHelp" class="help-block" [innerHTML]="'form.help.options' | translate"></div>

                    <div class="float-right inline-alert-info" aria-hidden="true"><span translate>name-search.advertisement-text-before-link</span>&nbsp;<a [routerLink]="basePaths.openData" translate>name-search.advertisement-link</a>!
                    </div>
                </div>
            </div>

            <div class="form-group row dynamic-search-fields mb-0">
                <!-- aineistotyyppi -->
                <div class="col-md-12" *ngIf="!loading">

                    <label class="col-form-label me-3" style="padding-top: 0" translate>form.general-type</label>
                    <app-checkbox-list [(ngModel)]="formatsCriterion" name="formatCheckboxes" [referenceData]="supportedFormatsWithLabels"></app-checkbox-list>
                    
                    <div *ngIf="showHelp" class="help-block" [innerHTML]="'form.help.general-type' | translate"></div>
                </div>

                <!-- nimeke -->
                <div class="col-lg-6">
                    <app-popover-picker-async type='title' [(ngModel)]="criteria.publications"
                                        [provider]="titleProvider" name="titles" [large]="true"></app-popover-picker-async>

                    <div *ngIf="showHelp" class="help-block" [innerHTML]="'form.help.publication' | translate"></div>
                </div>

                <!-- kokoelma -->
                <div class="col-lg-6">
                    <app-popover-picker-async type='collection' [(ngModel)]="criteria.collections"
                                        [provider]="collectionProvider"
                                        [formatter]="collectionFormatter"
                                        name="collections"></app-popover-picker-async>

                    <div *ngIf="showHelp" class="help-block" [innerHTML]="'form.help.collection' | translate"></div>
                </div>

                <!-- aikaväli -->
                <!-- datepicker 1. warnings.date1 || -->
                <!-- datepicker 2. warnings.date2 || -->
                <div class="col-lg-6">
                    <div class="d-flex justify-content-between align-items-baseline">
                        <label for="startdate1" class="col-form-label me-3 text-nowrap" translate>form.date-range</label>

                        <span class="sr-only">{{'form.option.order.date' | translate}}</span>
                        <app-datepicker
                                [ngClass]="{'has-warning': warnings.incompatibleDates}"
                                [(ngModel)]="criteria.startDate"
                                (onChange)="datesUpdated()"
                                [initDate]="initDate"
                                [normalize]="'start-of-year'"
                                name="startdate" id="startdate1"></app-datepicker>

                        <label aria-hidden="true" class="col-form-label ms-3 me-3">&mdash;</label>

                        <app-datepicker
                                [ngClass]="{'has-warning': warnings.incompatibleDates}"
                                [(ngModel)]="criteria.endDate"
                                (onChange)="datesUpdated()"
                                [initDate]="initDate"
                                [normalize]="'end-of-year'" name="enddate" id="enddate1"
                                [placeholder]="lastSearchableDate"></app-datepicker>
                    </div>

                    <!--<div class="warning-text" *ngIf="warnings.date1 || warnings.date2" translate>form.warning.date.unsearchable</div>-->
                    <div class="warning-text" *ngIf="warnings.incompatibleDates" translate>form.warning.date.incompatible-dates</div>

                    <div class="d-flex justify-content-between" *ngIf="showHelp">
                        <div class="help-block" [innerHTML]="'form.help.date.start' | translate"></div>
                        <div class="help-block" [innerHTML]="'form.help.date.end' | translate"></div>
                    </div>

                    <div *ngIf="showHelp" class="help-block" [innerHTML]="'form.help.date' | translate"></div>
                </div>


                <!-- julkaisupaikka (kaikki tyypit) -->
                <div class="col-lg-6">
                    <app-popover-picker-async type='publication-place'
                                              [(ngModel)]="criteria.publicationPlaces"
                                              name="publicationPlaces"
                                              [provider]="publishingPlaceProvider">
                    </app-popover-picker-async>

                    <div *ngIf="showHelp" class="help-block" [innerHTML]="'form.help.publication-place' | translate"></div>
                </div>
            </div>
            <div class="form-group row dynamic-search-fields mb-0">

                <!-- tekijä -->
                <div class="col-lg-6">
                    <app-popover-picker-async type="author" [(ngModel)]="criteria.authors"
                                              [provider]="authorProvider"
                                              name="authors"></app-popover-picker-async>

                    <div *ngIf="showHelp" class="help-block" [innerHTML]="'form.help.author' | translate"></div>
                </div>

                <!-- tagit / avainsanat -->
                <div class="col-lg-6">
                    <app-popover-picker-async type='keyword' [(ngModel)]="criteria.tags" [large]="true"
                                              name="tags" [provider]="tagProvider"></app-popover-picker-async>

                    <div *ngIf="showHelp" class="help-block" [innerHTML]="'form.help.keywords' | translate"></div>
                </div>

                <!-- julkaisija -->
                <div class="col-lg-6">
                    <app-popover-picker-async type='publisher' [(ngModel)]="criteria.publishers"
                                              [provider]="publisherProvider" name="publishers"></app-popover-picker-async>
                    <div *ngIf="showHelp" class="help-block" [innerHTML]="'form.help.publisher' | translate"></div>
                </div>

                <div class="col-lg-6">
                    <div class="row">
                        <!-- kieli -->
                        <div class="col-md-7 col-12 mb-3 mb-md-0">
                            <app-popover-picker-async type='language' [(ngModel)]="criteria.languages"
                                                      [provider]="languageProvider" [large]="false"
                                                      name="languages"></app-popover-picker-async>
                        </div>

                        <!-- sivut -->
                        <div class="col-md-5 col-12">
                            <div class="d-flex justify-content-between">
                                <label for="pages" class="col-form-label me-3" translate>form.pages</label>
                                <!-- this additional flex wrapper is because of Firefox -->
                                <div class="d-flex">
                                    <input type="text" [title]="'form.pages' | translate" class="form-control"
                                           (change)="disableLastPages()"
                                           [(ngModel)]="criteria.pages" name="pages" id="pages"
                                           [ngClass]="{'has-input': !!criteria.pages}">
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="showHelp">
                        <div class="col-md-7 col-12 help-block" [innerHTML]="'form.help.language' | translate"></div>
                        <div class="col-md-5 col-12 help-block" [innerHTML]="'form.help.pages' | translate"></div>
                    </div>
                </div>

                <!-- tuontiaika -->
                <div class="col-lg-6">
                    <div class="d-flex justify-content-between align-items-baseline">
                        <label for="importTime" class="col-form-label me-3 text-nowrap" [style.width.%]="100" translate>form.material-added-since</label>

                        <select class="form-control" style="width:70%" [(ngModel)]="criteria.importTime" name="importTime" id="importTime"
                                [ngClass]="{'has-selections': criteria.importTime != importTimes.ANY}"
                                (change)="importTimeChanged()">
                            <option [value]="it" *ngFor="let it of importTimeValues">{{'import-time.' + it | translate}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-6" *ngIf="criteria.importTime == importTimes.CUSTOM">
                    <div class="d-flex justify-content-between align-items-baseline">
                        <label for="importStartDate" class="col-form-label me-3" translate>form.custom-import-time</label>
                        <app-datepicker
                                [(ngModel)]="criteria.importStartDate"
                                [normalize]="'start-of-year'"
                                name="importStartDate" id="importStartDate"></app-datepicker>
                    </div>
                </div>

                <div class="col-lg-12">
                    <label class="checkbox-inline me-3">
                        <input type="checkbox" [(ngModel)]="criteria.hasIllustrations" name="illustrations"> {{'form.has-illustrations' | translate}}
                    </label>
                    
                    <label class="checkbox-inline me-3">
                        <input type="checkbox" [disabled]="criteria.pages !== '' || criteria.searchForBindings" [(ngModel)]="criteria.showLastPage"
                               name="lastPage"> {{'form.show-last-pages' | translate}}
                    </label>

                    <label class="checkbox-inline">
                        <input type="checkbox" [(ngModel)]="criteria.includeUnauthorizedResults" name="showUnauthorized"
                               (change)="refreshDateWarnings()"> {{'form.show-unauthorized-results' | translate}}
                    </label>

                    <div class="row" *ngIf="showHelp">
                        <div class="col-md-4 col-12 help-block" [innerHTML]="'form.help.has-illustrations' | translate"></div>
                        <div class="col-md-4 col-12 help-block" [innerHTML]="'form.help.search-for-bindings' | translate" *ngIf="settingsService.showExperimentalSearchFeatures"></div>
                        <div class="col-md-4 col-12 help-block" [innerHTML]="'form.help.show-last-pages' | translate"></div>
                    </div>

                    <div class="float-right ms-2 d-none d-sm-block" *ngIf="searchHistoryEnabled$ | async as enabled">
                        <i class="fa fa-history my-search-history"
                           [ngClass]="{'enabled': enabled.value}"
                           [ngbTooltip]="('my-search-history.tooltip.' + (enabled.value ? 'enabled' : 'disabled')) | translate"></i>
                        <a routerLink="/account/my-search-history">{{'my-search-history.search-page-link' | translate}}</a>
                    </div>
                </div>
            </div>
        </form>
    `, 
    styleUrls: [
        "./binding-search-form.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BindingSearchFormComponent implements OnInit {
    
    /** the criteria user has currently selected in the UI */
    @Input() criteria: BindingSearchCriteria;
    @Output() initialized = new EventEmitter<void>();
    @Output() submitForm = new EventEmitter<BindingSearchCriteria>();

    readonly importTimes = ImportTime;
    readonly importTimeValues = Object.keys(ImportTime);

    @ViewChild('form') queryForm: NgForm;

    supportedFormatsWithLabels: ValueWithLabel<GeneralType>[] = [];

    lastSearchableDates: Dictionary<any> | null = null;

    readonly collectionFormatter: PopoverFormatter = {
        selectedFormat: {
            showInfo1: true,
            trimTitle: true
        },
        unselectedFormat: {
            showInfo1: false
        }
    };

    // state
    loading = true;  // reference data is still loading
    showHelp = false;

    /** Date to use initially when user opens a date-picker */
    initDate = LocalDate.of(1918, 12, 31);

    /** updated based on selected generalTypes / formats */
    lastSearchableDate: LocalDate;

    warnings = {
        date1: false,
        date2: false,
        incompatibleDates: false
    };

    readonly titleProvider: IPopoverItemProvider;
    readonly collectionProvider: IPopoverItemProvider;
    readonly publisherProvider: IPopoverItemProvider;
    readonly publishingPlaceProvider: IPopoverItemProvider;
    readonly tagProvider: IPopoverItemProvider;
    readonly authorProvider: IPopoverItemProvider;
    readonly languageProvider: IPopoverItemProvider;

    public searchHistoryEnabled$: Observable<BoolWrapper> = this.accountService.loggedIn$.pipe(
        switchMap(loggedIn => loggedIn ? this.accountService.preferences$ : of(null)),
        map((a: PreferencesDto | null) => BoolWrapper.coerceToBool(a && a.saveSearchHistory))
    );

    private readonly subscription = new Subscription();
    
    set formatsCriterion(value: GeneralType[]) {
        this.criteria.formats = value;
        this.updateLastSearchableDate();
    }

    get formatsCriterion(): GeneralType[] {
        return this.criteria.formats;
    }

    get basePaths() { return this.navigationService.basePaths; }

    constructor(public readonly settingsService: SettingsService,
                private readonly searchService: SearchService,
                private readonly navigationService: NavigationService,
                private readonly log: LoggingService,
                private readonly errorService: ErrorService,
                public readonly accountService: AccountService,
                private readonly basicInfoService: BasicInfoService,
                private readonly bindingSearchRestEndpoint: BindingSearchRestEndpoint,
                private readonly cd: ChangeDetectorRef,
                private readonly translate: TranslateService) {
        
        const self = this;
        
        this.titleProvider = searchService.createTitleProvider(
            () => self.criteria.formats, 
            () => self.criteria.startDate?.year(), 
            () => self.criteria.endDate?.year());
        
        this.authorProvider = {
            fetch(query: string, selectedIds: string[], maxResults: number): Observable<IPopoverResult> {
                return bindingSearchRestEndpoint.searchAuthors({
                    selectedIds,
                    query,
                    generalTypes: null,
                    maxResults
                }).pipe(map((r) => {
                    return {
                        selectedIds: r.selectedIdResults.map(a => self.convertAuthor(a)),
                        queryResults: r.queryResults.map(a => self.convertAuthor(a)),
                        totalResults: r.totalResults,
                        totalPossibleResults: r.totalPossibleResults
                    }
                }));
            },

            matches(item: IPopoverItem): boolean {
                const i = item as IPopoverItemWithGeneralType;
                return self.formatsCriterion.length === 0 || self.formatsCriterion.includes(i.generalType);
            }
        };
        
        this.collectionProvider = this.searchService.createCollectionProvider();
        
        this.publisherProvider = {
            fetch(query: string, selectedIds: string[], maxResults: number): Observable<IPopoverResult> {
                return bindingSearchRestEndpoint.searchPublishers({
                    selectedIds,
                    query,
                    generalTypes: null,
                    maxResults
                }).pipe(map((r) => {
                    return {
                        selectedIds: r.selectedIdResults.map(s => ({id: s, title: s})),
                        queryResults: r.queryResults.map(s => ({id: s, title: s})),
                        totalResults: r.totalResults,
                        totalPossibleResults: r.totalPossibleResults
                    }
                }));
            }, 
            
            matches(item: IPopoverItem): boolean {
                return true;
            }
        };

        this.publishingPlaceProvider = {
            fetch(query: string, selectedIds: string[], maxResults: number): Observable<IPopoverResult> {
                return bindingSearchRestEndpoint.searchPublishingPlaces({
                    selectedIds,
                    query,
                    generalTypes: null,
                    maxResults
                }).pipe(map((r) => {
                    return {
                        selectedIds: r.selectedIdResults.map(s => self.formatI18NString(s)),
                        queryResults: r.queryResults.map(s => self.formatI18NString(s)),
                        totalResults: r.totalResults,
                        totalPossibleResults: r.totalPossibleResults
                    }
                }));
            },

            matches(item: IPopoverItem): boolean {
                return true;
            }
        };

        this.tagProvider = {
            fetch(query: string, selectedIds: string[], maxResults: number): Observable<IPopoverResult> {
                return bindingSearchRestEndpoint.searchTags({
                    selectedIds,
                    query,
                    generalTypes: null,
                    maxResults
                }).pipe(map((r) => {
                    return {
                        selectedIds: self.searchService.formatTags(r.selectedIdResults),
                        queryResults: self.searchService.formatTags(r.queryResults),
                        totalResults: r.totalResults,
                        totalPossibleResults: r.totalPossibleResults
                    }
                }));
            },

            matches(item: IPopoverItem): boolean {
                return true;
            }
        };
       
        const languages$: Observable<BasicPopoverItem[]> = this.bindingSearchRestEndpoint.getLanguages().pipe(
            map((codes) => codes.map((c) => ({
                id: c,
                title: this.translate.instant("iso639." + c) as string,
                info: null,
                info2: null
            })).sort((a, b) => a.title.localeCompare(b.title))),
            shareReplay(1)
        );
        
        this.languageProvider = new BasicInMemoryProvider(languages$);
    }

    ngOnInit(): void {
        this.subscription.add(this.basicInfoService.sortedGeneralTypes$
            .pipe(switchMap(types => this.searchService.getSearchReferenceData(types)))
            .subscribe((result: SearchReferenceData) => {
                    this.processReferenceData(result.generalTypes, result);
                    this.loading = false;
                    this.cd.detectChanges();
                    this.initialized.emit();
                },
                e => this.errorService.showUnexpectedError(e)));
    }
    
    isSearchableDate(date: LocalDate): boolean {
        return !date || !this.lastSearchableDate || !isAfter(date, this.lastSearchableDate);
    }

    incompatibleRange(from: LocalDate, until: LocalDate): boolean {
        if (!from || !until)
            return false;
        else
            return from.isAfter(until);
    }

    // TODO: real implementation
    isKeywordsAvailable() {
        return this.criteria.formats.length === 0;
    }

    public toggleHelp(e?: Event) {
        if (e) e.preventDefault();
        this.showHelp = !this.showHelp;
    }

    private processReferenceData(supportedTypes: GeneralType[], referenceData: SearchReferenceData) {
        this.lastSearchableDates = referenceData.userData.lastSearchableDates;

        const generalTypes = supportedTypes.filter(t => referenceData.generalTypes.includes(t));
        this.supportedFormatsWithLabels = this.searchService.formatGeneralTypesWithLabel(generalTypes);

        this.updateLastSearchableDate();
        this.datesUpdated();
    }

    private formatI18NString(s: InternationalizedString): IPopoverItem {
        const alts = [];
        if (s.sv && s.sv !== s.fi) {
            alts.push(s.sv);
        }
        if (s.en && s.en !== s.fi && s.en !== s.sv) {
            alts.push(s.en);
        }
        return {id: s.fi, title: s.fi, info: alts.join(" ")};
    }

    private convertAuthor(author: AuthorInfo): IPopoverItemWithGeneralType {
        return {
            id: author.description,
            title: author.description,
            info: author.asteriId || undefined,
            generalType: author.generalType,
            titleType: null
        }
    }
    
    updateLastSearchableDate() {
        const allFormats = this.formatsCriterion.length === 0;
        const filtered = allFormats ?
            this.lastSearchableDates :
            _.pickBy(this.lastSearchableDates, (v, k) => _.includes(this.formatsCriterion, k));

        // XXX rely on string comparison for max
        this.lastSearchableDate = parseISODate(_.max(_.values(filtered)));
        this.log.debug("last searchable date", this.lastSearchableDate);
    }

    public datesUpdated() {
        this.refreshDateWarnings();
    }

    disableLastPages() {
        this.criteria.showLastPage = false;
    }

    public refreshDateWarnings() {
        const from = this.criteria.startDate;
        const until = this.criteria.endDate;
        this.warnings.date1 = !this.criteria.includeUnauthorizedResults && !this.isSearchableDate(from);
        this.warnings.date2 = !this.criteria.includeUnauthorizedResults && !this.isSearchableDate(until);
        this.warnings.incompatibleDates = this.incompatibleRange(from, until);
    }

    importTimeChanged() {
        if (this.criteria.importTime !== ImportTime.CUSTOM)
            this.criteria.importStartDate = null;
    }
    
    doSubmit($event: Event) {
        $event.preventDefault();

        this.submitForm.emit(this.criteria);
    }
}

interface IYearRanged {
    firstYear: number | null;
    lastYear: number | null;
}
