import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {BindingAgreementInfo, BindingRestEndpoint} from "../../../apina-digiweb";
import {LocalDate} from "@js-joda/core";
import {formatEuropeanDate} from "../../../utils/date";
import {SettingsService} from "../../settings.service";
import {Subscription} from "rxjs";

@Component({
    selector: "app-binding-agreement-info",
    template: `
        <app-sidebar>
            <app-sidebar-header [titleKey]="'agreement.info.title'" (closeSidebar)="closeDialog.emit()"></app-sidebar-header>
            
            <app-sidebar-content>
                <div *ngIf="data != null else loading">
                    <div ngbNav #nav="ngbNav" class="nav-tabs">
                        <div ngbNavItem *ngIf="dataMissing">
                            <a ngbNavLink>Ei sopimustietoja</a>
                            <ng-template ngbNavContent>
                                <p>Hups! Tälle niteelle ei löytynyt sopimustietoja.</p>
                            </ng-template>
                        </div>
                        <div ngbNavItem *ngFor="let agreement of data.details">
                            <a ngbNavLink>{{'agreement.agreement-id.prefix' | translate}}</a>
                            <ng-template ngbNavContent>
                                <div class="kk-tab-content kk-bg-light">
    
                                    <p *ngIf="legalDeposit" translate>agreement.legal-deposit.info</p>
    
                                    <p>{{'agreement.validity.starts' | translate}}: {{formatDate(agreement.validFrom)}}<br/>
                                        {{'agreement.validity.ends' | translate}}: {{formatDate(agreement.validUntil)}}</p>
    
                                    <hr/>
    
                                    <div class="kk-markdown" [innerHTML]="agreement.terms"></div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div [ngbNavOutlet]="nav"></div>
                </div>
            </app-sidebar-content>
        </app-sidebar>

        <ng-template #loading>
            <div class="mb-3">
                <app-progress-spinner></app-progress-spinner>
            </div>
        </ng-template>
    `,
    styleUrls: [
        "../overlay-tabs.scss",
        "./binding-agreement-info.scss"
    ]
})
export class BindingAgreementInfoComponent implements OnInit, OnDestroy {

    constructor(private bindingRest: BindingRestEndpoint,
                settingsService: SettingsService) {
        this.legalDeposit = settingsService.commonOptions.legalDepositWorkstation;
    }

    @Input() bindingId: number;

    @Output() closeDialog = new EventEmitter<void>();

    data: BindingAgreementInfo;

    legalDeposit: boolean;

    private sub: Subscription;

    ngOnInit(): void {
        this.sub = this.bindingRest.getAgreementInfo(this.bindingId).subscribe(response => {
            this.data = response;
        });
    }

    formatDate(date: LocalDate) {
        return formatEuropeanDate(date) || '-';
    }

    get dataMissing() {
        return this.data && !this.data.isPublic && this.data.details.length === 0;
    }

    ngOnDestroy(): void {
        if (this.sub)
            this.sub.unsubscribe();
    }
}
