import {Component} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {concat, Observable, of} from "rxjs";
import {concatMap, debounceTime, filter, map, switchMap} from "rxjs/operators";
import {NerRestEndpoint, PersonNameHit, QueryOrder} from "../../apina-digiweb";
import {ActivatedRoute} from "@angular/router";
import {NavigationService} from "../navigation.service";
import {formatISODate} from "../../utils/date";
import {animAddRemoveSelection, animOpenClose} from "../animations/animations";
import * as _ from "lodash";
import {LocalDate} from "@js-joda/core";
import {BreadcrumbsService} from "../breadcrumbs/breadcrumbs.service";

enum LoadingState { INITIAL = 1, UPDATING = null }

const QUERY_PARAM_PRIMARY_NAME = "q1";
const QUERY_PARAM_ADDITIONAL_NAMES = "q2";
const QUERY_PARAM_YEAR1 = "y1";
const QUERY_PARAM_YEAR2 = "y2";

const SESSION_STORAGE_KEY = "KK-NER-SELECTION";

const SCROLL_AMOUNT = 250;

class ResultsWrapper {
    constructor(public rows: PersonNameHit[]) {
    }
}

interface PartialBindingSearchCriteria {
    query: string;
    orderBy: QueryOrder;
    endDate?: string; // ISO date
    startDate?: string; // ISO date
}

interface SelectedName {
    id: number;
    primaryToken: string;
    additionalTokens: string | null;
}

@Component({
    selector: "app-ner-name-search",
    template: `
        <div class="container mt-4">
            <div class="d-flex justify-content-between">
                <div>
                    <h2>{{'name-search.title' | translate}}</h2>
                    <div class="alpha">ALPHA</div>
                </div>
                <div [innerHTML]="'digi.logoline'|translate"></div>
            </div>

            <div class="alert alert-warning">
                <div [innerHTML]="'name-search.disclaimer'|translate"></div>
            </div>

            <form [formGroup]="form">
                <div class="form-row">
                    <div class="form-group col-3">
                        <label for="primaryName"><span translate>name-search.primary-name</span> <sup class="text-danger" title="{{'name-search.required-field' | translate}}">*</sup></label>
                        <input type="text" id="primaryName" class="form-control"
                               [placeholder]="'name-search.primary-name.placeholder' | translate" appAutofocus
                               pattern="^[^*]{2,}\\*?[^*]*$"
                               formControlName="primaryName" required/>
                    </div>

                    <div class="form-group col-5">
                        <label translate for="additionalNames">name-search.additional-names</label>
                        <input type="text" id="additionalNames" class="form-control"
                               [placeholder]="'name-search.additional-names.placeholder' | translate"
                               formControlName="additionalNames"/>
                    </div>

                    <div class="form-group col-2">
                        <label for="fromYear" translate>name-search.from-year</label>
                        <input type="number" id="fromYear" class="form-control" [placeholder]="'name-search.from-year.placeholder' | translate"
                               pattern="^\\d{0,4}$"
                               formControlName="fromYear"/>
                    </div>

                    <div class="form-group col-2">
                        <label for="toYear" translate>name-search.to-year</label>
                        <input type="number" id="toYear" class="form-control" [placeholder]="'name-search.to-year.placeholder' | translate"
                               pattern="^\\d{0,4}$"
                               formControlName="toYear"/>
                    </div>
                </div>
            </form>

            <div class="card" [@openClose]="'open'" *ngIf="selection.length > 0">
                <div class="card-body bg-light">
                    <div class="float-right">
                        <button class="btn btn-sm btn-secondary" (click)="clearSelections()" translate>name-search.clear-all</button>
                    </div>
                    <h5 class="card-title" translate>name-search.selection.title</h5>
                    <div>
                        <table class="table table-striped table-sm">
                            <tbody>
                            <tr *ngFor="let row of selection" [@addRemoveSelection]="'in'">
                                <td>{{row.primaryToken}}</td>
                                <td>{{row.additionalTokens}}</td>
                                <td class="text-right">
                                    <button class="btn btn-sm btn-secondary" (click)="removeFromSelection(row)" translate>name-search.remove-from-selection</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="text-right">
                        <button class="btn btn-success" (click)="searchSelectionPages()" translate>name-search.search-button</button>
                    </div>
                </div>
            </div>

            <div *ngIf="result$ | async as result; else loading">
                <p class="mt-3"><span translate>name-search.results-count.prefix</span> {{result.rows.length || 0}} <span translate>name-search.results-count.suffix</span></p>

                <table class="table table-sm table-striped mt-3" *ngIf="result.rows.length > 0">
                    <thead>
                    <tr>
                        <th translate>name-search.primary-name</th>
                        <th translate>name-search.additional-names</th>
                        <th translate>name-search.years</th>
                        <th translate>name-search.page-count</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody infinite-scroll [infiniteScrollDistance]="1" (scrolled)="onScroll()">
                    <tr *ngFor="let row of result.rows | slice:0:scrollTo" [class.selected]="isSelected(row)">
                        <td>{{row.primaryToken}}</td>
                        <td>{{row.additionalTokens}}</td>
                        <td>{{formatYearRange(row.minYear, row.maxYear)}}</td>
                        <td>{{row.pageCount}}</td>
                        <td class="text-right" [ngSwitch]="isSelected(row)">
                            <button *ngSwitchCase="false" class="btn btn-kk-blue btn-sm" (click)="addToSelection(row)" translate>name-search.add-to-selection</button>
                            <button *ngSwitchCase="true" class="btn btn-sm btn-secondary" (click)="removeFromSelection(row)" translate>name-search.remove-from-selection</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <ng-template #loading>
            <app-progress-spinner></app-progress-spinner>
        </ng-template>
    `,
    styles: [
        '.alpha { color:#782911; font-weight: bold;  }',
        '.selected { background-color: #c7de94!important; }'
    ],
    animations: [
        animOpenClose,
        animAddRemoveSelection
    ]
})
export class NerNameSearchComponent {

    form = new UntypedFormGroup({
        primaryName: new UntypedFormControl(''),
        additionalNames: new UntypedFormControl(''),
        fromYear: new UntypedFormControl(''),
        toYear: new UntypedFormControl('')
    });

    result$: Observable<LoadingState | ResultsWrapper>;

    scrollTo = SCROLL_AMOUNT;

    selection: SelectedName[] = [];

    constructor(private nerRestEndpoint: NerRestEndpoint,
                private navigationService: NavigationService,
                private activatedRoute: ActivatedRoute,
                breadcrumbs: BreadcrumbsService) {
        breadcrumbs.setLocalizedText('main.name-search');
        this.recallSelection();

        const urlParams = this.activatedRoute.snapshot.queryParamMap;
        const q1 = urlParams.get(QUERY_PARAM_PRIMARY_NAME);
        const q2 = urlParams.get(QUERY_PARAM_ADDITIONAL_NAMES);
        const y1 = urlParams.get(QUERY_PARAM_YEAR1);
        const y2 = urlParams.get(QUERY_PARAM_YEAR2);

        const input$ = concat(
            of(LoadingState.INITIAL),
            this.form.valueChanges.pipe(
                debounceTime(1000),
                filter(a => this.form.valid) // XXX not observable
            )
        );

        this.result$ = input$.pipe(
            concatMap(val => of(LoadingState.UPDATING, val)),
            switchMap(query => {
                switch (query) {
                    case LoadingState.UPDATING:
                        return of(null);
                    case LoadingState.INITIAL:
                        return this.searchWith('', null, null, null);
                    default:
                        return this.searchWith(query.primaryName, query.additionalNames, query.fromYear, query.toYear);
                }
            })
        );

        input$.pipe(
            filter(val => val !== LoadingState.INITIAL && val !== LoadingState.UPDATING)
        ).subscribe(val => {
            this.navigationService.search = {
                [QUERY_PARAM_PRIMARY_NAME]: val.primaryName,
                [QUERY_PARAM_ADDITIONAL_NAMES]: val.additionalNames,
                [QUERY_PARAM_YEAR1]: val.fromYear,
                [QUERY_PARAM_YEAR2]: val.toYear
            };
        });

        if (q1) {
            setTimeout(() => {
                this.form.patchValue({
                    primaryName: q1,
                    additionalNames: q2,
                    fromYear: y1,
                    toYear: y2
                });
            });
        }
    }

    searchWith(primaryName: string, additionalNames: string | null, fromYear: number, toYear: number) {
        this.resetScroll();
        return this.nerRestEndpoint.findPersonNameHits(primaryName, additionalNames, fromYear, toYear).pipe(map(a => {
            // we need to wrap this or template gets confused
            return new ResultsWrapper(a);
        }));
    }

    formatYearRange(minYear: number, maxYear: number): string {
        if (minYear === maxYear)
            return "" + minYear;
        else
            return minYear + ".." + maxYear;
    }

    addToSelection(row: PersonNameHit) {
        if (this.isSelected(row))
            return;

        this.selection.push({
            id: row.id,
            primaryToken: row.primaryToken,
            additionalTokens: row.additionalTokens
        });

        this.rememberSelection();
    }

    removeFromSelection(row: SelectedName) {
        const index = _.findIndex(this.selection, a => a.id === row.id);

        if (index !== -1) {
            this.selection.splice(index, 1);
            this.rememberSelection();
        } else {
            throw Error(`Not selected: [${row.id}, ${row.primaryToken}, ${row.additionalTokens}] `);
        }
    }

    clearSelections() {
        this.selection = [];
        this.rememberSelection();
    }

    isSelected(row: PersonNameHit) {
        return this.selection.some(a => a.id === row.id);
    }

    searchSelectionPages() {
        if (this.selection.length === 0)
            return;

        const queryString = this.selection.map(h => this.formatPersonQueryString(h)).join(" OR ");

        const fromYear: number = this.form.value.fromYear;
        const toYear: number = this.form.value.toYear;

        const queryParams: PartialBindingSearchCriteria = {
            query: queryString,
            orderBy: QueryOrder.RELEVANCE
        };

        if (fromYear)
            queryParams.startDate = formatISODate(LocalDate.of(fromYear, 1, 1));

        if (toYear)
            queryParams.endDate = formatISODate(LocalDate.of(toYear, 12, 31));

        this.navigationService.goTo2([this.navigationService.basePaths.search], queryParams);
    }

    onScroll() {
        this.scrollTo += SCROLL_AMOUNT;
    }

    private resetScroll() {
        this.scrollTo = SCROLL_AMOUNT;
    }

    private formatPersonQueryString(h: SelectedName) {
        // eslint-disable-next-line
        if (h.additionalTokens == null || h.additionalTokens == '')
            return h.primaryToken;
        else
            return '"' + h.additionalTokens + " " + h.primaryToken + '"~3';
    }

    private rememberSelection() {
        try {
            if (sessionStorage) {
                sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(this.selection));
            }
        } catch (e) {
            // security exception
        }
    }

    private recallSelection() {
        try {
            if (sessionStorage) {
                const storedValues = sessionStorage.getItem(SESSION_STORAGE_KEY);
                if (storedValues) {
                    try {
                        this.selection = JSON.parse(storedValues);
                        // TODO improve failure detection
                    } catch (e) {
                        this.selection = [];
                    }
                }
            }
        } catch (e) {
            // security exception
        }
    }
}
