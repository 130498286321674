import {Component} from "@angular/core";

@Component({
    template: `
        <mat-dialog-content>
            <p translate>my-search-history.clear-dialog.text</p>
        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="ms-auto mb-3">
                <button class="btn btn-kk-blue" [mat-dialog-close]="false" translate>common.action.cancel</button>
                <button class="btn btn-kk-red ms-3" [mat-dialog-close]="true" translate>my-search-history.clear-dialog.button.confirm</button>
            </div>
        </mat-dialog-actions>
    `
})
export class ConfirmClearSearchHistoryModalComponent {

}
