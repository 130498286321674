import {Component, OnDestroy} from "@angular/core";
import {
    BindingSearchCriteria,
    BindingSearchRestEndpoint,
    BindingSearchResultsBatch,
    GeneralType,
    ImportTime,
    QueryOrder,
    SerialPublicationPlaceInfo
} from "../../apina-digiweb";
import {currentDate, formatEuropeanDate, parseEuropeanDate} from "../../utils/date";
import {TranslateService} from "@ngx-translate/core";
import {NavigationService} from "../navigation.service";
import * as _ from "lodash";
import {BreadcrumbsService} from "../breadcrumbs/breadcrumbs.service";
import {SearchService} from "./search.service";
import {LocalDate} from "@js-joda/core";
import {UntypedFormControl} from "@angular/forms";
import {merge, Subscription} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {ProcessedBindingSearchResultRow, ResultMode} from "./result/result-row";


const STORAGE_KEY = "natlibfi-papers-for-day-years-ago";

// This is used when the date the user has entered is not valid
const EMPTY_RESULTS: BindingSearchResultsBatch = {
    hitsByYear: undefined,
    isAggregation: false,
    queryValidity: undefined,
    rows: [],
    totalHitsByYear: undefined,
    totalResults: 0,
    generalTypes: undefined
};

@Component({
    template: `
        <app-progress-bar *ngIf="loading"></app-progress-bar>
        
        <div *ngIf="!loading">
            <div class="kk-bg-lightgray pt-3 pb-3">
                <div class="container px-0">
                    <form novalidate class="d-flex justify-content-between flex-wrap">
                        <div class="form-group d-flex align-items-center">
    
                            <button type="button" class="btn btn-kk-blue"
                                    [disabled]="!formValid"
                                    (click)="selectPreviousDay()">
                                <i class="fa fa-caret-left"></i>
                                <span class="sr-only">{{'papersforday.previous.tooltip'|translate}}</span>
                            </button>
    
                            <!-- TODO add required to all inputs -->
                            <app-datepicker style="width: 140px"
                                            class="mx-2"
                                            [formControl]="date"
                                            [normalize]="'start-of-year'"
                                            title="{{'papersforday.calendar.tooltip'|translate}}"
                                            name="dateWrapper"></app-datepicker>
    
                            <button type="button" class="btn btn-kk-blue"
                                    [disabled]="!formValid"
                                    (click)="selectNextDay()">
                                <i class="fa fa-caret-right"></i>
                                <span class="sr-only">{{'papersforday.next.tooltip'|translate}}</span>
                            </button>
    
                        </div>
                        
                        <div class="form-group d-flex justify-content-center">
                            <input class="form-control" type="number" [formControl]="minusYears" 
                                   [min]="MIN_MINUS_YEARS" [max]="MAX_MINUS_YEARS"
                                   name="minusYears" style="width: 75px" [ngClass]="{'non-default': minusYears.value != 100}"/>
                            <label class="col-form-label ms-3" aria-label=""
                                   translate>papersforday.minus-years.label</label>
                        </div>
                        
                        <div class="form-group d-flex justify-content-end">
                            <label class="col-form-label me-3" aria-label=""
                                   translate>form.search.publication-place</label>
    
                            <app-popover-picker type='publication-place'
                                                [formControl]="publicationPlaces"
                                                [items]="allPublicationPlaces"
                                                [extraFilter]="publicationPlaceFilter"
                                                title="{{'papersforday.publicationplace.tooltip'|translate}}"
                                                name="publicationPlace">
                            </app-popover-picker>
                        </div>
                    </form>
                </div>
            </div>

            <section class="container">
                <div class="d-flex align-items-center">
                    <app-search-result-count-badge [total]="results?.totalResults" class="mb-3 me-3"></app-search-result-count-badge>
                    <app-result-navigation *ngIf="results" [sortOrderEnabled]="false" [style.width.%]="100"
                                       [totalResults]="results.totalResults"
                                       [resultMode]="resultMode" (resultModeChange)="setResultMode($event)"
                                       [pageSize]="pageSize" [currentPage]="page" (currentPageChange)="setPage($event)"
                                       [orderBy]="orderBy" [supportedModes]="supportedModes"></app-result-navigation>
                </div>
                
                <app-binding-search-results *ngIf="resultMode != 'CHART'" [items]="rows" [resultMode]="resultMode"></app-binding-search-results>

                <app-search-result-chart *ngIf="resultMode == 'CHART'" [results]="results"></app-search-result-chart>
            </section>
        </div>
    `,
    styles: [
        ".form-group { margin: 1rem!important; }",
        ".non-default { background-color: beige; }"
    ]
})
export class PapersForDayComponent implements OnDestroy {

    readonly MIN_MINUS_YEARS = 0;
    readonly MAX_MINUS_YEARS = 500;

    minusYears = new UntypedFormControl(100);
    date = new UntypedFormControl();
    publicationPlaces = new UntypedFormControl();

    resultMode = ResultMode.THUMB;

    loading = true;

    page = 1;
    pageSize = 20;
    orderBy = QueryOrder.TITLE_ASC;
    supportedModes = [ResultMode.TEXT, ResultMode.TEXT_WITH_THUMB, ResultMode.THUMB];
    results: BindingSearchResultsBatch;
    rows: ProcessedBindingSearchResultRow[] | null;

    /** display buffer */
    allPublicationPlaces: SerialPublicationPlaceInfo[] = [];
    publicationPlaceFilter: (p: SerialPublicationPlaceInfo) => boolean = null;


    private readonly subscription = new Subscription();

    constructor(private translate: TranslateService,
                private navigationService: NavigationService,
                private bindingSearchRest: BindingSearchRestEndpoint,
                private searchService: SearchService,
                private readonly breadcrumbs: BreadcrumbsService) {

        breadcrumbs.setLocalizedText('papers-for-day.main.header');

        const searchParams = this.navigationService.search;

        this.publicationPlaces.setValue(_.words(searchParams.publicationPlaces, /[^,]+/g));

        const storedYear = this.loadStoredYear();
        if (storedYear) {
            this.minusYears.setValue(storedYear);
        }

        this.subscription.add(merge(this.date.valueChanges, this.publicationPlaces.valueChanges)
            .subscribe(() => {
                this.update();
            }));

        this.subscription.add(this.date.valueChanges.subscribe(async () => {
            const allResults = await this.getAllResults();
            this.publicationPlaceFilter = this.createPublicationPlaceFilter(allResults);
        }));

        const now: LocalDate = currentDate();
        const overrideDate: LocalDate = parseEuropeanDate(searchParams.showDate);

        if (!!overrideDate) {
            this.date.setValue(overrideDate);
        } else {
            this.date.setValue(now.minusYears(this.minusYears.value));
        }

        searchService.getPapersForDayReferenceData().subscribe((data) => {
            this.allPublicationPlaces = data.serialPublicationPlaces;
            this.loading = false;
            this.executeSearch(true);
        });

        this.subscription.add(this.minusYears.valueChanges.pipe(
            debounceTime(200)
        ).subscribe(() => {
            this.updateFollowedYear();
        }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    get formValid() {
        return this.date.valid;
    }

    setPage(page: number): void {
        if (page !== this.page) {
            this.page = page;
            this.executeSearch();
        }
    }

    getPage(): number {
        return this.page;
    }

    setResultMode(mode: ResultMode) {
        this.resultMode = mode;
    }
    
    updateFollowedYear() {
        const v = this.parseYear(this.minusYears.value);
        if (v) {
            this.storeYear(v);
            this.date.setValue(LocalDate.now().minusYears(v));
        }
    }

    update() {
        if (!this.date.value) {
            this.results = EMPTY_RESULTS;
            return;
        }

        this.updateLocation();
        this.executeSearch();
    }

    executeSearch(fromUrl: boolean = false) {
        const firstBatch = true;

        const criteria: BindingSearchCriteria = {
            authors: [],
            districts: [],
            formats: [GeneralType.NEWSPAPER],
            searchForBindings: false,
            tags: [],
            collections: [],
            exactCollectionMaterialType: false,
            startDate: this.date.value,
            endDate: this.date.value,
            importTime: ImportTime.ANY,
            importStartDate: null,
            publicationPlaces: this.publicationPlaces.value,
            publications: [],
            query: "",
            requireAllKeywords: false,
            showLastPage: false,
            fuzzy: false,
            queryTargetsOcrText: true,
            queryTargetsMetadata: false,
            hasIllustrations: false,
            includeUnauthorizedResults: false,
            pages: "",
            languages: [],
            publishers: [],
            orderBy: this.orderBy
        };

        const offset = (this.page - 1) * this.pageSize;
        if (this.results)
            this.results.rows = null;
        this.bindingSearchRest.searchBindings(criteria, offset, this.pageSize)
            .subscribe(results => {
                this.results = results;
                this.rows = this.searchService.processBindingRows(results.rows);
                this.breadcrumbs.setLatestLocation([
                    {
                        translationKey: "papers-for-day.main.header",
                        commands: [this.navigationService.basePaths.papersForDay],
                        queryParams: {
                            showDate: formatEuropeanDate(this.date.value),
                            publicationPlaces: this.publicationPlaces.value
                        }
                    }
                ]);
            });
    }

    selectNextDay() {
        const v = this.date.value;
        if (v) {
            this.date.setValue(v.plusDays(1));
            this.page = 1;
        }
    }

    private createPublicationPlaceFilter(allResults: BindingSearchResultsBatch): (o: SerialPublicationPlaceInfo) => boolean {
        if(!allResults.rows) return null;
        const currentResultCities = allResults.rows.map(r => r.placeOfPublication);
        return (o: SerialPublicationPlaceInfo) => currentResultCities.includes(o.title);
    }

    private async getAllResults() {
        const criteria: BindingSearchCriteria = {
            authors: [],
            districts: [],
            formats: [GeneralType.NEWSPAPER],
            searchForBindings: false,
            tags: [],
            collections: [],
            exactCollectionMaterialType: false,
            startDate: this.date.value,
            endDate: this.date.value,
            importTime: ImportTime.ANY,
            importStartDate: null,
            publicationPlaces: [],
            publications: [],
            query: "",
            requireAllKeywords: false,
            showLastPage: false,
            fuzzy: false,
            queryTargetsOcrText: true,
            queryTargetsMetadata: false,
            hasIllustrations: false,
            includeUnauthorizedResults: false,
            pages: "",
            languages: [],
            publishers: [],
            orderBy: this.orderBy
        };

        return await this.bindingSearchRest.searchBindings(criteria, 0, 1000).toPromise();
    }

    selectPreviousDay() {
        const v = this.date.value;
        if (v) {
            this.date.setValue(v.minusDays(1));
            this.page = 1;
        }
    }

    private updateLocation() {
        // this.$location.path('papers-for-day'); // TODO why?

        this.navigationService.search = {
            showDate: formatEuropeanDate(this.date.value),
            publicationPlaces: this.publicationPlaces.value
        };
        this.page = 1;
    }

    private loadStoredYear(): number | null {
        try {
            if (localStorage) {
                const value = localStorage.getItem(STORAGE_KEY);
                return this.parseYear(value);
            }
        } catch (e) {
            // SecurityError
        }
        return null;
    }

    private storeYear(v: number) {
        try {
            if (localStorage) {
                localStorage.setItem(STORAGE_KEY, '' + v);
            }
        } catch (e) {
            // SecurityError
        }
    }

    private parseYear(value: string): number|null {
        const v = parseInt(value, 10);

        if (!isNaN(v) && v >= 0 && v <= this.MAX_MINUS_YEARS) {
            return v;
        }
        return null;
    }
}
