import {Component, OnDestroy} from "@angular/core";
import {UntypedFormControl} from "@angular/forms";
import {
    HackathonAssignment,
    HackathonRestEndpoint,
    HackathonResultRow,
    HackathonRole,
    HackathonSearchResults
} from "../../../apina-digiweb";
import {Observable, of, Subscription} from "rxjs";
import {SearchService} from "../search.service";
import {AccountService} from "../../account/account.service";
import {NavigationService} from "../../navigation.service";

enum Phase { LOADING, ROLE_SELECTION, SESSION_A, INTERMISSION, SESSION_B, FINISHED}

interface HackathonRow extends HackathonResultRow {
    highLights: string[];
    displayOCR: boolean;
    hackathonScore: UntypedFormControl;
    syncing: boolean;
    syncedToBackend: boolean;
    syncFailed: boolean;
}

@Component({
    template: `
        <section class="container">
            <div class="d-flex justify-content-between">
                <h3 class="d-flex flex-column align-items-center">
                    <img class="newseye-logo" src="/images/NewsEyeLogos_MP_3-01.jpg" alt="newseye">
                    <div>Hackathon</div>
                </h3>

                <div class="logos d-flex justify-content-around align-items-center">
                    <img height="95" src="/images/eu_flag_yellow_low.jpg" alt="EU-logo">
                    <div class="eu-logo-text">NewsEye-projekti on saanut rahoitusta
                        Euroopan Unionin tutkimuksen ja innovoinnin Horisontti 2020 –rahoitusohjelmasta,
                        EU-sopimusnumero 770299.</div>
                </div>
            </div>

            <ng-template #pleaseLogin>
                <div class="alert alert-warning">Aloita kirjautumalla sisään</div>
            </ng-template>
            
            <div *ngIf="loggedIn$ | async; else pleaseLogin">

                <div class="alert alert-warning" *ngIf="admin">
                    <div class="grid-admin">
                        <div class="t">ADMIN</div>

                        <div class="ocr">
                            <label class="me-4">Vanha OCR
                                <input type="radio" [value]="false" [formControl]="reOCR">
                            </label>

                            <label>Uusi OCR
                                <input type="radio" [value]="true" [formControl]="reOCR">
                            </label>
                        </div>

                        <select class="a1 form-control" [formControl]="adminTaskSelection">
                            <option *ngFor="let task of allAssignments$ | async" [value]="task.query">{{task.id}}
                                . {{task.query}}</option>
                        </select>

                        <button class="a2 btn btn-primary" (click)="adminSearch(adminTaskSelection.value)">Hae</button>

                        <input type="text" class="a3 form-control" [formControl]="sessionBQuery"
                               placeholder="Syötä hakusanat"/>
                        <button class="a4 btn btn-primary"
                                (click)="searchWithAssignmentAndCustomQuery(currentAssignment)">Hae
                        </button>

                        <div class="a5">
                            <button class="btn btn-secondary" (click)="adminResetToSessionA()">Palaa sessioon A</button>
                        </div>
                        <div class="a6">
                            <button class="btn btn-secondary" (click)="adminResetToSessionB()">Palaa sessioon B</button>
                        </div>
                    </div>
                </div>

                <div *ngIf="phase == PHASES.ROLE_SELECTION">
                    <div class="grid-registration">
                        <div class="legend">Valitse roolisi, jolla tulet täyttämän kyselyn.</div>
                        <button class="btn btn-primary button1" (click)="selectStudent()">Olen opiskelija</button>
                        <button class="btn btn-primary button2" (click)="selectTeacher()">Olen opettaja</button>
                    </div>
                </div>

                <div *ngIf="phase > PHASES.ROLE_SELECTION" class="mb-4">
                    Valittu rooli: {{'newseye-hackathon.roles.' + role | translate}}
                </div>

                <div *ngIf="phase == PHASES.SESSION_A || phase == PHASES.SESSION_B">
                    
                    <div class="text-right mb-3" *ngIf="!showHelp">
                        <button class="btn btn-kk-blue" (click)="showHelp = true">
                            <i class="fa fa-info-circle"></i>
                        </button>    
                    </div>
                    
                    <div class="alert alert-info" *ngIf="showHelp">
                        <p>Hakuistunnot on suoritettava kokonaisina. Et voi välillä tallentaa tai palata keskeneräiseen istuntoon myöhemmin. Istuntojen A ja B välillä voit pitää tauon, jolloin voit kirjautua ulos.</p>

                        <p>Jos tarvitset teknisiä ohjeita tai apua leikehakusession aikana, ota yhteyttä <a href="mailto:digi-yllapito@helsinki.fi">digi-yllapito&#64;helsinki.fi</a> Osoite vastaa arkipäivisin 8. - 17.3.2021 klo 9-16.</p>

                        <p>Saat tässä osiossa kuusi hakutehtävää arvioitaviksi. Jokaisessa on kymmenen arvioitavaa hakutulosta. Kaikkia hakutuloksia ei näytetä.</p>

                        <p>OCR-teksti = automaattisen tekstintunnistuksen tulos, tekstien laajuus vaihtelee</p>
                        
                        <div class="text-right">
                            <button class="btn btn-kk-blue" (click)="showHelp = false">Selvä!</button>
                        </div>
                    </div>
                    
                    <form>
                        <div class="grid-current-assignment">
                            <div class="explanation alert alert-info">
                                <div class="assignment-number">{{assignmentIndex + 1}}/{{assignments.length}}</div>
                                <div class="text">{{currentAssignment.explanation}}</div>
                                <div class="assignment-id">
                                    Sessio {{getSession(phase)}}, Tehtävä #{{currentAssignment.id}}
                                </div>
                                <div class="predefined-query" *ngIf="phase === PHASES.SESSION_A">
                                    Hakusanat: <code>{{currentAssignment.query}}</code>
                                </div>
                                <div class="custom-query" *ngIf="phase === PHASES.SESSION_B">
                                    <input type="text" class="form-control" [formControl]="sessionBQuery"
                                           placeholder="Syötä hakusanat"/>
                                    <button class="btn btn-primary"
                                            (click)="searchWithAssignmentAndCustomQuery(currentAssignment)">Hae
                                    </button>
                                </div>
                                <div class="search-button">
                                    <ng-container *ngIf="phase === PHASES.SESSION_A">
                                        <button class="btn btn-primary"
                                                *ngIf="results == null"
                                                (click)="searchWithAssignment(currentAssignment)">Hae
                                        </button>

                                        <div *ngIf="currentScoredCount as c">
                                            <span *ngIf="c < results.length else gotoNext">{{currentScoredCount}}
                                                / {{results.length}}</span>
                                            <ng-template #gotoNext>
                                                <button class="btn btn-primary ms-4" (click)="nextAssignment()">Seuraava
                                                    tehtävä
                                                </button>
                                            </ng-template>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="phase === PHASES.SESSION_B">
                                        <div *ngIf="currentScoredCount as c">
                                            <span *ngIf="c < results.length else gotoNext">{{currentScoredCount}}
                                                / {{results.length}}</span>
                                            <ng-template #gotoNext>
                                                <button class="btn btn-primary ms-4" (click)="nextAssignment()">Seuraava
                                                    tehtävä
                                                </button>
                                            </ng-template>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div *ngIf="phase == PHASES.INTERMISSION">
                    <div class="alert alert-success">Ensimmäinen vaihe on valmis. (Väliaika)</div>

                    <div>
                        <button class="btn btn-primary" (click)="this.startPhaseB()">Aloita toinen vaihe</button>
                    </div>
                </div>

                <div *ngIf="phase == PHASES.FINISHED">
                    <div class="alert alert-success">Kiitos osallistumisesta!</div>
                </div>

                <mat-progress-spinner *ngIf="loading || phase == PHASES.LOADING"
                                      mode="indeterminate" diameter="36" class="m-auto"></mat-progress-spinner>

                <div *ngIf="results">

                    <p *ngIf="results.length > 0">Näytetään {{results.length}} / {{totalResults}} hakutulosta.</p>

                    <table class="table table-striped" *ngIf="results.length > 0 else noResults">
                        <thead>
                        <tr>
                            <th>Tulos</th>
                            <th>Pisteytys</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let row of results">
                            <td>
                                <strong>{{row.publishingDate | date:"dd.MM.yyyy"}}</strong>
                                <div class="search-highlight-fragment" *ngFor="let hl of row.highLights"
                                     [innerHTML]="hl"></div>
                                <hr/>
                                <div *ngIf="row.displayOCR">
                                    <p class="ocr-text" *ngFor="let block of row.text" [innerHTML]="block"></p>
                                    <button class="btn btn-sm btn-kk m-0" (click)="row.displayOCR = false">Piilota OCR
                                    </button>
                                </div>
                                <div *ngIf="!row.displayOCR">
                                    <button class="btn btn-sm btn-kk m-0" (click)="row.displayOCR = true">Näytä leikkeen
                                        koko OCR-teksti ({{row.ocrLength}} merkkiä)
                                    </button>
                                </div>
                            </td>
                            <td>
                                <div class="hackathon-scoring">
                                    <label>0<input type="radio" [formControl]="row.hackathonScore"
                                                   [name]="'hackathon-score-' + row.articleId" [value]="0"/></label>
                                    <label>1<input type="radio" [formControl]="row.hackathonScore"
                                                   [name]="'hackathon-score-' + row.articleId" [value]="1"/></label>
                                    <label>2<input type="radio" [formControl]="row.hackathonScore"
                                                   [name]="'hackathon-score-' + row.articleId" [value]="2"/></label>
                                    <label>3<input type="radio" [formControl]="row.hackathonScore"
                                                   [name]="'hackathon-score-' + row.articleId" [value]="3"/></label>
                                </div>

                                <div class="d-flex justify-content-center">
                                    <div *ngIf="row.syncing">
                                        <mat-progress-spinner mode="indeterminate" diameter="24"></mat-progress-spinner>
                                    </div>
                                    <div *ngIf="row.syncedToBackend">
                                        <mat-icon matSuffix style="color: green">check</mat-icon>
                                    </div>
                                    <div *ngIf="row.syncFailed" class="alert alert-danger">Tallennus epäonnistui</div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <ng-template #noResults>Ei hakuosumia.</ng-template>
                </div>
            </div>
        </section>
    `,
    styleUrls: [
        "./hackathon-search.scss"
    ]
})
export class HackathonSearchComponent implements OnDestroy {
    PHASES = Phase;
    phase = Phase.LOADING;
    role: HackathonRole|null;

    assignments: HackathonAssignment[] = [];
    assignmentIndex = 0;

    sessionBQuery = new UntypedFormControl("");

    results: HackathonRow[]|null = null;
    totalResults: number|null = null;
    subscription: Subscription;
    assignmentsLoaded = false;
    loading = false;
    loggedIn$ = this.accountService.loggedIn$;

    showHelp = true;

    // admin
    admin = false;
    reOCR = new UntypedFormControl(true);
    allAssignments$: Observable<HackathonAssignment[]>;
    adminTaskSelection = new UntypedFormControl('');

    constructor(private readonly accountService: AccountService,
                navigationService: NavigationService,
                private readonly searchService: SearchService,
                private readonly hackathonRestEndpoint: HackathonRestEndpoint) {
        this.fetchState();

        this.admin = !!navigationService.search.admin;
        if (this.admin) {
            this.allAssignments$ = this.hackathonRestEndpoint.listAllAssignments();
        }
    }

    private fetchState() {
        this.hackathonRestEndpoint.findUser().subscribe((result) => {
            const data = result.data;
            if (data) {
                this.phase = this.detectPhase(result.data.completedSessions);
                this.assignments = data.assignments;
                this.role = data.role;
            } else {
                this.phase = Phase.ROLE_SELECTION;
            }
        });
    }

    detectPhase(completedSessions: string[]): Phase {
        if (completedSessions.includes("B")) {
          return Phase.FINISHED;
        } else if (completedSessions.includes("A"))
            return Phase.SESSION_B;
        else
            return Phase.SESSION_A;
    }

    get currentAssignment() {
        return this.assignments[this.assignmentIndex];
    }

    get currentScoredCount() {
        if (this.results && this.results.length > 0)
            return this.results.filter(r => r.syncedToBackend).length;
        else
            return null;
    }

    selectStudent() {
        this.selectRole(HackathonRole.STUDENT);
    }

    selectTeacher() {
        this.selectRole(HackathonRole.TEACHER);
    }

    selectRole(role: HackathonRole) {
        this.hackathonRestEndpoint.registerForHackathon({
            role
        }).subscribe(((data) => {
            this.assignments = data.assignments;
            this.phase = Phase.SESSION_A;
            this.role = role;
        }));
    }

    private search(q: string): Observable<HackathonSearchResults> {
        const reOCR = !!this.reOCR.value;

        if (q && q.trim().length > 0) {
            return this.hackathonRestEndpoint.searchArticles({
                query: q,
                reOCR
            });
        } else {
            const empty: HackathonSearchResults = {rows: [], totalResults: 0};
            return of(empty);
        }
    }

    searchWithAssignment(assignment: HackathonAssignment) {
        this.loading = true;
        this.resetResults();
        const query = assignment.query;
        this.search(query).subscribe((results) => {
            this.results = this.processRows(assignment.id, query, results.rows);
            this.totalResults = results.totalResults;
            this.loading = false;
        });
    }

    searchWithAssignmentAndCustomQuery(assignment: HackathonAssignment) {
        this.resetResults();

        const query: string = this.sessionBQuery.value;

        if (query && query.trim().length > 1) {
            this.loading = true;
            this.search(query).subscribe((results) => {
                this.results = this.processRows(assignment.id, query, results.rows);
                this.totalResults = results.totalResults;
                this.loading = false;
            });
        }
    }

    private processRows(assignmentId: number, query: string, rows: HackathonResultRow[]): HackathonRow[] {
        if (this.phase !== Phase.SESSION_A && this.phase !== Phase.SESSION_B)
            throw Error("invalid phase: " + this.phase);

        const session = this.phase === Phase.SESSION_A ? "A" : "B";

        this.subscription = new Subscription();
        return rows.map((originalRow, i) => {
            const row = this.initializeUiRow(originalRow);

            this.subscription.add(row.hackathonScore.valueChanges.subscribe(score => {
                row.syncing = true;
                this.hackathonRestEndpoint.setScore({
                    session,
                    assignmentId,
                    ocrCharCount: row.ocrLength,
                    score,
                    query,
                    resultNumber: i + 1,
                    clippingId: row.articleId
                }).subscribe(() => {
                    console.log("score saved", row.articleId, score);
                    row.syncing = false;
                    row.syncedToBackend = true;
                }, () => {
                    row.syncing = false;
                    row.syncFailed = true;
                });
            }));
            return row;
        });
    }

    private resetResults() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.results = null;
    }

    private initializeUiRow(originalRow: HackathonResultRow) {
        const row: HackathonRow = Object.assign({}, originalRow) as any;
        row.highLights = this.searchService.getHighlightsAsHtml(row.textHighlights);
        row.hackathonScore = new UntypedFormControl();
        row.syncedToBackend = false;
        return row;
    }

    ngOnDestroy(): void {
        this.resetResults();
    }

    nextAssignment() {
        this.resetResults();
        this.assignmentIndex++;

        if (this.phase === Phase.SESSION_B) {
            this.sessionBQuery.reset();
        }

        if (this.assignmentIndex >= this.assignments.length) {
            this.assignmentIndex = 0;

            const session = this.getSession(this.phase);
            this.hackathonRestEndpoint.completeSession(session).subscribe(() => {
                if (this.phase === Phase.SESSION_A) {
                    this.phase = Phase.INTERMISSION;
                } else if (this.phase === Phase.SESSION_B) {
                    this.phase = Phase.FINISHED;
                }
            });
        }
    }

    startPhaseB() {
        this.fetchState();
    }

    getSession(phase: Phase) {
        if (phase === Phase.SESSION_A)
            return "A";
        else if (phase === Phase.SESSION_B)
            return "B";
        else
            throw Error("No session associated with phase: " + phase);
    }

    adminSearch(query: string) {
        this.resetResults();
        this.loading = true;
        this.search(query).subscribe((results) => {
            this.results = this.processRows(-1, query, results.rows);
            this.totalResults = results.totalResults;
            this.loading = false;
        });
    }

    adminResetToSessionA() {
        this.hackathonRestEndpoint.resetUserToA().subscribe(() => {
            this.fetchState();
        });
    }

    adminResetToSessionB() {
        this.hackathonRestEndpoint.resetUserToB().subscribe(() => {
            this.fetchState();
        });
    }
}
