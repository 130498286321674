import {Component, OnDestroy} from "@angular/core";
import {NavigationService} from "../navigation.service";
import {BreadcrumbsService} from "../breadcrumbs/breadcrumbs.service";
import {FrontpageCarouselItemDto, GeneralType, QueryOrder} from "../../apina-digiweb";
import {BasicInfoService} from "../basic-info.service";
import {isIEOrEdge} from "../hacks/detect-ie";
import {Router} from "@angular/router";
import {combineLatest, Subscription} from "rxjs";
import {SocialPrivacyService} from "../social/social-privacy.service";
import {randomInt} from "../../utils/number-utils";

const SOCIAL_COOKIE_NAME = 'kkSocialShareFrontpage';

@Component({
    selector: "app-frontpage",
    template: `
        <div *ngIf="!loading else progress">
            <div class="bg-image">
                <div class="kk-bg-teal quick-search-links">
                    <section class="container">
                        
                        <div class="row">
                            <div class="col-12 frontpage-search-box">
                                <div class="row">
                                <h1 class="h1-front col-md-8">{{'frontpage.search.guide' | translate}}</h1>
                                <span class="qtyofpages d-flex justify-content-center col-md-4">{{pageCount}}&nbsp;
                                    <span class="padding-right:3em; numberx">{{'main.numofpages' | translate}}</span></span>
                                </div>
                                <form #form="ngForm">
                                    <div class="d-flex justify-content-between" style="margin-bottom:2.5rem;">
                                        <label for="srchfi1" class="sr-only" hidden>{{'frontpage.search.vb' | translate}}</label>
                                        <input id="srchfi1" type="text" value="" class="form-control" appAutofocus appValidQuerySyntax [(ngModel)]="query" name="query"  [placeholder]="'frontpage.search.placeholder' | translate">
                                        <button class="btn btn-kk-blue btn-block ms-3" style="width: 9rem; max-width: 20%;" (click)="gotoSearch($event)" [disabled]="form.invalid" [ngbTooltip]="'form.search.submit' | translate"><i 
                                                class="fa fa-regular fa-magnifying-glass fa-lg fa-fw" title="'form.search.submit' | translate"></i>&nbsp;&nbsp;{{'form.search.submit'|translate}}</button>
                                    </div>
                                </form>                                 
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <div class="kk-bg-dark">
            <section class="container flex justify-content-md-start kk-bg-dark">
                <div class="row ">
                    <div class="col-12">
                        <div class="row col-12" >
                            <a routerLink="/search" [queryParams]="{formats: gt}"
                               class="col-12 justify-content-left col-md-3 link-icon link-arrow general-type-search-link"
                               style="text-transform:capitalize; font-weight:normal;margin-top:0.3rem;margin-bottom:0.3rem;"
                               *ngFor="let gt of generalTypes">{{'general-type.plural.' + gt | translate}}</a></div>
                    </div>
                </div>
            </section>
            </div>

            <div class="kk-bg-grey">
                <section class="container flex justify-content-md-start kk-bg-grey">
                    <div class="row ">
                        <div class="col-12">
                            <h1 class="h1-front-b" translate>frontpage.collection.subtitle</h1>

                            <div class="row col-12"  style="width:100%">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="ie-carousel">
                                            <div *ngFor="let slide of carouselItems">
                                                <div class="kk-slide">
                                                    <a href="{{slide.targetUri}}" (click)="carouselRoute($event, slide.targetUri)">
                                                        <img src="{{ slide.imageUri }}" [alt]="slide.label">

                                                        <caption>{{slide.label}}</caption>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            
            <section class="container mb-5 dark-visitable-links">
                <div class="row">
                    <div class="col-12 order-last order-md-first">
    
                        <h2 class="mb-4" translate>frontpage.news.title</h2>
    
                        <div class="news-container">
                            <div class="news-item" *ngFor="let news of rssDigiNewsItems" style="position: relative;">
                                <article class="article--listing-liftup--news">
                                    <div class="article--media media--image" [innerHTML]="news.htmlContent[0]">
                                    </div>
                                    <div class="listing-liftup-content">
                                        <div class="listing-liftup-title-container">
                                            <h3 class="listing-liftup-title">
                                                <a class="overlay-link-liftup"
                                                   attr.href={{news.link}}>
                                                    <span class="listing-liftup-span">{{news.title}}</span>
                                                </a>
                                            </h3>
                                            <div class="listing-liftup-meta">
                                                <span class="listing-liftup-category">Uutinen</span>
                                                <span class="listing-liftup-date">
                                                    <div class="field field--name-field-publish-date field--type-datetime field--label-visually_hidden">
                                                        <div class="field__label visually-hidden">Julkaisupäivä</div>
                                                                      <div class="field--item"><time attr.datetime="{{news.pubDate | date:'yyyy-MM-dd hh:mm:ss.SSSZ'}}">{{news.pubDate | date:'dd.MM.yyyy'}}</time></div>
                                                    </div>
                                            
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="listing-liftup-text">
                                        <div class="news-item-text" [innerHTML]="news.htmlContent[1]"></div>
                                        <span class="mat-button-wrapper">
                                                    <i class="fa-regular fa-arrow-up-right-from-square"></i>
                                        </span>
                                        </div>
                                </article>
                            </div>
                        </div>
    
                        <div class="fb-page fb-mobile-news">
                            <div *ngIf="isSocialShareAllowed()"><app-facebook-content></app-facebook-content></div>
                            <div *ngIf="!isSocialShareAllowed()"><button class="btn btn-kk-blue" (click)="allowSocialShare()">{{'frontpage.social-share-privacy' | translate}}</button></div>
                        </div>
                    </div>
                </div>
                
            </section>
            
            <app-footer></app-footer>
    
            <button (click)="generateError()" style="display: none;">Generate error!</button>
        </div>
        
        <ng-template #progress>
            <app-progress-bar></app-progress-bar>
        </ng-template>
    `,
    styleUrls: [
        "./frontpage.scss"
    ]
})
export class FrontpageComponent implements OnDestroy {

    loading = true;
    
    query = "";

    generalTypes: GeneralType[];

    rssDigiNewsItems: any;

    pageCount: string;

    carouselItems: FrontpageCarouselItemDto[];

    slideConfig = {
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        variableWidth: true,
        centerMode: true,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 6000,
        initialSlide: 0,
        lazyLoad : 'onDemand'
    };

    isIE = isIEOrEdge;

    private readonly subscription: Subscription;

    constructor(private navigationService: NavigationService,
                basicInfoService: BasicInfoService,
                breadcrumbs: BreadcrumbsService,
                private socialPrivacyService: SocialPrivacyService,
                private _router: Router) {
        breadcrumbs.setLocalizedText('breadcrumbs.frontpage');

        this.subscription = combineLatest([basicInfoService.sortedGeneralTypes$, basicInfoService.frontPageContent$, basicInfoService.rssDigiNews$])
            .subscribe(([gt, content, rssnews]) => {
                this.carouselItems = content.carouselItems;
                this.pageCount = formatPageCount(content.pageCount);

                if (rssnews) {
                    const addedNews = new Set();
                    // Remove possible duplicate news from news items
                    this.rssDigiNewsItems = rssnews.item.filter((item: any) => !addedNews.has(item.title) && addedNews.add(item.title));

                    if (this.rssDigiNewsItems) {
                        this.rssDigiNewsItems.forEach((item: any) => this.getRssNewsContent(item));
                    }
                    else{
                        this.rssDigiNewsItems = [];
                    }
                }
                else{
                    this.rssDigiNewsItems = [];
                }

                this.generalTypes = gt;
                this.slideConfig.initialSlide = randomInt(this.carouselItems.length);
                this.loading = false;
            });
    }

    gotoSearch($event: Event) {
        $event.preventDefault();

        this.navigationService.goTo2([this.navigationService.basePaths.search], {
            query: this.query,
            orderBy: QueryOrder.RELEVANCE
        });
    }

    carouselRoute($event: Event, targetUri: string) {
        $event.preventDefault();
        this._router.navigateByUrl(targetUri);
    }

    generateError() {
        throw Error('this is a test');
    }

    ngOnDestroy(): void {
        if (this.subscription != null)
            this.subscription.unsubscribe();
    }

    isSocialShareAllowed(): boolean {
        return this.socialPrivacyService.isSocialShareAllowed(SOCIAL_COOKIE_NAME);
    }

    allowSocialShare() {
        this.socialPrivacyService.allowSocialShare(SOCIAL_COOKIE_NAME);
    }

    getRssNewsContent(currentNewsItem: any){
        if ('content:encoded' in currentNewsItem) {
            const htmlContent = currentNewsItem['content:encoded'].split('\n');
            const resultContent = [];

            for (const i in htmlContent){
                if (htmlContent[i].includes("img") && resultContent[0] === undefined){
                    resultContent[0] = htmlContent[i];
                }
                else if (htmlContent[i].includes("news--ingress") && resultContent[1] === undefined){
                    resultContent[1] = htmlContent[i];
                }
            }

            // adds empty results if there was no content in 'content:encoded'

            if (resultContent[0] === undefined) {
                resultContent[0] = "";
            }

            if (resultContent[1] === undefined) {
                resultContent[1] = "";
            }

            currentNewsItem.htmlContent = resultContent;
        }
        else {
            // adds empty results if there was no 'content:encoded'
            currentNewsItem.htmlContent = ["", ""];
        }
    }
}

function formatPageCount(n: number) {
    return n.toLocaleString('fi');
}
