import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {FrontpageComponent} from "./angular4/frontpage/frontpage.component";
import {BindingSearchComponent} from "./angular4/search/binding-search.component";
import {BrowseTitlesComponent} from "./angular4/browse/browse-titles.component";
import {BrowseCollectionsComponent} from "./angular4/collections/browse-collections.component";
import {UserArticleSearchComponent} from "./angular4/search/user-article-search.component";
import {PapersForDayComponent} from "./angular4/search/papers-for-day.component";
import {SavedContentComponent} from "./angular4/search/saved-content.component";
import {FallbackComponent} from "./errors/fallback.component";
import {OtherComponent} from "./angular4/misc/other.component";
import {OpendataListComponent} from "./angular4/opendata/opendata-list.component";
import {OpendataSubmitComponent} from "./angular4/opendata/opendata-submit.component";
import {BrowseIssuesByYearComponent} from "./angular4/browse/browse-issues-by-year.component";
import {BindingViewComponent} from "./angular4/binding/binding-view.component";
import {LoginPageComponent} from "./angular4/account/login-page.component";
import {UserArticleComponent} from "./angular4/article/user-article.component";
import {StatsComponent} from "./angular4/common/stats.component";
import {OpendataRequestedPackagesComponent} from "./angular4/opendata/opendata-requested-packages.component";
import {NerPersonSearchComponent} from "./angular4/ner/admin/ner-person-search.component";
import {NerNameSearchComponent} from "./angular4/ner/ner-name-search.component";
import {NotFoundComponent} from "./errors/not-found.component";
import {AccountSettingsComponent} from "./angular4/account/account-settings.component";
import {HackathonSearchComponent} from "./angular4/search/hackathon/hackathon-search.component";
import {AccountSearchHistoryComponent} from "./angular4/account/account-search-history.component";
import {NewspaperMetadataComponent} from "./angular4/browse/title/newspaper-metadata.component";
import {AfterLogoutComponent} from "./angular4/account/after-logout.component";
import {UserconsentComponent} from "./angular4/userconsent/userconsent.component";
import {HumansitemapComponent} from "./angular4/misc/humansitemap.component";
import {TutkainComponent} from "./angular4/misc/tutkain.component";
import {MediapageComponent} from "./angular4/misc/mediapage.component";

const routes: Routes = [
    { path: '', redirectTo: '/etusivu', pathMatch: 'full' },
    { path: 'etusivu', component: FrontpageComponent, data: { titleKey: 'main.links.main'} },
    { path: 'signin', component: LoginPageComponent },
    { path: 'search', component: BindingSearchComponent, data: {titleKey: 'search.main.header'} },
    { path: 'serial-publications', component: BrowseTitlesComponent, data: {titleKey: 'serialpublications.main.header'} },
    { path: ':context/binding/:bindingId/articles/:articleId/edit', component: BindingViewComponent, data: {dynamicTitle: true} },
    { path: ':context/binding/:bindingId/articles/:articleId', component: UserArticleComponent, data: {dynamicTitle: true} },
    { path: ':context/binding/:bindingId', component: BindingViewComponent, data: {dynamicTitle: true} },
    { path: ':context/titles/:publicationId', component: BrowseIssuesByYearComponent, data: {titleKey: 'serialpublications.main.header'} },
    { path: 'collections', component: BrowseCollectionsComponent, data: {titleKey: 'kirja.main.links.collection'} },
    { path: 'ptolemaios', redirectTo: '/collections?id=821' },  // short url for flyer
    { path: 'clippings', component: UserArticleSearchComponent, data: {titleKey: 'main.links.user.articles'} },
    { path: 'papers-for-day', component: PapersForDayComponent, data: {titleKey: 'papers-for-day.main.header'} },
    { path: 'account/settings', component: AccountSettingsComponent },
    { path: 'account/my-search-history', component: AccountSearchHistoryComponent, data: {titleKey: 'my-search-history.title'} },
    { path: 'account', component: SavedContentComponent, data: {titleKey: 'scrapbook.saved-content'} },
    { path: 'other', component: OtherComponent, data: {titleKey: 'others.main.header'} },
    { path: 'sivustokartta', component: HumansitemapComponent, data: {titleKey: 'sitemap.title'} },
    { path: 'tutkain', component: TutkainComponent, data: {titleKey: 'tutkain.page.title'} },
    { path: 'media', component: MediapageComponent, data: {titleKey: 'mediapage.title'} },
    { path: 'opendata/submit', component: OpendataSubmitComponent, data: {titleKey: 'opendata.footer.title'} },
    { path: 'opendata/requested-packages/:hash', component: OpendataRequestedPackagesComponent, data: {titleKey: 'opendata.footer.title'} },
    { path: 'opendata', component: OpendataListComponent, data: {titleKey: 'opendata.footer.title'} },
    { path: 'stats', component: StatsComponent, data: {titleKey: 'main.stats'} },
    { path: 'evasteasetukset', component: UserconsentComponent, data: {titleKey: 'cookies.title'} },
    { path: 'admin/person-search', component: NerPersonSearchComponent, data: {titleKey: 'main.person-search'} },
    { path: 'name-search', component: NerNameSearchComponent, data: {titleKey: 'main.name-search'} },
    { path: 'sanomalehti/titles/:issn/data', component: NewspaperMetadataComponent },
    { path: 'sanomalehti/directory/:id', component: FallbackComponent },
    { path: 'sanomalehti/directory', component: FallbackComponent },
    { path: 'pienpainate/directory/:id', component: FallbackComponent },
    { path: 'pienpainate/directory', component: FallbackComponent },
    { path: 'pienpainate/publishers/:letterOrName', component: FallbackComponent },
    { path: 'pienpainate/publishers', component: FallbackComponent },
    { path: 'pienpainate/years/:year', component: FallbackComponent },
    { path: 'pienpainate/centuries/:year', component: FallbackComponent },
    { path: 'pienpainate/centuries', component: FallbackComponent },
    { path: 'terms', component: FallbackComponent },
    { path: 'info', component: FallbackComponent },
    { path: 'faq', component: FallbackComponent },
    { path: 'admin/caches', component: FallbackComponent },
    { path: 'digitalia', component: FallbackComponent },
    { path: 'media', component: FallbackComponent },
    { path: 'expr', component: FallbackComponent },
    { path: 'downloadtool-update-checker', component: FallbackComponent },
    { path: 'saavutettavuus', component: FallbackComponent },
    { path: 'account/locked', component: FallbackComponent },
    { path: 'account/register', component: FallbackComponent },
    { path: 'account/change-password', component: FallbackComponent },
    { path: 'account/request-password-reset', component: FallbackComponent },
    { path: 'account/request-password-reset-confirm', component: FallbackComponent },
    { path: 'account/password-reset/:token', component: FallbackComponent },
    { path: 'account/password-reset', component: FallbackComponent },
    { path: 'account/retrieve-username', component: FallbackComponent },
    { path: 'account/retrieve-username-confirm', component: FallbackComponent },
    { path: 'account/retrieve-gdpr', component: FallbackComponent },
    { path: 'account/retrieve-gdpr-confirm', component: FallbackComponent },
    { path: 'account/remove-confirm', component: FallbackComponent },
    { path: 'account-removed', component: FallbackComponent },
    { path: 'newseye-hackathon', component: HackathonSearchComponent },
    { path: 'api/documentation', component: FallbackComponent },
    { path: 'after-logout', component: AfterLogoutComponent },
    { path: '**', component: NotFoundComponent, data: {noReload: true} } // see account.service.ts#reloadUI
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {})
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {

}
