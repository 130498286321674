import {debounceTime, map, switchMap} from 'rxjs/operators';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, forwardRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild} from "@angular/core";
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {BehaviorSubject, combineLatest, merge, Observable, of, Subject, Subscription} from "rxjs";
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {ANIM_ADD_REMOVE_IN, ANIM_OPEN_CLOSE_OPEN, ANIM_STATIC, animAddRemoveSelection, animOpenClose} from "../animations/animations";
import {addContext} from "../../utils/observable-utils";

const SELECT_ALL_MAX_ITEMS = 25;
const ITEMS_LOADED_BEFORE_SEEN = SELECT_ALL_MAX_ITEMS + 1; // so that the select all text does not flicker when picker initially opened 
const MAX_DISPLAYED_ITEMS = 250;

const FORMATTER_DEFAULTS: PopoverFormatter = {
    selectedFormat: {
        showInfo1: true,
        showInfo2: true,
        trimTitle: false
    },
    unselectedFormat: {
        showInfo1: true,
        showInfo2: true,
        trimTitle: false
    }
};

export interface PopoverItemFormat {
    trimTitle?: boolean;
    showInfo1?: boolean;
    showInfo2?: boolean;
}

export interface PopoverFormatter {
    selectedFormat?: PopoverItemFormat;
    unselectedFormat?: PopoverItemFormat;
}

export interface PopoverMapper {
    map(a: IPopoverItem): IPopoverItem;
}

export interface IPopoverItem {
    id: any;
    title: string;
    /**
     * Optional info-row shown below the title.
     */
    info?: string;
    /**
     * Optional second info-row.
     */
    info2?: string;
    /**
     * Used in prefixing the title when a single item is selected.
     */
    type?: string;

    /**
     * Used in sorting filtered values.
     */
    score?: number;
}

export interface IPopoverItemProvider {
    /**
     * Get IPopoverItem results from this provider. Should apply any possible extra filters.
     */
    fetch(query: string, selectedIds: string[], maxResults: number): Observable<IPopoverResult>;

    /**
     * Returns true, if the given item matches this provider's extra filters, or false otherwise.
     */
    matches(item: IPopoverItem): boolean;
}

export interface IPopoverResult {
    readonly selectedIds: IPopoverItem[];
    readonly queryResults: IPopoverItem[];
    readonly totalResults: number;
    readonly totalPossibleResults: number;
}

@Component({
    selector: "app-popover-picker-async",
    styleUrls: ["./popover-picker.component.scss"],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => PopoverPickerAsyncComponent),
        multi: true
    }],
    animations: [
        animOpenClose,
        animAddRemoveSelection
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <ng-template #picker>
            <div class="app-popover popover-picker-popup-content" [ngClass]="{large: large}">
                <!-- FIXME aria label -->
                <button type="button" class="close" (click)="popover.close()" attr.aria-label="{{'list.close.tooltip' | translate}}">&times;</button>
                <app-progress-spinner *ngIf="!items"></app-progress-spinner>
                <div *ngIf="items">
                    <div [@openClose]="openCloseAnim" *ngIf="hasSelections()">
                        <h4 class="my-2">{{'popover-picker.filters' | translate}}</h4>

                        <ul class="items">
                            <li *ngFor="let item of selectedItems; trackBy: popoverItemTrack"
                                [ngClass]="{conflict: isConflictingItem(item)}"
                                [@addRemoveSelection]="addRemoveAnim">
                                <a href="" (click)="deselectItem($event, item)">
                                    <i class="fa fa-minus float-right"></i>
                                    <div class="title">{{formatTitle(item, true)}}</div>
                                    <div class="info" *ngIf="showInfo1(item, true)">{{item.info}}</div>
                                    <div class="info" *ngIf="showInfo2(item, true)">{{item.info2}}</div>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <h4 class="my-2">{{'popover-picker.add-filter' | translate}} ({{('popover-picker.single.' + type) | translate}})</h4>

                    <div class="input-group item-filter">
                        <input type="text" class="form-control" placeholder="{{'popover-picker.search' | translate}}"
                               [formControl]="filterQuery" aria-label="Select" appAutofocus autofocusDelay="50">
                    </div>

                    <ul class="items filters" [ngClass]="{large: large}" appScrollControl [resetScroll]="resetScroll$">
                        <li *ngFor="let item of items; trackBy: popoverItemTrack" (click)="selectItem($event, item)">
                            <a href="">
                                <i class="fa fa-plus"></i>
                                <div class="title">{{formatTitle(item, false)}}</div>
                                <div class="info" *ngIf="showInfo1(item, false)">{{item.info}}</div>
                                <div class="info" *ngIf="showInfo2(item, false)">{{item.info2}}</div>
                            </a>
                        </li>
                        <li *ngIf="items.length < totalResults" class="text-center">
                            <button type="button" class="btn btn-kk-blue" (click)="displayMoreOptions($event)" translate>popover-picker.display-more</button>
                        </li>
                    </ul>

                    <div class="d-flex justify-content-between flex-row-reverse">
                        <span class="text-center">{{'popover-picker.possible-filters' | translate}} {{totalResults}} / {{totalPossibleResults}}</span>

                        <span *ngIf="items.length > 0 && items.length <= selectAllVisibilityLimit">
                            <a href="" (click)="selectAllItems($event)" [translate]="'popover-picker.select-all'" [translateParams]="{count: items.length}"></a>
                        </span>
                    </div>
                </div>
            </div>
        </ng-template>

        <div class="input-group popover-picker" [ngClass]="{conflict: hasConflictingItems()}">
            <input type="text" class="form-control" readonly [value]="inputText" attr.aria-label="{{'list.choices.tooltip' | translate}}" [disabled]="_isDisabled"
                   #input (focus)="popover.open()" [ngClass]="{'has-selections': hasSelections()}">
            <span class="input-group-append">
                <button type="button" class="btn btn-kk" [ngbPopover]="picker" placement="{{placement}}" [autoClose]="false" (shown)="opened.next()"
                        #popover="ngbPopover" attr.aria-label="{{'list.descr.tooltip'|translate}}" [disabled]="_isDisabled" appClosePopoverOnClickOutside [parentInput]="input">
                    <i class="fa fa-caret-down"></i></button>
            </span>
        </div>
    `
})
export class PopoverPickerAsyncComponent implements ControlValueAccessor, AfterViewInit, OnChanges, DoCheck, OnDestroy {

    readonly selectAllVisibilityLimit = SELECT_ALL_MAX_ITEMS;

    @Input() type: string;
    @Input() placement = "bottom-right";
    @Input() _isDisabled = false;
    @Input() large = true;
    @Input() formatter: PopoverFormatter;

    /**
     * AngularJS doesn't like the "_" in "_isDisabled", so call this from legacy code instead.
     */
    @Input() set setDisabled(value: boolean) {
        if (value !== undefined)
            this._isDisabled = value;
    }

    @Input() set provider(value: IPopoverItemProvider) {
        this._provider = value;
    }

    get items(): IPopoverItem[] {
        return this._items;
    }

    @ViewChild("popover", {static: true}) pickerPopover: NgbPopover;

    opened = new Subject();
    openCloseAnim = ANIM_STATIC;
    addRemoveAnim = ANIM_STATIC;
    
    resetScroll$ = new Subject<void>();
    
    filterQuery = new UntypedFormControl("");
    
    /** the "native" input field that we are beefing up */
    inputText = "";
    
    selectedIds$ = new BehaviorSubject<string[]>([]);
    // For manual change detection of the input array.
    // The included items should not change, but can be added or removed. 
    // Thus, we can implement change detection simply by checking if the array length changes.
    private selectionLength = 0;

    filterValue$: Observable<string>;
    triggerRefresh$ = new BehaviorSubject<void>(null);
    
    selectedItems: IPopoverItem[] = [];
    displayItemsUpTo = MAX_DISPLAYED_ITEMS;

    _provider: IPopoverItemProvider;
    _items: IPopoverItem[];
    totalResults: number;
    totalPossibleResults: number;
    _formatter: PopoverFormatter;

    private readonly subs = new Subscription();

    propagateChange = (_: any) => {};

    constructor(private translateService: TranslateService,
                private readonly cd: ChangeDetectorRef) {

        // It's pointless to load items that will never be seen by the user, so only fetch so many that we have something to display instantly
        // if the picker is opened, and immediately load more results before the user decides to scroll.
        let notSeen = true;
        this.subs.add(this.opened.subscribe(() => {
            if (notSeen) {
                notSeen = false;
                this.triggerRefresh$.next();
            }
        }));

        const initialFilterValue: string = null;
        this.filterValue$ = merge(of(initialFilterValue), this.filterQuery.valueChanges.pipe(debounceTime(500)));
        
        let previousFilterValue = initialFilterValue;
        
        this.subs.add(combineLatest([this.filterValue$, this.selectedIds$, this.triggerRefresh$]).pipe(
            debounceTime(50), // stabilize a bit
            switchMap(([filter, selectedIds]) => {
                const filterChanged = filter !== previousFilterValue;
                previousFilterValue = filter;
                
                if (filterChanged) {
                    // reset fetched item count to original when user changes filter text
                    this.displayItemsUpTo = MAX_DISPLAYED_ITEMS;
                }
                
                return addContext(this._provider.fetch(filter, selectedIds, notSeen ? ITEMS_LOADED_BEFORE_SEEN : this.displayItemsUpTo), filterChanged);
            })
        ).subscribe((r) => {
            const results = r.payload;
            this._items = results.queryResults;
            this.selectedItems = results.selectedIds;
            this.totalResults = results.totalResults;
            this.totalPossibleResults = results.totalPossibleResults;
            this.updateInputFieldText();
            this.cd.markForCheck();

            if (r.context) // filterChanged 
                this.resetScroll$.next();
        }));
    }
    
    ngDoCheck(): void {
        const prevLength = this.selectionLength;
        this.selectionLength = this.selectedIds$.value.length;
        
        if (prevLength !== this.selectionLength) {
            this.triggerRefresh$.next();
            this.cd.markForCheck();
        }
    }
    
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.subs.add(merge(
            this.pickerPopover.shown.pipe(map(() => true), debounceTime(1)),
            this.pickerPopover.hidden.pipe(map(() => false))
        ).subscribe((shown) => {
            this.openCloseAnim = shown ? ANIM_OPEN_CLOSE_OPEN : ANIM_STATIC;
            this.addRemoveAnim = shown ? ANIM_ADD_REMOVE_IN : ANIM_STATIC;
        }));
    }

    writeValue(obj: any): void {
        this.selectedIds$.next(obj || []);
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    // Note: ControlValueAccessor's disable state setting is not working from angularJS
    setDisabledState(isDisabled: boolean): void {
        this._isDisabled = isDisabled;
        if (this._isDisabled && this.pickerPopover.isOpen()) {
            this.pickerPopover.close();
        }
    }

    hasSelections(): boolean {
        return this.selectedItems.length > 0;
    }

    isConflictingItem(item: IPopoverItem): boolean {
        return !this._provider.matches(item);
    }

    hasConflictingItems(): boolean {
        if (!this._provider) return false;

        return _.some(this.selectedItems, (item: any) => this.isConflictingItem(item));
    }

    updateInputFieldText(): void {
        const selected = this.selectedItems;

        if (selected.length === 0) {
            this.inputText = this.translateService.instant('popover-picker.all') + ' ' + this.translateService.instant(`popover-picker.all.${this.type}`);
        } else if (selected.length === 1) {
            const s = selected[0];
            const prefix = s.type ? s.type : this.translateService.instant(`popover-picker.single.${this.type}`);
            this.inputText = `${prefix}: ${s.title?.trim()}`; // collections have padding in title due to tree structure
        } else {
            this.inputText = this.translateService.instant(`popover-picker.counted.${this.type}`, { count: selected.length });
        }
    }

    selectItem(event: Event, item: IPopoverItem) {
        event.preventDefault();
        event.stopPropagation();
        
        const newId = item.id;
        const ids = this.selectedIds$.value;
        if (!ids.includes(newId)) {
            ids.push(newId);

            // notify change
            this.propagateChange(ids);
        }
    }

    selectAllItems(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        
        for (const item of this._items) {
            const newId = item.id;
            const ids = this.selectedIds$.value;
            console.log(newId, ids);
            if (!ids.includes(newId)) {
                ids.push(newId);
            }
        }

        // notify change
        this.propagateChange(this.selectedIds$.value);
    }

    deselectItem(event: Event, item: IPopoverItem) {
        event.preventDefault();
        event.stopPropagation();

        this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
        this.selectedIds$.value.splice(this.selectedIds$.value.indexOf(item.id), 1);

        // notify change
        this.propagateChange(this.selectedIds$.value);
    }

    // Function to track IPopoverItems in *ngFor
    popoverItemTrack(index: number, item: IPopoverItem) {
        return item.id;
    }

    formatTitle(item: IPopoverItem, selected: boolean): string {
        const value = item.title;
        return this.getFormat(selected).trimTitle ? value?.trim(): value;
    }

    showInfo1(item: IPopoverItem, selected: boolean): boolean {
        return !!item.info && this.getFormat(selected).showInfo1;
    }

    showInfo2(item: IPopoverItem, selected: boolean): boolean {
        return !!item.info2 && this.getFormat(selected).showInfo2;
    }

    private getFormat(selected: boolean): PopoverItemFormat {
        return selected? this._formatter.selectedFormat : this._formatter.unselectedFormat;
    }

    displayMoreOptions(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.displayItemsUpTo = this.items.length + MAX_DISPLAYED_ITEMS;
        this.triggerRefresh$.next();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this._formatter = _.defaultsDeep({}, this.formatter, FORMATTER_DEFAULTS);
    }
}
