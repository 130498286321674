import {Component, Inject, Input} from "@angular/core";
import {DisplayService} from "../../display.service";
import {BindingService} from "../binding.service";
import {BehaviorSubject} from "rxjs";
import {BindingRestEndpoint} from "../../../apina-digiweb";
import {map, switchMap} from "rxjs/operators";
import {BINDING_VIEW, ICurrentBindingView} from "../../../binding/types";

enum Mode {MARC = "MARC", NEWSPAPER = "NEWSPAPER"}

@Component({
    selector: "app-binding-marc-text",
    template: `
        <app-sidebar>
            <app-sidebar-header titleKey="marc.view.title" (closeSidebar)="cbv.toggleMarc()">
                
                    <a class="ms-4 light" href="#" (click)="$event.preventDefault(); showMarc = !showMarc">{{showMarc ? ('marc.hide-marc' | translate) : ('marc.show-marc' | translate)}}</a>
            </app-sidebar-header>
            
            <app-sidebar-content [padding]="false">
                <div class="kk-bg-lightgray">
                    
                    <a *ngIf="partFinnaId$ | async as finnaId" [href]="'https://kansalliskirjasto.finna.fi/Record/' + finnaId" target="_blank" class="part-finna-link">
                        <img alt="Finna" src="/images/finna/FNA_tunnus_vaaka_WEB_viol.png">
                        <i class="fa-regular fa-arrow-up-right-from-square fa-fw"></i>
                    </a>
                    
                    <div *ngIf="marcRecords$ | async as marcRecords else loading">
                        
                        <div class="alert alert-warning" *ngIf="marcRecords?.length === 0">
                            <p translate>binding.marc.not-found</p>
                        </div>
        
                        <div *ngIf="!showMarc && marcRecords?.length > 0">
                            <app-user-friendly-marc-record [record]="marcRecords[0]"></app-user-friendly-marc-record>
                        </div>
        
                        <div *ngIf="showMarc">
                            <div *ngFor="let record of marcRecords" class="pt-2 pb-2">
                                <app-technical-marc-record *ngIf="showMarc" [record]="record"></app-technical-marc-record>
                            </div>
                        </div>
                    </div>
                </div>
            </app-sidebar-content>
        </app-sidebar>

        <ng-template #loading>
            <app-progress-spinner></app-progress-spinner>
        </ng-template>
    `,
    styleUrls: [
        "./binding-marc-text.scss"
    ]
})
export class BindingMarcTextComponent {

    @Input() set componentPart(value: string) {
        this.partFinnaId$.next(value);
    }
    
    constructor(public displayService: DisplayService,
                private bindingService: BindingService,
                private bindingRest: BindingRestEndpoint,
                @Inject(BINDING_VIEW) public readonly cbv: ICurrentBindingView) {
    }

    partFinnaId$ = new BehaviorSubject<string>(null);
    
    marcRecords$ = this.partFinnaId$.pipe(switchMap((finnaId) => {
        if (finnaId == null)
            return this.bindingService.getMarcRecords();
        else
            return this.bindingRest.getLatestMarcForComponentPart(finnaId).pipe(map(a => [a]));
    }));

    showMarc = false;
}
