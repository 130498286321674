import {Component} from "@angular/core";
import {SettingsService} from "../settings.service";
import {DisplayService} from "../display.service";
import {NavigationService} from "../navigation.service";
import {AccountService} from "../account/account.service";
import {TranslateService} from "@ngx-translate/core";
import { FeedbackService } from "../feedback/feedback.service";

@Component({
    selector: "app-brand-area",
    template: `
        <header [hidden]="!displayService.showTopNavigation" class="brand" [ngClass]="{minimized: minimized}">
            <div class="container">
                <div class="row d-flex align-items-center pl-3 pl-sm-0 pr-3 pr-sm-0"
                     [ngClass]="{'pt-2 pb-2': !minimized}">
                    <div class="logo">
                        <a [href]="basePaths.frontPage">
                            <span class="sr-only translate">breadcrumbs.frontpage</span>
                            <img title="{{'main.title'|translate}}" alt="main.title" [src]="settingsService.logoPathDark" class="d-none d-md-block me-2"
                                 style="padding-top: 8px;padding-bottom: 8px;padding-right: 8px;">
                        </a>
                    </div>


                    <div class="ms-2" [ngClass]="{'p-2': !minimized}">
                        <app-main-title></app-main-title>
                    </div>

                    <div class="d-none d-lg-block" style="padding-left: 6px;">
                        <div class="row d-flex align-items-center pl-3 pl-sm-0 pr-3 pr-sm-0" [ngClass]="{'pt-2 pb-2': minimized}">
                                <div class="logo eu_logo d-flex justify-content-around align-items-center" style="padding-left: 6px;">
                                    <img src="{{'eulogo.flag' | translate }}" alt="{{'frontpage.euflagalt' | translate}}">
                                    <img src="{{'eulogo.lever' | translate }}" alt="{{'frontpage.vipualt' | translate}}">
                                </div>
                        </div>
                    </div>


                    <div class="ms-auto d-flex align-items-center">
                        <span *ngIf="feedbackEnabled">
                            <a href="#" class="btn btn-kk-light" (click)="$event.preventDefault(); feedbackService.toggleFeedbackForm()">
                                <i class="fa fa-comment-o"></i>
                                {{'main.links.feedback' | translate}}
                            </a>
                        </span>
                        
                        <button type="button" class="btn btn-kk-light ms-2" [matMenuTriggerFor]="language_menu" >
                            <i class="fa-light fa-globe"></i>&nbsp;
                            <span id="language_menu_top">{{'current.language'|translate}}</span>&nbsp;
                            <i class="fa-light fa-chevron-down"></i></button>
                        <mat-menu #language_menu="matMenu">
                            <button mat-select mat-menu-item (click)="navigationService.changeLanguage($event, 'fi');">Suomeksi</button>                            
                            <button mat-menu-item (click)="navigationService.changeLanguage($event, 'sv');">På svenska</button>
                            <button mat-menu-item (click)="navigationService.changeLanguage($event, 'en');">In English</button>
                        </mat-menu>

 
                        <ng-container *ngIf="accountService.currentUserInfo$ | async as cui; else loading">
                            <div class="ms-2" [ngSwitch]="cui.loggedIn">
                                <app-account-dropdown *ngSwitchCase="true"></app-account-dropdown>
                                <div *ngSwitchCase="false">
                                    <a class="btn btn-kk-blue d-inline-block d-md-none"
                                       (click)="accountService.login($event)" href="" rel="nofollow">
                                        <i class="fa fa-user" title="'account.login.action' | translate"></i>
                                        <span class="sr-only">account.login.action</span></a>
                                    <a class="btn btn-kk-blue d-none d-md-inline-block"
                                       (click)="accountService.login($event)" href="" rel="nofollow">
                                        <i class="fa-regular fa-user fa-fw"></i>&nbsp;&nbsp;{{'account.login.action' | translate}}</a>
                                </div>
                            </div>
                        </ng-container>

                        <ng-template #loading>
                            <app-progress-spinner class="ms-3"></app-progress-spinner>
                        </ng-template>
                    </div>
                </div>
            </div>
        </header>
    `,
    styleUrls: [
        "./brand-area.scss"
    ]
})
export class BrandAreaComponent {

    get basePaths() { return this.navigationService.basePaths; }

    get feedbackEnabled() {
        return this.settingsService.feedbackEnabled;
    }

    get minimized() {
        return this.displayService.headerMinimized;
    }

    constructor(public displayService: DisplayService,
                public accountService: AccountService,
                public navigationService: NavigationService,
                public settingsService: SettingsService,
                public feedbackService: FeedbackService,
                private translateService: TranslateService) {
    }

    get heightXs() {
        return this.minimized ? 35 : 80;
    }

    get heightMd() {
        return this.minimized ? 45 : 95;
    }

}
