import {Component} from "@angular/core";

@Component({
    template: `
        <section class="container">
            <p translate>session.logout.after.title</p>

            <div class="alert alert-info"><i class="fa fa-exclamation-circle me-2"></i><span translate>session.logout.after.body</span></div>    
        </section>
    `
})
export class AfterLogoutComponent {
    
}