import {Component, OnDestroy} from "@angular/core";
import {AccountService} from "./account.service";
import {UntypedFormControl} from "@angular/forms";
import {AccountRestEndpoint} from "../../apina-digiweb";
import {Subscription} from "rxjs";
import {NavigationService} from "../navigation.service";

@Component({
    selector: 'app-account-settings',
    template: `
        <div class="container mt-4">
            <h2 translate>account.settings</h2>

            <div *ngIf="accountService.userProperties$ | async as up; else loading">

                <div *ngIf="up.currentUserInfo.loggedIn; else pleaseLogin">

                    <div class="setting-row" *ngIf="up.isTermsConfirmationRequired">
                        <p translate>account.settings.terms-confirm.info</p>

                        <button class="btn btn-kk-blue" (click)="accountService.openTermsConfirmationDialog()"
                                translate>
                            account.settings.terms-confirm.open
                        </button>
                    </div>

                    <div class="setting-row">
                        <label>
                            <input type="checkbox" (click)="toggleSearchLimit()" [formControl]="limitSearch"/>
                            {{'account.settings.no-search-limit' | translate}}
                        </label>
                    </div>

                    <div class="setting-row">
                        <label>
                            <input type="checkbox" (click)="toggleExcelOcr()" [formControl]="excelOcr"/>
                            {{'account.settings.excel-ocr' | translate}}
                        </label>
                    </div>

                    <div class="setting-row">
                        <label>
                            <input type="checkbox" (click)="toggleSaveSearchHistory()" [formControl]="searchHistory"/>
                            {{'account.settings.save-search-history' | translate}}
                        </label>
                    </div>
                    
                    <hr>
                    <app-import-user-articles></app-import-user-articles>
                    <br>
                    <hr>
                    <div class="setting-row">
                        <a [href]="navigationService.basePaths.removeAccount" translate>account-remove.title</a>
                    </div>
                    
                </div>
            </div>
        </div>

        <ng-template #pleaseLogin>
            {{'account.settings.login-required' | translate}}
        </ng-template>

        <ng-template #noSettings>
            {{'account.settings.not-available' | translate}}
        </ng-template>

        <ng-template #loading>
            <app-progress-spinner></app-progress-spinner>
        </ng-template>
    `,
    styles: [
        ".setting-row { padding-top: 1em; padding-bottom: 1em; }"
    ]
})
export class AccountSettingsComponent implements OnDestroy {

    limitSearch = new UntypedFormControl();
    excelOcr = new UntypedFormControl();
    searchHistory = new UntypedFormControl();

    private subscription = new Subscription();

    constructor(public accountService: AccountService,
                private readonly accountEndpoint: AccountRestEndpoint,
                public navigationService: NavigationService) {

        this.subscription.add(accountService.preferences$.subscribe((prefs) => {
            this.limitSearch.setValue(prefs.noSearchLimit);
            this.excelOcr.setValue(prefs.excelOcr);
            this.searchHistory.setValue(prefs.saveSearchHistory);
        }));
    }

    toggleSearchLimit() {
        this.accountEndpoint.toggleSearchLimit().subscribe(() => {
            this.refresh();
        });
    }

    toggleExcelOcr() {
        this.accountEndpoint.toggleExcelOcr().subscribe(() => {
            this.refresh();
        });
    }

    toggleSaveSearchHistory() {
        this.accountEndpoint.toggleSaveSearchHistory().subscribe(() => {
            this.refresh();
        });
    }

    private refresh() {
        this.accountService.refresh();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
