import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {BINDING_VIEW, IClipping, ICurrentBindingView, IPageInformation, IRegion} from "../../binding/types";
import {TranslateService} from "@ngx-translate/core";
import {debounceTime, distinctUntilChanged, filter, map, switchMap} from "rxjs/operators";
import {BehaviorSubject, combineLatest, Observable} from "rxjs";
import {Subscriptions} from "../../utils/observable-utils";
import {GeneralType} from "../../apina-digiweb";

@Component({
    selector: "app-print-clipping-form",
    template: `
        <app-sidebar>
            <app-sidebar-header titleKey="common.action.print" (closeSidebar)="cancel()"></app-sidebar-header>
            <app-sidebar-content>
                <form name="metadataForm" role="form" #f="ngForm">
                    <div id="canvasContainer" style="width: auto;height: auto;overflow:auto;max-height: 400px;">
                        <canvas id="printCanvas" width="0" height="0" style="border:1px solid #000000;"></canvas>
                    </div>
                    <div class="float-right">
                        <button type="button" class="btn btn-kk-blue me-1" (click)="cancel()"
                                [disabled]="cbv.saving" translate>common.action.cancel
                        </button>
                        <button class="btn btn-kk-light" (click)="printCanvas()" [disabled]="(hasActiveClippings$ | async) === false">
                            <span translate>common.action.print</span>
                            <app-progress-spinner *ngIf="cbv.saving"></app-progress-spinner>
                        </button>
                    </div>
                </form>
            </app-sidebar-content>
        </app-sidebar>
    `
})
export class PrintClippingFormComponent implements OnInit, OnDestroy {

    constructor(@Inject(BINDING_VIEW) public cbv: ICurrentBindingView,
                private translate: TranslateService) {
    }

    private subs = new Subscriptions();

    collapsed = false;
    pageClippingsCache: ImageData[];
    lastModifiedCacheItem = -1;

    hasActiveClippings$: Observable<boolean> = this.cbv.clippings$.pipe(map(a => a.length > 0), distinctUntilChanged());

    ngOnInit(): void {
        const view = this.cbv;
        const initialized = new BehaviorSubject<boolean>(false);

        const image$ = view.loadedPage$.pipe(switchMap(p => p.image));

        this.subs.add(view.pagesCount$.subscribe(pageCount => {
            this.pageClippingsCache = new Array(pageCount);
            initialized.next(true);
        }));

        this.subs.add(combineLatest([initialized.pipe(filter(a => a === true)), view.loadedPage$, image$, view.clippings$, view.zoomEvents$])
            .pipe(debounceTime(500))
            .subscribe(([, page, image, clippings]) => {
                this.clippedRegionChanged(page, image, clippings);
            }));
    }

    cancel() {
        this.cbv.cancelClipping(true);
    }

    getClippedRegion(image: HTMLImageElement,
                     currentRegion: IRegion,
                     canvas: HTMLCanvasElement,
                     ctx: CanvasRenderingContext2D,
                     startY: number,
                     pageLastClipping: boolean,
                     scaleF: number,
                     currentPageNumber: number) {

        const {x, y, width, height} = currentRegion;

        const pImage = document.getElementById('pageImage') as HTMLImageElement;

        const scaleWidth = pImage.width / image.width;
        const scaleHeight = pImage.height / image.height;

        const imageWidth = pImage.width;
        const imageHeight = pImage.height;

        const correctWidth = scaleWidth * (scaleF * width);
        let correctHeight = 0;
        let tempCanvasData;

        if (startY === 0)
            correctHeight = scaleHeight * (scaleF * height);
        else {

            // if there is earlier clipped regions in canvas their total height startY is added to current region height
            // and earlier canvas data is copied from canvas.
            correctHeight = startY + scaleHeight * (scaleF * height);
            tempCanvasData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        }

        if (canvas.width < correctWidth)
            canvas.width = correctWidth; // correctWidth;

        canvas.height = correctHeight;

        let currentCacheHeight = 0;

        const currentPageMinusOne = currentPageNumber - 1;

        if (pageLastClipping) {

            for (let i = 0; i < this.pageClippingsCache.length; i++) {
                if (i !== currentPageMinusOne && this.pageClippingsCache[i] !== undefined) {

                    currentCacheHeight += this.pageClippingsCache[i].height;
                }
            }

            if (currentCacheHeight !== 0)
                canvas.height = correctHeight + currentCacheHeight;
        }

        // Puts earlier canvas data back to canvas if there is earlier clipped regions.
        if (startY !== 0)
            ctx.putImageData(tempCanvasData, 0, 0);

        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = scaleWidth * image.width;
        tempCanvas.height = scaleHeight * image.height;

        const tempCtx = tempCanvas.getContext('2d');

        tempCtx.drawImage(pImage, 0, 0, image.width, image.height, 0, 0, scaleWidth * image.width, scaleHeight * image.height);

        const imgData = tempCtx.getImageData(scaleWidth * (scaleF * x), scaleHeight * (scaleF * y), scaleWidth * (scaleF * width), scaleHeight * (scaleF * height));

        ctx.putImageData(imgData, 0, startY);

        if (pageLastClipping) {

            this.pageClippingsCache[currentPageMinusOne] = ctx.getImageData(0, 0, ctx.canvas.width, correctHeight);
            this.lastModifiedCacheItem = currentPageMinusOne;

            let maxCanvasWidth = 0;

            for (let i = 0; i < this.pageClippingsCache.length; i++) {
                if (this.pageClippingsCache[i] !== undefined)
                    if (this.pageClippingsCache[i].width > maxCanvasWidth)
                        maxCanvasWidth = this.pageClippingsCache[i].width;
            }

            tempCtx.clearRect(0, 0, tempCanvas.width, tempCanvas.height);
            tempCanvas.width = maxCanvasWidth;
            canvas.width = maxCanvasWidth;
            tempCanvas.height = currentCacheHeight;

            let cacheY = 0;

            for (let i = 0; i < this.pageClippingsCache.length; i++) {
                if (i !== (currentPageMinusOne) && this.pageClippingsCache[i] !== undefined) {
                    tempCtx.putImageData(this.pageClippingsCache[i], 0, cacheY);
                    cacheY += this.pageClippingsCache[i].height;
                }
            }
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

            if (currentCacheHeight !== 0)
                ctx.putImageData(tempCtx.getImageData(0, 0, tempCanvas.width, tempCanvas.height), 0, 0);

            ctx.putImageData(this.pageClippingsCache[currentPageMinusOne], 0, currentCacheHeight);
        }

        return correctHeight;
    }

    // Removes current pages last remaining clipping from pageClippingCache and redraws cache items to canvas
    removePagesLastClipping(canvas: HTMLCanvasElement, currentPageNumber: number) {
        const tempCanvas = document.createElement('canvas') as HTMLCanvasElement;
        const tempCtx = tempCanvas.getContext('2d');
        const currentPageMinusOne = currentPageNumber - 1;

        this.pageClippingsCache[currentPageMinusOne] = undefined;
        this.lastModifiedCacheItem = -1;

        let currentCacheHeight = 0;
        let currentCacheWidth = 0;

        for (let i = 0; i < this.pageClippingsCache.length; i++) {
            if (this.pageClippingsCache[i] !== undefined) {

                currentCacheHeight += this.pageClippingsCache[i].height;

                if (currentCacheWidth < this.pageClippingsCache[i].width)
                    currentCacheWidth = this.pageClippingsCache[i].width;
            }
        }

        tempCanvas.width = currentCacheWidth;
        tempCanvas.height = currentCacheHeight;

        let cacheY = 0;

        for (let i = 0; i < this.pageClippingsCache.length; i++) {
            if (this.pageClippingsCache[i] !== undefined) {
                tempCtx.putImageData(this.pageClippingsCache[i], 0, cacheY);
                cacheY += this.pageClippingsCache[i].height;
            }
        }

        if (this.pageClippingsCache.length !== 0) {
            canvas.height = cacheY;
            canvas.width = currentCacheWidth;
            canvas.getContext('2d').putImageData(tempCtx.getImageData(0, 0, tempCanvas.width, tempCanvas.height), 0, 0);
        }
    }

    printCanvas() {
        this.subs.switchable("printCanvas", combineLatest([this.cbv.bindingInfo$, this.cbv.loadedPageNumber$])
            .subscribe(([bindingData, currentPageNumber]) => {

                const generalType = bindingData.generalType;
                let generalTitle;

                if (generalType === GeneralType.NEWSPAPER) {
                    generalTitle = this.translate.instant('sanomalehti.header.title');
                } else if (generalType === GeneralType.JOURNAL) {
                    generalTitle = this.translate.instant('aikakausi.header.title');
                } else if (generalType === GeneralType.PRINTING) {
                    generalTitle = this.translate.instant('pienpainate.header.title');
                } else if (generalType === GeneralType.BOOK) {
                    generalTitle = this.translate.instant('kirja.header.title');
                }

                const documentTitle = bindingData.title + ' - ' + generalTitle;
                const pageURL = bindingData.citationInfo.uri + '?page=' + currentPageNumber;

                const resultImagesSrc: string[] = [];
                let modifiedCacheIndex = -1;

                for (let i = 0; i < this.pageClippingsCache.length; i++) {
                    if (this.pageClippingsCache[i] !== undefined) {
                        const tempCanvas = document.createElement('canvas') as HTMLCanvasElement;
                        tempCanvas.width = this.pageClippingsCache[i].width;
                        tempCanvas.height = this.pageClippingsCache[i].height;

                        const tempCtx = tempCanvas.getContext('2d');

                        tempCtx.putImageData(this.pageClippingsCache[i], 0, 0);

                        resultImagesSrc[resultImagesSrc.length] = tempCanvas.toDataURL("image/png");

                        if (this.lastModifiedCacheItem === i) {
                            modifiedCacheIndex = resultImagesSrc.length - 1;
                        }
                    }
                }
                // Moves last modified pages clippings to last index, then images are in same order as they are in preview window.
                if (modifiedCacheIndex !== -1) {
                    resultImagesSrc[resultImagesSrc.length - 1] = resultImagesSrc.splice(modifiedCacheIndex, 1)[0];
                }

                const header = '<div style="width: 100%; font-size: 12px;"><div id="left" style="float: left;">' + bindingData.citationInfo.bindingTitle + ' no ' + bindingData.citationInfo.issue +
                    '</div><div id="right" style="float: right;">' + bindingData.citationInfo.localizedPublishingDate + '</div><div id="center" style="width: 50%;margin: 0 auto;">' + pageURL + '</div></div>';

                const printWindow = window.open('', "_new");
                printWindow.document.open();
                printWindow.document.write(this.printImage(resultImagesSrc, documentTitle, header));
                printWindow.document.close();
            }));
    }

    printImage(sources: string[], title: string, header: string) {
        let imgs = '';
        for (const source of sources) {
            imgs += "<img style='max-width: 100%;' src='" + source + "'/>";
        }

        return "<html><head><style>img {\n" +
            "    page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */\n" +
            "    page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */\n" +
            "    page-break-inside: avoid; /* or 'auto' */\n" +
            "\tdisplay: block;\n" +
            "</style><title>" + title + "</title><script>" + "function step1(){\n" +
            "setTimeout('step2()', 10);}\n" +
            "function step2(){window.print();window.close()}\n" +
            "</script></head><body onload='step1()'>\n" +
            header + imgs +
            "</body></html>";
    }

    clippedRegionChanged(currentPage: IPageInformation, image: HTMLImageElement, clippings: IClipping[]) {
        const rootCtrl = this.cbv;

        if (document.getElementById('printCanvas') !== null) {
            const canvas = document.getElementById('printCanvas') as HTMLCanvasElement;
            const ctx = canvas.getContext('2d');
            let currentHeight = 0;
            const clippingCount = clippings.length;

            if (clippingCount === 0) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
            }

            canvas.width = 0;
            canvas.height = 0;

            let currentPageLastClipping = -1;

            const currentPageNumber = currentPage.info.number;
            for (let i = 0; i < clippingCount; i++) {

                const currentClipping = clippings[i];

                if (currentClipping.pageNumber === currentPageNumber)
                    currentPageLastClipping = i;
            }

            // removePagesLastClipping is called when there is no clippings in current page, but there is still
            // clippings in other pages.
            if (currentPageLastClipping === -1 && clippingCount !== 0)
                this.removePagesLastClipping(canvas, currentPageNumber);
            else if (currentPageLastClipping === -1)
                this.pageClippingsCache[currentPageNumber - 1] = undefined;
            else
                for (let i = 0; i < clippingCount; i++) {

                    const currentClipping = clippings[i];

                    if (currentClipping.pageNumber === currentPageNumber)
                        if (i === currentPageLastClipping)
                            currentHeight = this.getClippedRegion(image, currentClipping, canvas, ctx, currentHeight, true, currentPage.info.scaleFactor, currentPageNumber);
                        else
                            currentHeight = this.getClippedRegion(image, currentClipping, canvas, ctx, currentHeight, false, currentPage.info.scaleFactor, currentPageNumber);
                }

            const containerDiv = document.getElementById("canvasContainer");
            containerDiv.scrollTop = containerDiv.scrollHeight;
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribeAll();
    }
}
