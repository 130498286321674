import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {BindingRestEndpoint, BlockWithId, ComponentNode, ComponentTree, PageArea, PageRequestSource} from "../../../apina-digiweb";
import {BehaviorSubject, combineLatest, Observable, Subject, Subscription} from "rxjs";
import {BINDING_VIEW, ICurrentBindingView, IRegion} from "../../../binding/types";
import {debounceTime, distinctUntilChanged, map, switchMap} from "rxjs/operators";
import {UntypedFormControl} from "@angular/forms";
import {IPredicate} from "../../../utils/predicates";

@Component({
    selector: 'app-component-tree',
    template: `
        <app-sidebar>
            <app-sidebar-header [titleKey]="'component-tree.title'" (closeSidebar)="closeDialog.emit()">
                <a [href]="metsUri$ | async" class="ms-3" target="_blank">METS</a>
            </app-sidebar-header>
            
            <app-sidebar-content>
                <div *ngIf="tree$ | async as tree else loading">
                    <ng-container [ngSwitch]="tree.empty">
                        <div *ngSwitchCase="true">N/A</div>
                        <ng-container *ngSwitchCase="false">
                            <mat-form-field [appearance]="'outline'" [style.width.%]="100">
                                <mat-label>{{'component-tree.filter.placeholder' | translate}}</mat-label>
                                <input type="text" matInput [formControl]="filterCtrl"/>
                            </mat-form-field>

                            <div class="scrollable-content">
                                <div *ngFor="let n of tree; trackBy: trackById">
                                    <app-component-tree-node [node]="n" (clickNode)="clickNode($event)" (hoverNode)="hoverNode($event)"></app-component-tree-node>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>    
            </app-sidebar-content>
        </app-sidebar>
        
        <ng-template #loading>
            <div class="mb-3">
                <app-progress-spinner></app-progress-spinner>
            </div>
        </ng-template>
    `,
    styleUrls: [
        "./component-tree.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComponentTreeComponent implements OnInit, OnDestroy {

    @Input() set bindingId(value: number) {
        const val = +value;
        
        if (!isNaN(val))
            this.bindingId$.next(value);
    }

    @Output() closeDialog = new EventEmitter<void>();
    
    filterCtrl = new UntypedFormControl();
    
    bindingId$ = new Subject<number>();
    private currentPage: number;
    
    metsUri$: Observable<string> = this.currentBindingView.bindingInfo$.pipe(
        map(i => i.baseUrl), 
        distinctUntilChanged(), 
        map(uri => `${uri}/mets.xml?removeTechDetails=true&workingUrls=true`));
    
    tree_ = new BehaviorSubject<TreeIterable>(null);
    tree$ = this.tree_ as Observable<TreeIterable>;
    
    areas$: Observable<BlockWithId[]> = combineLatest([this.bindingId$, this.currentBindingView.loadedPageNumber$])
        .pipe(switchMap(([id, p]) => this.bindingRest.getAltoBlocks(id, p)));

    highlightedAreas = new BehaviorSubject<PageArea[]>([]);
    
    private sub = new Subscription();
    
    constructor(private bindingRest: BindingRestEndpoint,
                @Inject(BINDING_VIEW) private currentBindingView: ICurrentBindingView,
                private cd: ChangeDetectorRef) {
        
        this.sub.add(this.bindingId$.pipe(switchMap((id) => this.bindingRest.getComponentTree(id))).subscribe((nodes) => {
            this.tree_.next(new TreeIterable(nodes));
        }));

        this.sub.add(this.currentBindingView.loadedPageNumber$.subscribe(page => {
            this.currentPage = page;
        }));
        
        this.sub.add(combineLatest([this.highlightedAreas, this.areas$]).subscribe(([hlAreas, cpAreas]) => {

            const visibleAreas: IRegion[] = [];

            for (const a of cpAreas) {
                for (const area of hlAreas) {
                    if (area.id === a.id)
                        visibleAreas.push(a);
                }
            }

            this.currentBindingView.setHighlightedBlocks(visibleAreas);
        }));
        
        this.sub.add(this.filterCtrl.valueChanges.pipe(debounceTime(250)).subscribe((f) => {
            const tree = this.tree_.getValue();
            const filter = f?.toLowerCase();
            
            if (filter == null || filter === '') {
                tree.resetFilter(true, null);
            } else {
                tree.filter(n => {
                    const normalizedLabel = n.label?.toLowerCase();
                    return normalizedLabel?.includes(filter);
                });    
            }
            
            this.cd.markForCheck();
        }));
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
        this.currentBindingView.setHighlightedBlocks(null);
    }

    clickNode(n: UiNode) {
        n.expanded = !n.expanded;
        if (n.firstPage != null && this.currentPage !== n.firstPage) {
            this.currentBindingView.goToPage({
                pageNumber: n.firstPage,
                source: PageRequestSource.COMPONENT_TREE_CLICK
            });
        }
    }
    
    hoverNode(n: UiNode) {
        const selectedAreas: PageArea[] = [];
        this.findAllAreas(n, selectedAreas);
        this.highlightedAreas.next(selectedAreas);
    }

    private findAllAreas(n: UiNode, accumulator: PageArea[]): void {
        if (n.areas?.length > 0) {
            for (const area of n.areas) {
                accumulator.push(area);
            }
        }
        
        if (n.children?.length > 0) {
            for (const child of n.children) {
                this.findAllAreas(child, accumulator);
            }
        }
    }
}

export interface UiNode extends ComponentNode {
    trackById: number;
    depth: number;
    expanded: boolean;
    filterMatch: boolean; // node itself is a match
    descendantFilterMatch: boolean;
    parent: UiNode | null;
    children: UiNode[] | null;
    firstPage: number | null;
    lastPage: number | null;
}

class TreeIterable implements Iterable<UiNode> {
    
    private readonly root: UiNode;
    private nodeCount = 0;
    
    constructor(tree: ComponentTree) {
        if (tree == null || tree.root == null)
            return;
        
        // we decorate the original tree 
        this.root = tree.root as unknown as UiNode;
        this.recursiveInit(this.root, 0);
        this.root.expanded = true;
        console.log("total nodes: " + this.nodeCount);
    }

    *[Symbol.iterator](): Iterator<UiNode> {
        yield* this.traverseVisible(this.root);
    }
    
    private *traverseVisible(node: UiNode): Generator<UiNode> {
        if (!node) return;
        
        if (node.filterMatch || node.descendantFilterMatch) {
            yield node;

            if (node.children?.length > 0 && node.expanded) {
                for (const n of node.children) {
                    yield* this.traverseVisible(n);
                }
            }    
        }
    }

    private *traverseAll(node: UiNode): Generator<UiNode> {
        if (!node) return;
        
        yield node;

        if (node.children?.length > 0) {
            for (const n of node.children) {
                yield* this.traverseAll(n);
            }
        }
    }

    private recursiveInit(node: UiNode, level: number): number[] {
        node.trackById = this.nodeCount++;
        node.depth = level;
        const [min, max] = findBounds(node.areas);
        node.firstPage = min;
        node.lastPage = max;
        node.filterMatch = true;
        node.descendantFilterMatch = false;
        
        if (node.children?.length > 0) {
            for (const n of node.children) {
                n.parent = node;
                const [first, last] = this.recursiveInit(n as unknown as UiNode, level + 1);
                if (first != null && (node.firstPage == null || node.firstPage > first)) node.firstPage = first;
                if (last != null && (node.lastPage == null || node.lastPage < last)) node.lastPage = last;
            }
        }

        return [node.firstPage, node.lastPage];
    }
    
    trackById(index: number, item: UiNode) {
        return item.trackById;
    }

    /**
     * Reset all nodes to given state. Null means don't change the state from current value.
     */
    resetFilter(filterMatch: boolean | null, expanded: boolean | null) {
        for (const n of this.traverseAll(this.root)) {
            n.descendantFilterMatch = false;
            if (filterMatch != null)
                n.filterMatch = filterMatch;
            if (expanded != null)
                n.expanded = expanded;
        }
    }
    
    filter(predicate: IPredicate<UiNode>) {
        this.resetFilter(false, false);
        
        // find actual matches and their parents
        for (const n of this.traverseAll(this.root)) {
            const b = predicate(n);
            if (b) {
                n.filterMatch = true;
                for (const p of this.visitParents(n)) {
                    p.descendantFilterMatch = true; // TODO we could stop if we find a parent that is already a descendantFilterMatch
                    p.expanded = true;
                }
            }
        }
    }
    
    private *visitParents(node: UiNode): Generator<UiNode> {
        const p = node.parent;
        if (p != null) {
            yield p;
            yield* this.visitParents(p);
        }
    } 
    
    public get empty(): boolean {
        return this.root == null;
    }
}

function findBounds(areas: PageArea[] | null): number[] {
    if (areas == null || areas.length === 0) return [null, null];
    let min = Number.POSITIVE_INFINITY;
    let max = Number.NEGATIVE_INFINITY;

    for (const area of areas) {
        const num = area.pageNumber;
        if (num < min) min = num;
        if (num > max) max = num;
    }
    
    return [min, max];
}