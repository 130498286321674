import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, QueryList, ViewChildren} from "@angular/core";
import {ErrorService} from "../error.service";
import {BindingOcrInfoModalComponent} from "./binding-ocr-info-modal.component";
import {BindingRestEndpoint, OcrBlock, OcrVersion} from "../../apina-digiweb";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LoggingService} from "../logging.service";
import {DisplayService} from "../display.service";
import {BINDING_VIEW, ICurrentBindingView} from "../../binding/types";
import {map} from "rxjs/operators";
import {combineLatest, Subscription} from "rxjs";
import {mapError, mapResponse} from "../../utils/observable-utils";


@Component({
    selector: "app-binding-ocr-text",
    template: `
        <app-sidebar>
            <app-sidebar-header titleKey="binding.ocr.title" (closeSidebar)="cbv.toggleOcr()"></app-sidebar-header>
            <app-sidebar-content [padding]="false">
                <div class="ocr-view">
                    <div class="ocr-heading" *ngIf="cbv.bindingViewState$ | async as bvs">
                        <div *ngIf="ocrInfo$ | async as ocrInfo">
                            <i class="cursor-pointer unselectable fa fa-info-circle" (click)="showOcrInfo()"></i>
                            <i class="cursor-pointer unselectable fa fa-columns ms-1" (click)="cbv.toggleOcrBorders()"
                               [style.color]="bvs.ocrBorders ? 'red' : 'inherit'"
                               [ngbTooltip]="'binding.ocr.toggle-ocr-borders' | translate"></i>
                            <i class="unselectable ocr-version fa fa-file-text ms-1 {{ocrInfo.ocrVersion}}" [ngbTooltip]="('binding.ocr.version.' + ocrInfo.ocrVersion) | translate"
                               [ngClass]="{'cursor-pointer': ocrInfo.reOcrAvailable}" (click)="toggleOcrVersion(ocrInfo.reOcrAvailable)"></i>

                            <span class="quality" *ngIf="accuracy$ | async as accuracy"><span translate>binding.ocr.quality</span>: {{accuracy | number}}%</span>
                            <label *ngIf="dataLoaded$ | async"><input type="checkbox" [(ngModel)]="originalLineBreaks"><span class="ms-1" translate>binding.ocr.wrapping</span></label>
                        </div>
                        <div *ngIf="downloadUrls$ | async as urls">
                            <span>{{'binding.ocr.download' | translate}}: </span>
                            <a class="light" [href]="urls.textUrl + oldOcrParam(bvs.oldOcr)" rel="nofollow">TXT</a>
                            <a class="light" [href]="urls.altoUrl + oldOcrParam(bvs.oldOcr)" rel="nofollow">ALTO XML</a>
                        </div>
                    </div>

                    <div class="ocr-text kk-bg-lightgray">
                        <div class="alert alert-warning" *ngIf="notFound$ | async">
                            <p translate>binding.ocr.not-found</p>
                        </div>

                        <div #ocrBlocks *ngFor="let block of ocrBlocks$ | async"
                             class="ocr-block"
                             [innerHTML]="originalLineBreaks ? block.text : block.normalizedText"
                             (mouseenter)="enterBlock(block)"
                        ></div>
                    </div>
                </div>
            </app-sidebar-content>
        </app-sidebar>
    `,
    styleUrls: [
        "./binding-ocr-text.scss"
    ]
})
export class BindingOcrTextComponent implements AfterViewInit, OnDestroy {

    public ocrVersions = OcrVersion;
    
    constructor(private bindingRest: BindingRestEndpoint,
                private errorService: ErrorService,
                private ngbModal: NgbModal,
                private log: LoggingService,
                private el: ElementRef,
                public displayService: DisplayService,
                @Inject(BINDING_VIEW) public cbv: ICurrentBindingView) {
    }

    @ViewChildren("ocrBlocks") blockElements !: QueryList<ElementRef>;

    accuracy$ = this.cbv.currentPageOcrData$.pipe(map(d => mapResponse(d, r => r.accuracy)));
    ocrBlocks$ = this.cbv.ocrBlocks$;
    notFound$ = this.cbv.currentPageOcrData$.pipe(map(d => mapError(d, _ => true, false)));
    dataLoaded$ = this.cbv.currentPageOcrData$.pipe(map(d => d !== null));
    ocrInfo$ = this.cbv.ocrInfo$;
    downloadUrls$ = combineLatest([this.cbv.bindingInfo$, this.cbv.loadedPageNumber$]).pipe(map(([data, page]) => {
        return {
            textUrl: `${data.baseUrl}/page-${page}.txt`,
            altoUrl: `${data.baseUrl}/page-${page}.xml`,
            metsUrl: `${data.baseUrl}/mets.xml?removeTechDetails=true&workingUrls=true`
        };
    }));

    /**
     * The current block is stored in the root controller, but we keep a separate
     * variable noting the last block that was entered by us. That way, when we
     * get a notification change on highlighted-block, we'll now if it was actually
     * changed by us (enteredBlock == currentBinding.highlightedBlock) or if it
     * as changed by someone else. If the block was changed by someone else, e'll
     * scroll our view to show the block and then set enteredBlock.
     */
    enteredBlock: OcrBlock;
    originalLineBreaks = false;
    private _subscription: Subscription;

    ngAfterViewInit(): void {
        this._subscription = combineLatest([this.cbv.highlightedBlock$, this.ocrBlocks$])
            .subscribe(([block, blocks]) => {

                if (block != null && block !== this.enteredBlock) {
                    this.enteredBlock = block;

                    // Scroll the selected element into view
                    const index = blocks.indexOf(block);

                    const blockElem = this.blockElements.find((item, i) => i === index);

                    blockElem.nativeElement.scrollIntoView({behavior: "smooth"});
                }
            });
    }

    ngOnDestroy(): void {
        if (this._subscription != null) {
            this._subscription.unsubscribe();
        }
    }

    showOcrInfo() {
        this.ngbModal.open(BindingOcrInfoModalComponent);
    }

    enterBlock(block: OcrBlock) {
        this.enteredBlock = block;
        this.cbv.setHighlightedBlock(block);
        this.cbv.panToRegion(block);
    }

    oldOcrParam(oldOcr: boolean) {
        return oldOcr ? '?oldOcr=true' : '';
    }

    toggleOcrVersion(reOcrAvailable: boolean) {
        if (reOcrAvailable) 
            this.cbv.toggleOldOcr();
    }
}
