import {Component} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {NgbActiveModal, NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";
import {AddUsagePollDataDTO, StudyDuration, StudyType, TermsConfirmationSubmitDTO, UserEndpoint} from "../../apina-digiweb";
import {SettingsService} from "../settings.service";
import {currentDate, formatEuropeanDate} from "../../utils/date";

export enum ConfirmationResult { CONFIRMED, SKIPPED }

class TermsTab {
    public viewed = false;
    public templateId: string;

    constructor(public agreementId: number, public html: string) {
        this.templateId = "agreement-terms-" + this.agreementId;
    }
}

class StudyTypeOption {
    public id: string;
    public selected = false;

    constructor(public value: StudyType) {
        this.id = "study-type-" + value;
    }
}

class StudyDurationOption {
    public id: string;

    constructor(public value: StudyDuration) {
        this.id = "study-duration-" + value;
    }
}

function hasText(value: string | null) {
    return value != null && value.trim().length > 0;
}

@Component({
    template: `
        <div class="modal-header">
            <header class="modal-title" translate>haka.terms-confirmation.title</header>
        </div>

        <div class="modal-body">
            <p *ngIf="hakaHomeOrganization != null" [innerHTML]="'haka.terms-confirmation.part1' | translate:homeOrg"></p>

            <div ngbNav #nav="ngbNav" (navChange)="beforeChangeTab($event)">
                <div ngbNavItem *ngFor="let tab of terms" [id]="tab.templateId">
                    <a ngbNavLink>{{'agreement.agreement-id.prefix' | translate}} #{{tab.agreementId}}
                        <span *ngIf="!tab.viewed" class="important-indicator"><i class="fa fa-exclamation-circle"></i></span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="terms-content">
                            <p *ngIf="legalDeposit" translate>agreement.legal-deposit.info</p>
                            <div class="kk-markdown" [innerHTML]="tab.html"></div>
                        </div>
                    </ng-template>
                </div>
                <div [ngbNavOutlet]="nav"></div>
            </div>

            <p [innerHTML]="'haka.terms-confirmation.part2' | translate"></p>

            <form *ngIf="!requirePollData" novalidate name="form" class="form-horizontal" (ngSubmit)="confirm()">

                <div class="form-group row">
                    <label class="col-md-4 col-form-label">{{timestamp}} {{username}}</label>
                    <div class="col-md-4">
                        <button class="btn btn-kk-blue" translate>haka.terms-confirmation.accept</button>
                    </div>
                    <div class="col-md-4 text-right">
                        <button type="button" class="btn btn-kk-gray" (click)="skip()" translate>haka.terms-confirmation.skip</button>
                    </div>
                </div>
            </form>

            <p *ngIf="requirePollData" [innerHTML]="'haka.terms-confirmation.poll' | translate"></p>

            <form *ngIf="showPoll" novalidate name="form" class="form-horizontal" #form="ngForm" (ngSubmit)="confirm()">
                <div class="form-group row">
                    <label class="col-md-4" translate>haka.terms-confirmation.study-type</label>
                    <div class="col-md-8">
                        <div *ngFor="let type of types" class="form-check form-check">
                            <input class="form-check-input" type="checkbox" name="study-types" [id]="type.id"
                                   [(ngModel)]="type.selected" (change)="updateStudyTypes()">
                            <label class="form-check-label" [for]="type.id" translate>haka.terms-confirmation.study-types.{{type.value}}</label>
                        </div>

                        <input type="text" class="form-control" [required]="displayCustomStudyType"
                               name="customStudyType" [hidden]="!displayCustomStudyType"
                               [placeholder]="'haka.terms-confirmation.custom-study-type.placeholder' | translate"
                               [(ngModel)]="pollData.customStudyType"
                               maxlength="1000"/>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" translate>haka.terms-confirmation.research-topic</label>
                    <div class="col-md-8">
                        <div class="form-group">
                            <input type="text" class="form-control"
                                   [placeholder]="'haka.terms-confirmation.research-topic.option1' | translate"
                                   name="researchTopicOpt1" [(ngModel)]="pollData.researchTopicOpt1"
                                   (change)="updateResearchTopics()" maxlength="4000"/>
                        </div>

                        <div class="form-group">
                            <input type="text" class="form-control"
                                   [placeholder]="'haka.terms-confirmation.research-topic.option2' | translate"
                                   name="researchTopicOpt2" [(ngModel)]="pollData.researchTopicOpt2"
                                   (change)="updateResearchTopics()" maxlength="4000"/>
                        </div>

                        <div class="form-group">
                            <input type="text" class="form-control"
                                   [placeholder]="'haka.terms-confirmation.research-topic.option3' | translate"
                                   name="researchTopicOpt3" [(ngModel)]="pollData.researchTopicOpt3"
                                   (change)="updateResearchTopics()" maxlength="4000"/>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" translate>haka.terms-confirmation.research-title</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" required
                               name="researchTitle" [(ngModel)]="pollData.researchTitle" maxlength="4000"/>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" translate>haka.terms-confirmation.supervisor</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control"
                               name="supervisor" [(ngModel)]="pollData.supervisor" maxlength="4000"/>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-12" translate>haka.terms-confirmation.duration-estimate</label>
                    <div class="col-md-8 offset-4">

                        <div *ngFor="let duration of durations" class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="study-durations" [id]="duration.id"
                                   [value]="duration.value"
                                   [(ngModel)]="pollData.estimatedDuration" required>
                            <label class="form-check-label" [for]="duration.id" translate>haka.terms-confirmation.duration-estimates.{{duration.value}}</label>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4 col-form-label" translate>haka.terms-confirmation.usage-purpose</label>
                    <div class="col-md-8">
                        <textarea class="form-control" [(ngModel)]="pollData.usagePurpose" name="usagePurpose" required
                                  maxlength="4000"></textarea>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4 col-form-label">{{timestamp}} {{username}}</label>
                    <div class="col-md-4">
                        <button class="btn btn-kk-blue" [disabled]="form.invalid || !customValid" translate>
                            haka.terms-confirmation.accept
                        </button>
                    </div>
                    <div class="col-md-4 text-right">
                        <button class="btn btn-kk-gray" (click)="skip()" translate>
                            haka.terms-confirmation.skip
                        </button>
                    </div>
                </div>
            </form>
        </div>
    `,
    styleUrls: [
        "./terms-confirmation-modal.scss"
    ]
})
export class TermsConfirmationModalComponent {
    timestamp = formatEuropeanDate(currentDate());
    username: string;
    hakaHomeOrganization: string;
    terms: TermsTab[];
    legalDeposit: boolean;

    types: StudyTypeOption[] = [
        new StudyTypeOption(StudyType.KURSSIN_PAATTOTYO),
        new StudyTypeOption(StudyType.PROSEMINAARITYO),
        new StudyTypeOption(StudyType.KANDIDAATINTUTKIELMA),
        new StudyTypeOption(StudyType.PROGRADU_DIPLOMITYO),
        new StudyTypeOption(StudyType.LISENSIAATINTUTKIELMA),
        new StudyTypeOption(StudyType.VAITOSKIRJA),
        new StudyTypeOption(StudyType.TIETEELLINEN_ARTIKKELI),
        new StudyTypeOption(StudyType.TIETEELLINEN_MONOGRAFIA),
        new StudyTypeOption(StudyType.OHJAAJA),
        new StudyTypeOption(StudyType.MUU)
    ];

    displayCustomStudyType = false;

    durations: StudyDurationOption[] = [
        new StudyDurationOption(StudyDuration.BELOW_3_MONTHS),
        new StudyDurationOption(StudyDuration.BETWEEN_3_TO_6_MONTHS),
        new StudyDurationOption(StudyDuration.BETWEEN_6_TO_9_MONTHS),
        new StudyDurationOption(StudyDuration.BETWEEN_9_TO_12_MONTHS),
        new StudyDurationOption(StudyDuration.OVER_12_MONTHS),
    ];

    requirePollData: boolean;

    get showPoll() {
        return this.requirePollData && this.pollData != null;
    }

    get homeOrg(): any {
        return {
            homeOrganization: this.hakaHomeOrganization || '...'
        };
    }

    private model: TermsConfirmationSubmitDTO;
    pollData: AddUsagePollDataDTO;

    constructor(private settingsService: SettingsService,
                private ngbActiveModal: NgbActiveModal,
                private userEndpoint: UserEndpoint,
                private translateService: TranslateService) {

        this.model = {agreementIds: [], pollData: undefined, pollDataV2: undefined};

        userEndpoint.getTerms().subscribe(response => {
            this.requirePollData = response.requirePollData;
            if (this.requirePollData) {
                this.pollData = {
                    customStudyType: undefined,
                    estimatedDuration: undefined,
                    researchTitle: "",
                    researchTopicOpt1: undefined,
                    researchTopicOpt2: undefined,
                    researchTopicOpt3: undefined,
                    studyTypes: [],
                    supervisor: undefined,
                    usagePurpose: ""
                };
                this.model.pollData = this.pollData;
            }
            this.username = response.userName;
            this.hakaHomeOrganization = response.hakaHomeOrganization;
            this.terms = response.terms.map(t => new TermsTab(t.agreementId, t.terms));
            this.terms[0].viewed = true; // hack: set first tab as viewed
            this.model.agreementIds = response.terms.map(t => t.agreementId);
        });

        this.legalDeposit = settingsService.commonOptions.legalDepositWorkstation;
    }

    validation = {
        studyTypesValid: false,
        researchTopicValid: false
    };

    get customValid(): boolean {
        return this.validation.researchTopicValid && this.validation.studyTypesValid && this.allTermsViewed;
    }

    updateStudyTypes() {
        this.pollData.studyTypes = this.types.filter(t => t.selected).map(t => t.value);
        this.displayCustomStudyType = this.pollData.studyTypes.includes(StudyType.MUU);
        if (!this.displayCustomStudyType)
            this.pollData.customStudyType = null;

        this.validation.studyTypesValid = this.pollData.studyTypes.length > 0;
    }

    updateResearchTopics() {
        this.validation.researchTopicValid =
            hasText(this.pollData.researchTopicOpt1) ||
            hasText(this.pollData.researchTopicOpt2) ||
            hasText(this.pollData.researchTopicOpt3) ;
    }

    confirm() {
        this.userEndpoint.confirmTerms(this.model).subscribe(() => {
            console.log("confirmed");
            this.ngbActiveModal.close(ConfirmationResult.CONFIRMED);
        }, () => {
            console.log("confirmation failed");
        });
    }

    skip() {
        this.ngbActiveModal.close(ConfirmationResult.SKIPPED);
    }

    beforeChangeTab($event: NgbNavChangeEvent) {
        for (const t of this.terms) {
            if (t.templateId === $event.nextId)
                t.viewed = true;
        }
    }

    get allTermsViewed() {
        return this.terms.every(t => t.viewed);
    }
}
