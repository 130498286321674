import {Component, OnInit} from "@angular/core";
import * as XLSX from "xlsx";
import {ArticleRestEndpoint, UserArticlesImport} from "../../apina-digiweb";
import {UntypedFormControl} from "@angular/forms";

@Component({
    selector: 'app-import-user-articles',
    template: `
        <div id="import-container" style="display: flex">
            <label id="import-label" for="import" class="btn btn-kk-blue">{{'account.settings.import-articles' | translate}} <app-progress-spinner *ngIf="loading" style="margin-left: 7px;" size="XX-SMALL"></app-progress-spinner></label>
            <input accept=".xlsx" [formControl]="userArticles" type="file" (change)="importUserArticles($event)" style="visibility:hidden;height: 0;" id="import"/>
            <p class="info-message">{{'account.settings.import-info' | translate}}</p><p></p>
            <p class="info-message error" *ngIf="error">{{'account.settings.import-error' | translate}}</p>
            <p class="info-message success" *ngIf="success">{{'account.settings.import-success' | translate}} {{resultCount}}</p>
            <p *ngIf="failedImports.length > 0">{{'account.settings.import-failed' | translate}} {{failedImports}}</p>
        </div>
    `,
    styles: [
        "#import-label { display: flex; flex-direction: row;justify-content: center;align-items: center; }" +
        "#import-container { flex-direction: column;justify-content: center;align-items: flex-start; }" +
        ".info-message { margin:0 }"
    ]
})
export class UserArticlesImportComponent implements OnInit {
    userArticles = new UntypedFormControl();
    wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
    fileName = 'SheetJS.xlsx';
    loading = false;
    error = false;
    success = false;
    resultCount = "";
    failedImports: number[] = [];

    constructor(
        private readonly articleRestEndpoint: ArticleRestEndpoint) {
    }

    ngOnInit() {
        this.loading = false;
        this.reset();
    }

    /**
     * Performs import operation of user articles to current user from Digi export excel-file
     * @param evt Event from file-input
     */
    async importUserArticles(evt: any) {
        this.loading = true;
        this.reset();
        this.userArticles.disable();
        const target: DataTransfer = (evt.target);
        const reader: FileReader = new FileReader();
        try {
            const userArticlesImport = await new Promise<UserArticlesImport[]>((resolve, reject) => {
                reader.onload = (e: any) => {
                    const bstr: string = e.target.result;
                    const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

                    const wsname: string = wb.SheetNames[0];
                    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

                    const data = (XLSX.utils.sheet_to_json(ws, {header: 1}));
                    const userArticles = this.excelToUserArticlesImport(data);
                    if(userArticles.length > 0) resolve(userArticles);
                    else reject(null);
                };
                reader.readAsBinaryString(target.files[0]);
            });
            const response = await this.sendUserArticles(userArticlesImport);
            this.resultCount = "" + response.importCount + "/" + response.totalCount;
            this.failedImports = response.failedImports;
            this.success = true;
        } catch (e) {
            console.log('Error:', e);
            this.error = true;
        }
        this.loading = false;
        this.userArticles.setValue("");
        this.userArticles.enable();
    }

    /**
     * Converts excel document to UserArticlesImport-class and performs basic validation on excel document
     * @param excelData Digi user articles export
     */
    excelToUserArticlesImport(excelData: any): UserArticlesImport[] {
        const notesImport: UserArticlesImport[] = [];
        for(let i = 1; i < excelData.length; i++) {
            const dataRow = excelData[i];
            const id = Number(this.getUserArticleId(dataRow[4]));
            if(!id) return [];
            const note: UserArticlesImport = {
                articleId: id,
                notes: dataRow[9]
            };
            notesImport.push(note)
        }
        return notesImport;
    }

    getUserArticleId(userArticleUrl: string) {
        return /[^/]*$/.exec(userArticleUrl)[0];
    }

    async sendUserArticles(userArticlesImport: UserArticlesImport[]) {
        return await this.articleRestEndpoint.importUserArticles(userArticlesImport).toPromise();
    }

    reset() {
        this.success = false;
        this.error = false;
        this.resultCount = "";
        this.failedImports = [];
    }
}