import {Component} from "@angular/core";
import {SettingsService} from "../settings.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {NavigationService} from "../navigation.service";

@Component({
    template: `
        <div class="modal-header">
            <h4 class="modal-title" translate>account.login.title</h4>
            <button type="button" class="close" (click)="activeModal.close()" aria-hidden="true">&times;</button>
        </div>
        <div class="modal-body">
            <p style="font-size: 1.0rem;" [innerHTML]="'account.login.terms' | translate"></p>

            <div class="login-dialog external-resource row">
                <form ngNoForm action="/signin/google" method="POST" class="col-6">
                    <input type="hidden" name="returnTo" value="{{currentUrl}}"/>
                    <button class="btn btn-kk-light btn-block btn-kk-border"  type="submit">
                        <img src="/images/logos/google.png" height="14px" style="top: -0.09rem; position: relative;" alt="Google"/> Google
                    </button>
                </form>
                
                <form ngNoForm action="/signin/twitter" method="POST" class="col-6">
                    <input type="hidden" name="returnTo" value="{{currentUrl}}"/>
                    <button class="btn btn-kk-light btn-block btn-kk-border" type="submit"><i class="fa fa-twitter"></i> Twitter</button>
                </form>

                <form ngNoForm action="/saml/login" method="GET" class="col-6" *ngIf="hakaEnabled || true">
                    <button class="btn btn-kk-light haka btn-block btn-kk-border" type="submit"><img src="/images/haka_vaaka.svg" height="31px" width="115px"  alt="Haka"/></button>
                </form>
                
                <div class="col-6">
                    <button class="btn btn-kk-light btn-block btn-kk-border" (click)="digiwebLogin = true" [disabled]="!localLoginEnabled || digiwebLogin">Digiweb</button>
                </div>
                
                
                <!--<form ngNoForm action="/signin/facebook" method="POST" class="col-6">
                    <button readonly class="btn btn-kk-light btn-block" style="background-color:darkgray;" type="submit"><i class="fa fa-facebook"></i> Facebook</button>
                    <input type="hidden" name="returnTo" value="{{currentUrl}}"/>
                </form>-->
                <hr>
                <div class="col-6" [innerHTML]="'feature.notavailable' | translate">
                </div>
                
                <form ngNoForm action="/signin/facebook" method="POST" class="col-6">
                    <div class="btn btn-kk-light btn-block" style="background-color:darkgray; pointer-events:none" type="submit">
                        <i class="fa fa-facebook"></i> Facebook</div>
                    <!--<input type="hidden" name="returnTo" value="{{currentUrl}}"/>-->
                </form>
                
                
            </div>

            <div *ngIf="showDigiwebLogin()">
                <hr>

                <form ngNoForm action="/j_spring_security_check" method="POST" class="form-horizontal offset-md-2">
                    <input type="hidden" name="returnTo" value="{{currentUrl}}"/>

                    <div class="form-group row">
                        <label class="col-form-label col-md-3" for="j_username" translate>account.login</label>

                        <div class="col-md-6">
                            <input type="text" id="j_username" name="j_username" autofocus class="form-control"
                                   autocorrect="off" autocapitalize="off">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-form-label col-md-3" for="j_password" translate>account.password</label>

                        <div class="col-md-6">
                            <input type="password" name="j_password" id="j_password" class="form-control">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="offset-md-3 col-md-9">
                            <button type="submit" class="btn btn-kk-blue" translate>account.login.action</button>

                            <a href="/account/register" class="btn btn-outline-secondary" *ngIf="localRegistrationEnabled" translate>account.create-account</a>
                        </div>
                    </div>
                </form>

                <hr>

                <div class="forgotten-links">
                    <a href="/account/request-password-reset" translate>account.password-reset-link</a>
                    <a href="/account/retrieve-username" translate>account.username-retrieval-link</a>
                </div>
            </div>
        </div>

    `,
    styleUrls: [
        "./login-dialog.scss"
    ]
})
export class LoginDialogComponent {
    digiwebLogin = false;

    constructor(private settingsService: SettingsService,
                private navigationService: NavigationService,
                public activeModal: NgbActiveModal) {
    }

    showDigiwebLogin(): boolean {
        return this.digiwebLogin || !this.settingsService.commonOptions.includeExternalResources;
    }

    get localRegistrationEnabled(): boolean {
        return this.settingsService.commonOptions.localRegistrationEnabled;
    }

    get currentUrl() {
        return this.navigationService.currentUrl;
    }

    get hakaEnabled() {
        return this.settingsService.commonOptions.hakaEnabled;
    }

    get localLoginEnabled() {
        return this.settingsService.commonOptions.localLoginEnabled;
    }
}
