import {Component, Input} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    template: `
        <div class="modal-header">
            <h4 class="modal-title">{{title}}</h4>
            <button type="button" class="close" (click)="activeModal.dismiss('cross-click')" aria-hidden="true">&times;</button>
        </div>
        <div class="modal-body">
            <p [innerHTML]="message"></p>
            
            <p *ngIf="suggestPageRefresh">
                {{'error.server-error.suggest-refresh1' | translate}} <a href="" (click)="reloadPage($event)">{{'error.server-error.suggest-refresh2' | translate}}</a>. {{'error.server-error.suggest-refresh3' | translate}}
            </p>
        </div>
    `
})
export class ErrorPopupComponent {
    @Input() title: string;
    @Input() message: string;
    @Input() suggestPageRefresh: boolean;

    constructor(public activeModal: NgbActiveModal) {}

    reloadPage($event: Event) {
        $event.preventDefault();
        location.reload();
    }
}
