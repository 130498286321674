import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {BindingPreview, SerialPublicationRestEndpoint, TitleCalendar} from "../../../apina-digiweb";
import * as _ from "lodash";
import {TranslateService} from "@ngx-translate/core";
import {TimeTranslationService} from "../../../translations/time-translations";
import {DateTimeFormatter, LocalDate} from "@js-joda/core";

interface MonthlyThumbnailMap {
    [key: number]: BindingPreview[];
}

@Component({
    selector: 'app-thumbs-by-month',
    template: `        
        <app-progress-spinner [hidden]="!loading"></app-progress-spinner>

        <div class="alert alert-danger" *ngIf="bindingThumbnails == null && monthlyThumbnails == null && !loading">
            <div [innerHTML]="'error.not-authorized-or-not-found' | translate"> </div>
        </div>

        <!-- <div *ngIf="(getBindingThumbnailsQuantity() == 0 && getMonthlyThumbnailsQuantity() == 0)">
            {{year}} 
            <div [innerHTML]="'browse-thumb.nothumbs' | translate"></div></div> 
             loops all missing bindings and adds a note of missing bindings 
             (generating 100 empty thumbs does not seem beneficial... ) 
             -->
        <div *ngIf="!loading">
            <div *ngFor="let monthCalendar of calendar.months" class="calendar-month col-12 col-md-8">
                <app-calendar-month-missing [monthCalendar]="monthCalendar"></app-calendar-month-missing>
            </div>
        </div>
        
        <!--<app-missing-info-issn [issn]="issn"></app-missing-info-issn>-->
        
        <div *ngIf="!loading">
            <div *ngIf="yearlyThumbnails?.length > 0" class="row">
                <header translate>browse.bindings-without-accurate-date</header>
                <div *ngFor="let binding of yearlyThumbnails" class="grid-item col-6 col-md-4 col-lg-3" >
                    <a [href]="binding.uri" target="_blank" appStoreSession>
                        <img [src]="binding.thumbnailUri" alt="" appErrorThumb />
                        <p>
                            No {{binding.issue}}
                        </p>
                    </a>
                </div>
            </div>
            <div *ngIf="thumbsByMonth">
                <div infinite-scroll [infiniteScrollDistance]="1" (scrolled)="loadMore()" (appPrefill)="prefill()">
                    <!--suppress AngularInvalidExpressionResultType -->
                    <div *ngFor="let month of monthlyThumbnails; trackBy: trackByIndex; index as monthIndex">
                        <div *ngIf="month != null" class="row">
                            <header>{{monthNames[monthIndex-1]}} {{year}}</header>
                            <div *ngFor="let binding of month" class="grid-item col-6 col-md-4 col-lg-3" >
                                <a [href]="binding.uri" target="_blank" appStoreSession>
                                    <img [src]="binding.thumbnailUri" alt="" appErrorThumb />
                                    <p>
                                        No {{binding.issue}} - {{weekDays[binding.weekDay-1]}} {{formatPublishingMonth(binding.publishingDate)}}
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!thumbsByMonth" class="row">
                <div class="grid-item col-6 col-md-4 col-lg-3" *ngFor="let binding of bindingThumbnails">
                    <a [href]="binding.uri" target="_blank" appStoreSession>
                        <img [src]="binding.thumbnailUri" alt="" appErrorThumb />
                        <p>
                            No {{binding.issue}} - {{formatPublishingMonth(binding.publishingDate)}}
                        </p>
                    </a>
                </div>
            </div>
        </div>
    `,
    styleUrls: [
        "./thumbs-by-month.scss"
    ]
})
export class ThumbsByMonthComponent implements OnChanges {
    @Input() thumbsByMonth: boolean;
    @Input() issn: string;
    @Input() year: number;

    @Input() calendar: TitleCalendar;
    // @Input() monthCalendar: TitleCalendarMonth;


    monthNames: string[];
    weekDays: string[];

    loading = true;
    thumbnailBuffer: MonthlyThumbnailMap = [];
    bindingThumbnails: BindingPreview[] = null;
    yearlyThumbnails: BindingPreview[] = null;
    monthlyThumbnails: MonthlyThumbnailMap = null;

    endOfScrollReached = false;

    public trackByIndex2(index: number) {
        return index;
    }

    constructor(private serialPublicationEndpoint: SerialPublicationRestEndpoint,
                private translateService: TranslateService,
                timeTranslations: TimeTranslationService) {

        this.monthNames = timeTranslations.monthNamesFull;
        this.weekDays = timeTranslations.weekDays;
    }

    private updateThumbs() {
        this.loading = true;

        if (this.year === undefined || isNaN(this.year))
            this.loading = false;
        else
            this.serialPublicationEndpoint.publicationsForYear(this.issn, this.year).subscribe(list => {
                if (!this.thumbsByMonth) {
                    this.bindingThumbnails = list;
                } else {
                    this.yearlyThumbnails = list.filter(t => !isAccurate(t));
                    this.monthlyThumbnails = [];
                    const moreAccurate = list.filter(t => isAccurate(t));
                    this.thumbnailBuffer = _.groupBy(moreAccurate, (bindingThumbnail: BindingPreview) => bindingThumbnail.month);

                    // load the first month
                    this.loadMore();
                }
                this.loading = false;
            });
    }

    private updateCalendar2() {
        this.loading = true;
        this.calendar = null;

        if (this.year === undefined || isNaN(this.year))
            this.loading = false;
        else
            this.serialPublicationEndpoint.calendarForYear(this.issn, this.year).subscribe(calendar => {
                this.calendar = calendar;
                this.loading = false;
            });
    }

    prefill() {
        if (!this.endOfScrollReached) {
            this.endOfScrollReached = !this.loadMore();
        }
    }

    /**
     * Returns true if there could be more data.
     */
    loadMore(): boolean {
        const months = Object.keys(this.thumbnailBuffer);

        if (months.length === 0) {
            return false;
        }

        const nextMonth = parseInt(months[0], 10);
        if (!isNaN(nextMonth)) {
            this.monthlyThumbnails[nextMonth] = this.thumbnailBuffer[nextMonth];
            delete this.thumbnailBuffer[nextMonth];
        }
        return true;
    }

    formatPublishingMonth(date: LocalDate): string {
        // let format = this.translateService.instant(  "js.day-month.format");
        return DateTimeFormatter.ofPattern("dd.MM.yyyy").format(date);
    }

    // trackByFunction<any>
    public trackByIndex(index: number) {
        return index;
    }

    public getBindingThumbnailsQuantity() {
        if (this.bindingThumbnails != null) {
            return this.bindingThumbnails.length;
        } else {
            return 0;
        }

    }

    public getMonthlyThumbnailsQuantity() {

        if (this.monthlyThumbnails != null) {
            return Object.keys(this.monthlyThumbnails).length;
        } else {
            return 0;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.endOfScrollReached = false;
        this.updateThumbs();
        this.updateCalendar2();

    }
}

function isAccurate(bp: BindingPreview): boolean {
     return bp.dateAccuracy === 'MONTH' || bp.dateAccuracy === 'DATE';
}

