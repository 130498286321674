import {ChangeDetectionStrategy, Component, Inject, OnDestroy} from "@angular/core";
import {BINDING_VIEW, BindingViewState, ClippingMode, ICurrentBindingView, IPageInformation, NonUrlState, PageRequest} from "../../binding/types";
import {BehaviorSubject, combineLatest, Observable, Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, map} from "rxjs/operators";
import {BindingInformation, PageRequestSource} from "../../apina-digiweb";
import {DisplayService} from "../display.service";
import {SettingsService} from "../settings.service";
import {AccountService} from "../account/account.service";
import {UntypedFormControl} from "@angular/forms";

const FAVORITE_STORAGE_KEY = "kk-toolbar-favorites";

@Component({
    selector: "app-binding-toolbar",
    template: `
        <div class="toolbar-container" *ngIf="viewData$ | async as vd">
            <div class="toolbar-v2 kk-bg-dark kk-mat-dark-theme">
                
                <button mat-icon-button (click)="previousPage()" [disabled]="vd.paging.current <= vd.paging.min"
                        ngbTooltip="{{'clipping.toolbar.previous-page' | translate}}"
                        attr.aria-label="{{'clipping.toolbar.previous-page' | translate}}"
                        placement="top-left" autoClose>
                    <i class="fa fa-chevron-left fa-lg"></i>
                </button>

                <div class="page-input-wrapper">
                    <input type="text" class="page-input ms-1" autocomplete="off" [min]="vd.paging.min" [max]="vd.paging.max" [formControl]="pageCtrl"
                           attr.aria-label="{{'clipping.toolbar.page-input' | translate}}"/>
                    <span>/ {{vd.paging.max}}</span>
                </div>

                <button mat-icon-button (click)="nextPage()" [disabled]="vd.paging.current >= vd.paging.max"
                        ngbTooltip="{{'clipping.toolbar.next-page' | translate}}"
                        attr.aria-label="{{'clipping.toolbar.next-page' | translate}}"
                        placement="top-left" autoClose>
                    <i class="fa fa-chevron-right fa-lg"></i>
                </button>

                <div class="favorite-actions">
                    <ng-container *ngFor="let action of favoriteActions | async">
                        <button *ngIf="isVisible(action, vd)"
                                mat-icon-button
                                ngbTooltip="{{action.tooltipKey | translate}}"
                                [ngClass]="{active: action.active && action.active(vd)}"
                                (click)="action.onClick(vd)"
                                (mouseenter)="action.mouseEnter && action.mouseEnter(vd)"
                                (mouseleave)="action.mouseLeave && action.mouseLeave(vd)"
                                (dblclick)="$event.preventDefault()"
                                [disabled]="action.disabled && action.disabled(vd)"
                                attr.aria-label="{{action.tooltipKey | translate}}">
                            <i class="fa-lg" [ngClass]="action.iconClass(vd)"></i>
                        </button>
                        <span class="kk-badge" [matBadge]="action.badge && action.badge(vd)" [matBadgePosition]="'below'" matBadgeColor="accent"></span>
                    </ng-container>
                </div>

                <button mat-icon-button [matMenuTriggerFor]="menu" ngbTooltip="{{'clipping.toolbar.menu' | translate}}"
                        attr.aria-label="{{'clipping.toolbar.menu' | translate}}">
                    <i class="fa fa-lg fa-bars"></i>
                </button>

                <mat-menu #menu="matMenu" class="toolbar-v2-menu">

                    <div *ngFor="let action of menuActions | async" class="menu-row-wrapper">
                        <ng-container *ngIf="isVisible(action, vd)">
                            <button mat-menu-item
                                    [ngClass]="{active: action.active && action.active(vd)}"
                                    (click)="action.onClick(vd)">
                                <i class="fa-lg" [ngClass]="action.iconClass(vd)"></i>
                                <span [matBadge]="action.badge && action.badge(vd)" matBadgeOverlap="false">{{action.tooltipKey | translate}}</span>
                            </button>
                            <button mat-icon-button (click)="toggleFavorite(action, $event)" 
                                    [color]="action.favorite ? 'primary' : 'accent'"
                                    [attr.aria-label]="action.favorite ? ('common.action.unpin' | translate) : ('common.action.pin' | translate)"
                                    [attr.title]="action.favorite ? ('common.action.unpin' | translate) : ('common.action.pin' | translate)">
                                <i class="fa fa-thumbtack" aria-hidden="true"></i>
                            </button>
                        </ng-container>
                    </div>

                    <a href="{{vd.data.bi.downloadPdfUrl}}" rel="nofollow">
                        <button mat-menu-item *ngIf="vd.data.bi.downloadable && vd.data.bi.downloadPdfUrl">
                            <i class="fa fa-download fa-lg" [ngClass]="{'red-icon-color': vd.data.bi.downloadPdfSize > 500}"></i>
                            <span class="ms-1">{{'tooltip.download.issue' | translate}} {{vd.data.bi.downloadPdfSize | number: '1.0-2'}} MB</span>
                        </button>
                    </a>
                </mat-menu>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./binding-toolbar.scss"
    ]
})
export class BindingToolbarComponent implements OnDestroy {

    readonly data$: Observable<Data>;
    readonly paging$: Observable<Paging>;
    readonly viewData$: Observable<ViewData>;
    
    readonly refreshActions = new BehaviorSubject<void>(null);
    readonly _actions = new BehaviorSubject<ToolbarAction[]>([]);
    readonly favoriteActions: Observable<ToolbarAction[]> = combineLatest([this.refreshActions, this._actions])
        .pipe(map(([r, as]) => as.filter(a => a.favorite)));
    readonly menuActions: Observable<ToolbarAction[]> = combineLatest([this.refreshActions, this._actions])
        .pipe(map(([r, as]) => as.filter(a => !a.defaultAction)));
    
    readonly pageCtrl = new UntypedFormControl();

    private readonly sub = new Subscription();
    
    constructor(public displayService: DisplayService,
                public settingsService: SettingsService,
                public accountService: AccountService,
                @Inject(BINDING_VIEW) public readonly cbv: ICurrentBindingView) {
        
        let toggledArticlesOnHover: boolean;
        
        const actions: ToolbarAction[] = [
            // VIEW
            {
                id: 'zoomin',
                defaultAction: true,
                favorite: true,
                iconClass: () => 'fa-solid fa-search-plus',
                tooltipKey: "clipping.toolbar.zoom-in",
                onClick: () => cbv.zoomIn()
            },
            {
                id: 'zoomout',
                defaultAction: true,
                favorite: true,
                iconClass: () => 'fa-solid fa-search-minus',
                tooltipKey: "clipping.toolbar.zoom-out",
                onClick: () => cbv.zoomOut()
            },
            {
                id: 'fullscreen',
                favorite: false,
                iconClass: () => displayService.maximized ? 'fa-solid fa-compress' : 'fa-solid fa-expand',
                tooltipKey: "clipping.toolbar.fullscreen",
                onClick: () => displayService.toggleMaximized(),
                active: (vd) => displayService.maximized
            },
            {
                id: 'drag',
                favorite: false,
                iconClass: () => 'fa-regular fa-hand',
                tooltipKey: "clipping.toolbar.move",
                onClick: () => cbv.dragMode = !cbv.dragMode,
                active: (vd) => cbv.dragMode
            },
            {
                id: 'fitw',
                favorite: false,
                iconClass: () => 'fa-solid fa-distribute-spacing-horizontal',
                tooltipKey: "clipping.toolbar.fit-width",
                onClick: () => cbv.fitWidth()
            },
            {
                id: 'fith',
                favorite: false,
                iconClass: () => 'fa-solid fa-distribute-spacing-vertical',
                tooltipKey: "clipping.toolbar.fit-height",
                onClick: () => cbv.fitHeight()
            },
            {
                id: 'rot90',
                favorite: false,
                iconClass: () => 'fa-solid fa-rotate-left',
                tooltipKey: "clipping.toolbar.rotate90",
                onClick: () => cbv.rotate90(),
                disabled: (vd) => !this.canRotate(vd.data),
                active: () => cbv.isRotated()
            },
            // META
            {
                id: 'clip',
                defaultAction: true,   
                favorite: true,
                iconClass: () => 'fa-solid fa-cut',
                tooltipKey: "clipping.toolbar.edit",
                onClick: (vd) => {
                    if (this.canClip(vd.data)) {
                        cbv.toggleArticleEdit();
                    }
                },
                mouseEnter: (vd) => {
                    if (!vd.data.state2.displayArticles) {
                        cbv.toggleUserArticles();
                        toggledArticlesOnHover = true;
                    }
                },
                mouseLeave: (vd) => {
                    if (toggledArticlesOnHover) {
                        cbv.toggleUserArticles();
                    }
                    toggledArticlesOnHover = false;
                },
                active: (vd) => vd.data.clipMode === ClippingMode.ARTICLE,
                disabled: (vd) => vd.data.clipMode !== ClippingMode.OFF,
                badge: (vd) => vd.data.pageClippingCount
            },
            {
                id: 'd-clip',
                favorite: false,
                iconClass: () => 'fa-regular fa-memo',
                tooltipKey: "clipping.toolbar.show-articles",
                onClick: () => cbv.toggleUserArticles(),
                disabled: (vd) => vd.data.state.displayOcr || vd.data.clipMode !== ClippingMode.OFF,
                active: (vd) => vd.data.state2.displayArticles,
                badge: (vd) => vd.data.pageClippingCount
            },
            {
                id: 'd-agr',
                defaultAction: true,
                favorite: true,
                iconClass: (vd) => vd.data.bi.restrictedMaterial ? 'fa-solid fa-lock-keyhole yellow-icon-color' : 'fa-solid fa-lock-keyhole-open',
                tooltipKey: "agreement.info.title",
                onClick: (vd) => cbv.toggleAgreements(),
                active: (vd) => vd.data.state2.displayAgreements
            },
            {
                id: 'd-marc',
                favorite: false,
                iconClass: () => 'fa-solid fa-info-circle',
                tooltipKey: "clipping.toolbar.marc",
                onClick: () => cbv.toggleMarc(),
                active: (vd) => vd.data.state.displayMarc,
                enabled: () => settingsService.commonOptions.marcOverlayEnabled
            },
            {
                id: 'd-structmap',
                favorite: false,
                iconClass: () => 'fa-solid fa-folder-tree',
                tooltipKey: "component-tree.toolbar.tooltip",
                onClick: () => cbv.toggleComponentTree(),
                active: (vd) => vd.data.state2.displayComponentTree,
            },
            {
                id: 'd-parts',
                favorite: false,
                iconClass: () => 'fa-solid fa-list-ol',
                tooltipKey: "clipping.toolbar.parts",
                onClick: () => {
                    cbv.toggleParts();
                    cbv.toggleToc();
                },
                active: (vd) => vd.data.state2.displayParts,
                enabled: (vd) => vd.data.bi.hasComponentParts
            },
            {
                id: 'd-ocr',
                favorite: true,
                iconClass: () => 'fa-solid fa-font',
                tooltipKey: "clipping.toolbar.ocr",
                onClick: () => cbv.toggleOcr(),
                active: (vd) => vd.data.state.displayOcr,
                enabled: (vd) => cbv.enableOcr,
                disabled: (vd) => vd.data.clipMode !== ClippingMode.OFF
            },
            {
                id: 'd-nerl',
                favorite: false,
                iconClass: () => 'fa-solid fa-globe',
                tooltipKey: "ner.tooltip.LOC",
                onClick: () => cbv.toggleNerLocations(),
                active: (vd) => vd.data.state.displayNerLocations,
                enabled: (vd) => vd.data.page.info.hasNerOccurrences
            },
            {
                id: 'd-nerp',
                favorite: false,
                iconClass: () => 'fa-solid fa-user-circle',
                tooltipKey: "ner.tooltip.PER",
                onClick: () => cbv.toggleNerPersons(),
                active: (vd) => vd.data.state.displayNerPersons,
                enabled: (vd) => vd.data.page.info.hasNerOccurrences
            },
            {
                id: 'search-in',
                favorite: false,
                iconClass: () => 'fa-solid fa-search',
                tooltipKey: "clipping.toolbar.search-inside",
                onClick: () => cbv.toggleSearch(),
                active: (vd) => vd.data.state2.displaySearch
            },
            // TAKEAWAY
            {
                id: 'd-cit',
                favorite: false,
                iconClass: () => 'fa-solid fa-quote-right',
                tooltipKey: "citation.toolbar.toggle",
                onClick: () => cbv.toggleCitation(),
                active: (vd) => vd.data.state2.displayCitation
            },
            {
                id: 'print',
                favorite: false,
                iconClass: () => 'fa-solid fa-print',
                tooltipKey: "clipping.toolbar.print",
                onClick: () => cbv.togglePrintEdit(),
                active: (vd) => vd.data.clipMode === ClippingMode.PRINT,
                disabled: (vd) => !this.canPrint(vd.data),
                enabled: (vd) => !displayService.isMobile && vd.data.bi.downloadable
            },
            // ADMIN
            {
                id: 'probs',
                favorite: false,
                iconClass: () => 'fa-regular fa-frown',
                tooltipKey: "clipping.toolbar.mark-problems",
                onClick: () => cbv.toggleBindingProblemDialog(),
                enabled: (vd) => vd.data.bi.canMarkProblems,
                active: (vd) => vd.data.state2.displayBindingProblemDialog
            },
            {
                id: 'hide',
                favorite: false,
                iconClass: () => 'fa-solid fa-eye-slash',
                tooltipKey: "tooltip.binding.hide",
                onClick: () => cbv.toggleHideBindingDialog(),
                enabled: (vd) => vd.data.bi.canHideBindings,
                active: (vd) => vd.data.state2.displayHideBindingDialog
            },
            {
                id: 'redact',
                favorite: false,
                iconClass: () => 'fa-solid fa-eraser',
                tooltipKey: "tooltip.binding.redact",
                onClick: () => cbv.toggleRedactEdit(),
                enabled: (vd) => vd.data.bi.canRedact,
                disabled: (vd) => !this.canRedact(vd.data),
                active: (vd) => vd.data.clipMode === ClippingMode.REDACTION
            },
        ];
        
        this.loadFavorites(actions);
        
        this._actions.next(actions);
        
        this.data$ = combineLatest([
            cbv.bindingInfo$,
            cbv.bindingViewState$,
            cbv.loadedPage$,
            cbv.clippingMode$,
            cbv.userArticleClippingsOnCurrentPage$,
            cbv.nonUrlState$
        ]).pipe(map(([bi, state, page, m, uac, state2]) => {
            return {
                bi,
                state,
                page,
                articleClippingEnabled: this.cbv.allowClipping && m === ClippingMode.OFF,
                clipMode: m,
                pageHasClippings: uac > 0,
                pageClippingCount: uac > 0 ? '' + uac : null,
                state2
            };
        }));

        const currentPage = cbv.loadedPageNumber$.pipe(distinctUntilChanged());

        this.paging$ = combineLatest([
            cbv.pagesCount$,
            currentPage
        ]).pipe(map(([pc, current]) => {
            return {
                current,
                min: 1,
                max: pc
            }
        }));

        this.viewData$ = combineLatest([this.data$, this.paging$]).pipe(map(([d, p]) => {
            return {
                data: d,
                paging: p
            }
        }));

        this.sub.add(currentPage.subscribe((p) => {
            this.pageCtrl.setValue(p);
        }));

        this.sub.add(this.pageCtrl.valueChanges.pipe(
            distinctUntilChanged(),
            debounceTime(250),
            filter(input => input?.length > 0)
        ).subscribe((p) => {
            this.cbv.goToPage(new PageRequest(p, PageRequestSource.PAGER));
        }));
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    isVisible(action: ToolbarAction, vd: ViewData): boolean {
        if (action.enabled === undefined)
            return true;
        return typeof action.enabled === 'function' && action.enabled(vd);
    }
    
    toggleFavorite(action: ToolbarAction, event: Event) {
        event.stopPropagation(); // prevent closing of menu, so it's easier to select multiple
        
        action.favorite = !action.favorite;
        this.storeFavorites(this._actions.getValue());
        this.refreshActions.next();
    }
    
    canRotate(data: Data): boolean {
        return data.clipMode === ClippingMode.OFF;
    }

    canClip(data: Data): boolean {
        return data.articleClippingEnabled && !this.cbv.isRotated();
    }

    canRedact(data: Data): boolean {
        return data.clipMode === ClippingMode.OFF && !this.cbv.isRotated();
    }

    canPrint(data: Data): boolean {
        return data.clipMode === ClippingMode.OFF && !this.cbv.isRotated();
    }

    previousPage() {
        this.cbv.previousPage();
    }

    nextPage() {
        this.cbv.nextPage();
    }

    private loadFavorites(actions: ToolbarAction[]): void {
        try {
            if (localStorage) {
                const serialized = localStorage.getItem(FAVORITE_STORAGE_KEY);
                if (serialized) {
                    const favorites: string[] = JSON.parse(serialized);
                    for (const action of actions) {
                        for (const fav of favorites) {
                            if (action.id === fav) {
                                action.favorite = true;
                            }
                        }
                    }
                }
            }
        } catch (e) {
            console.log("error while loading toolbar favorites", e);
        }
    }
    
    private storeFavorites(actions: ToolbarAction[]) {
        try {
            if (localStorage) {
                const favoriteIds = actions.filter(a => a.favorite && !a.defaultAction).map(a => a.id);
                localStorage.setItem(FAVORITE_STORAGE_KEY, JSON.stringify(favoriteIds));    
            }
        } catch (e) {
            console.log("error while storing toolbar favorites", e);
        }
    }
}

interface Data {
    bi: BindingInformation;
    state: BindingViewState;
    state2: NonUrlState;
    page: IPageInformation;
    articleClippingEnabled: boolean;
    clipMode: ClippingMode;
    pageHasClippings: boolean;
    pageClippingCount: string | null;
}

interface Paging {
    min: number;
    max: number;
    current: number;
}

interface ViewData {
    data: Data;
    paging: Paging;
}

interface ToolbarAction {
    id: string;
    defaultAction?: boolean;
    favorite: boolean;
    iconClass: (vd: ViewData) => string;
    onClick: (vd: ViewData) => void;
    tooltipKey: string;
    active?: (vd: ViewData) => boolean;
    badge?: (vd: ViewData) => string | null | undefined;
    disabled?: (vd: ViewData) => boolean;
    enabled?: (vd: ViewData) => boolean;
    
    // only for toolbar, not menu
    mouseEnter?: (vd: ViewData) => void;
    mouseLeave?: (vd: ViewData) => void;
}