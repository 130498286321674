import {Component} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";
import {AddUsagePollDataDTOv2, EducationPlace, StudyField, StudyProgram, StudyPurpose, StudyRole, TermsConfirmationSubmitDTO, UserEndpoint} from "../../apina-digiweb";
import {SettingsService} from "../settings.service";
import {currentDate, formatEuropeanDate} from "../../utils/date";
import {UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {StepperSelectionEvent} from "@angular/cdk/stepper";
import {MatDialogRef} from "@angular/material/dialog";

export enum ConfirmationResult {CONFIRMED = "CONFIRMED", SKIPPED = "SKIPPED"}

class TermsTab {
    public viewed = false;
    public templateId: string;

    constructor(public agreementId: number, public html: string) {
        this.templateId = "agreement-terms-" + this.agreementId;
    }
}

abstract class LocalizableOption<T> {
    public readonly localizationKey: string;

    protected constructor(public readonly value: T, localizationKeyPrefix: string) {
        this.localizationKey = localizationKeyPrefix + value;
    }

    static getLabelComparator(translate: TranslateService): (a: LocalizableOption<any>, b: LocalizableOption<any>) => number {
        return (a: LocalizableOption<any>, b: LocalizableOption<any>) => {
            const aLoc = translate.instant(a.localizationKey);
            const bLoc = translate.instant(b.localizationKey);
            return aLoc.localeCompare(bLoc);
        };
    }
}

abstract class CheckBoxOption<T> extends LocalizableOption<T> {
    public selected = false;
}

class EducationPlaceOption extends LocalizableOption<EducationPlace> {
    constructor(public readonly value: EducationPlace) {
        super(value, "haka.poll.education-place.");
    }
}

class StudyRoleOption extends CheckBoxOption<StudyRole> {
    constructor(public readonly value: StudyRole) {
        super(value, "haka.poll.study-roles.");
    }
}

class StudyPurposeOption extends CheckBoxOption<StudyPurpose> {
    constructor(public readonly value: StudyPurpose) {
        super(value, "haka.poll.study-purposes.");
    }
}

class StudyFieldOption extends LocalizableOption<StudyField> {
    constructor(public readonly value: StudyField) {
        super(value, "haka.poll.research-field-classifications.");
    }
}

class StudyProgramOption extends LocalizableOption<StudyProgram> {
    constructor(public readonly value: StudyProgram) {
        super(value, "haka.poll.field-of-education.");
    }
}

class ResultEntry {
    public label: string;
    public value: string;

    constructor(label: string, value: string | string[],
                translate: TranslateService,
                localizations?: LocalizableOption<any>[]) {
        this.label = translate.instant('haka.poll.labels.' + label);
        if (Array.isArray(value)) {
            this.value = value.map(v => this.localize(v, localizations, translate)).join(", ");
        } else {
            this.value = this.localize(value, localizations, translate);
        }
    }

    localize<T>(value: string, localizations?: LocalizableOption<any>[], translate?: TranslateService): string {
        if (localizations && translate) {
            for (const localization of localizations) {
                if (localization.value === value)
                    return translate.instant(localization.localizationKey);
            }
        }
        return value as unknown as string;
    }

    toString(): string {
        return `${this.label}: ${this.value || ''}`;
    }
}

function hasText(value: string | null) {
    return value != null && value.trim().length > 0;
}

function setInList<T>(items: T[], item: T, checked: boolean) {
    const i = items.indexOf(item);
    if (checked && i === -1)
        items.push(item);
    else if (!checked && i !== -1)
        items.splice(i, 1);
}

function toList(checkBoxes: {[key: string]: boolean}): string[] {
    const keys: string[] = Object.keys(checkBoxes);
    return keys.filter(a => checkBoxes[a] === true);
}

const requireOneTrue: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    const controls = Object.keys(control.controls);
    return !controls.some(c => control.get(c).value === true) ? {required: true} : null;
};

@Component({
    template: `
        <mat-dialog-content>
            <div mat-dialog-title translate>haka.terms-confirmation2.title</div>

            <mat-vertical-stepper linear *ngIf="!loading; else loadingIndicator" (selectionChange)="onChange($event)">
                <mat-step label="{{'haka.terms-confirmation2.step1' | translate}}" [editable]="!submitted">

                    <p translate>haka.terms-confirmation2.introduction1</p>
                    <p translate>haka.terms-confirmation2.introduction2</p>

                    <div class="btn-row">
                        <button mat-flat-button color="accent" (click)="skip()">{{'haka.terms-confirmation2.buttons.skip' | translate}}</button>
                        <button mat-flat-button color="primary" matStepperNext>{{'haka.terms-confirmation2.buttons.begin' | translate}}</button>
                    </div>
                </mat-step>
                <mat-step #step2 label="{{'haka.terms-confirmation2.step2' | translate}}" [editable]="!submitted"
                          [completed]="step2Viewed && allTermsViewed">
                    <div #nav="ngbNav" ngbNav (navChange)="beforeChangeTab($event)">
                        <div ngbNavItem *ngFor="let tab of terms" [id]="tab.templateId">
                            <a ngbNavLink>{{'agreement.agreement-id.prefix' | translate}} #{{tab.agreementId}}
                                <span *ngIf="!tab.viewed" class="important-indicator"><i class="fa fa-exclamation-circle"></i></span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="terms-content">
                                    <p *ngIf="legalDeposit" translate>agreement.legal-deposit.info</p>
                                    <div class="kk-markdown" [innerHTML]="tab.html"></div>
                                </div>
                            </ng-template>
                        </div>
                        <div [ngbNavOutlet]="nav"></div>
                    </div>

                    <button mat-flat-button color="primary" matStepperNext [disabled]="!allTermsViewed">
                        {{'haka.terms-confirmation2.buttons.continue' | translate}}
                    </button>
                </mat-step>

                <mat-step *ngIf="requirePollData"
                          label="{{'haka.terms-confirmation2.step3' | translate}}"
                          [editable]="!submitted"
                          [completed]="formGroup.valid">

                    <form *ngIf="showPoll" novalidate name="form" class="form-horizontal"
                          [formGroup]="formGroup">

                        <label [ngClass]="{invalid: showInvalid('studyPlace')}"><span translate>haka.poll.labels.education-place</span> *</label>
                        <div class="form-group row">
                            <div class="col-12">
                                <mat-radio-group required formControlName="studyPlace">
                                    <mat-radio-button [value]="eduPlace.value" *ngFor="let eduPlace of educationPlaces"
                                                      color="primary">
                                        {{eduPlace.localizationKey | translate}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>

                        <label [ngClass]="{invalid: showInvalid('studyRoles')}"><span translate>haka.poll.labels.study-roles</span> *</label>
                        <div class="form-group row">
                            <div *ngFor="let role of roles" class="col-md-6" formGroupName="studyRoles">
                                <mat-checkbox [formControlName]="role.value" color="primary">
                                    {{role.localizationKey | translate}}
                                </mat-checkbox>
                            </div>
                            <div class="col-12">
                                <mat-form-field [hidden]="!isCustomRoleRequired">
                                    <input matInput placeholder="{{'haka.poll.placeholders.role' | translate}}" formControlName="customRole"
                                           [required]="isCustomRoleRequired">
                                </mat-form-field>
                            </div>
                        </div>

                        <label [ngClass]="{invalid: showInvalid('studyPurposes')}"><span translate>haka.poll.labels.study-purposes</span> *</label>
                        <div class="form-group row">
                            <div *ngFor="let purpose of purposes" class="col-md-6" formGroupName="studyPurposes">
                                <mat-checkbox [formControlName]="purpose.value" color="primary">
                                    {{purpose.localizationKey | translate}}
                                </mat-checkbox>
                            </div>
                            <div class="col-12">
                                <mat-form-field [hidden]="!isCustomPurposeRequired">
                                    <input matInput placeholder="{{'haka.poll.placeholders.study-purpose' | translate}}" formControlName="customPurpose"
                                           [required]="isCustomPurposeRequired">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">

                                <mat-form-field>
                                    <mat-label translate>haka.poll.labels.research-field-classifications</mat-label>
                                    <mat-select multiple formControlName="studyFields" [required]="!fieldPopulated('studyPrograms')">
                                        <mat-option *ngFor="let field of fields" [value]="field.value">
                                            {{field.localizationKey | translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-md-12">
                                <mat-form-field>
                                    <mat-label translate>haka.poll.labels.field-of-education</mat-label>
                                    <mat-select multiple formControlName="studyPrograms" [required]="!fieldPopulated('studyFields')">
                                        <mat-option *ngFor="let program of programs" [value]="program.value">
                                            {{program.localizationKey | translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <br/>

                        <label translate>haka.poll.labels.description</label>

                        <mat-form-field>
                            <mat-label>{{'haka.poll.placeholders.description' | translate}}</mat-label>
                            <textarea matInput formControlName="description" matTextareaAutosize
                                      maxlength="4000"></textarea>
                        </mat-form-field>

                        <button type="button" mat-flat-button class="disabled" *ngIf="formGroup.invalid" (click)="formGroup.markAllAsTouched()">
                            {{'haka.terms-confirmation2.buttons.continue' | translate}}
                        </button>
                        <button type="button" mat-flat-button color="primary" matStepperNext *ngIf="formGroup.valid">
                            {{'haka.terms-confirmation2.buttons.continue' | translate}}
                        </button>
                    </form>
                </mat-step>

                <mat-step label="{{'haka.terms-confirmation2.step4' | translate}}" [editable]="!submitted">
                    <div *ngIf="requirePollData">
                        <p translate>haka.terms-confirmation2.summary</p>

                        <pre style="white-space: pre-line">
                        {{formattedResult}}
                        </pre>

                        <hr/>
                    </div>

                    <div class="form-group row">
                        <label class="col-12 col-form-label">{{timestamp}} {{username}}</label>
                        <div class="col-12">
                            <button mat-flat-button color="primary" (click)="confirm()"
                                    [disabled]="submitting || submitted">
                                {{'haka.terms-confirmation2.buttons.accept' | translate}}
                            </button>
                            <app-progress-spinner *ngIf="submitting" class="ms-2"></app-progress-spinner>
                        </div>
                    </div>

                    <div>
                        <span translate>haka.terms-confirmation2.download</span>:<br/>

                        <div class="d-flex justify-content-between">
                            <a [href]="downloadUrl">
                                <button type="button" mat-flat-button color="accent" [disabled]="!downloadUrl">
                                    <mat-icon matSuffix>archive</mat-icon>
                                    {{'haka.terms-confirmation2.buttons.download' | translate}}
                                </button>
                            </a>
                            <button mat-flat-button color="accent" (click)="close()"
                                    [disabled]="!submitted">
                                {{'haka.terms-confirmation2.buttons.close' | translate}}
                            </button>
                        </div>
                    </div>

                </mat-step>
            </mat-vertical-stepper>

        </mat-dialog-content>

        <ng-template #loadingIndicator>
            <app-progress-spinner></app-progress-spinner>
        </ng-template>
    `,
    styleUrls: [
        "./terms-confirmation-modal.scss"
    ]
})
export class TermsConfirmationV2ModalComponent {

    formGroup: UntypedFormGroup;

    loading = true;
    timestamp = formatEuropeanDate(currentDate());
    username: string;
    hakaHomeOrganization: string;
    terms: TermsTab[];
    step2Viewed = false;
    legalDeposit: boolean;
    submitting = false;

    get submitted() {
        return !!this.downloadUrl;
    }

    educationPlaces: EducationPlaceOption[] = [
        new EducationPlaceOption(EducationPlace.YO),
        new EducationPlaceOption(EducationPlace.AMK)
    ];

    roles: StudyRoleOption[] = [
        new StudyRoleOption(StudyRole.TUTKIMUKSEN_TEKIJA),
        new StudyRoleOption(StudyRole.KIRJASTOHENKILOSTO),
        new StudyRoleOption(StudyRole.TUTKIMUKSEN_OHJAAJA_TAI_OPETTAJA),
        new StudyRoleOption(StudyRole.MUU)
    ];

    purposes: StudyPurposeOption[] = [
        new StudyPurposeOption(StudyPurpose.KURSSIN_PAATTOTYO),
        new StudyPurposeOption(StudyPurpose.TIETEELLINEN_ARTIKKELI),
        new StudyPurposeOption(StudyPurpose.AMK_PAATTOTYO),
        new StudyPurposeOption(StudyPurpose.TIETEELLINEN_MONOGRAFIA),
        new StudyPurposeOption(StudyPurpose.YAMK_PAATTOTYO),
        new StudyPurposeOption(StudyPurpose.TIEDONHAKU),
        new StudyPurposeOption(StudyPurpose.PROSEMINAARITYO),
        new StudyPurposeOption(StudyPurpose.TUTKIMUSMETODIOPETUS),
        new StudyPurposeOption(StudyPurpose.KANDIDAATINTUTKIELMA),
        new StudyPurposeOption(StudyPurpose.TIEDONHAUN_OPETUS),
        new StudyPurposeOption(StudyPurpose.PRO_GRADU_TAI_DIPLOMITYO),
        new StudyPurposeOption(StudyPurpose.LISENSIAATINTUTKIELMA),
        new StudyPurposeOption(StudyPurpose.VAITOSKIRJA),
        new StudyPurposeOption(StudyPurpose.MUU)
    ];

    fields: StudyFieldOption[] = Object.keys(StudyField).map(e => new StudyFieldOption(e as StudyField))
        .sort(LocalizableOption.getLabelComparator(this.translateService));

    programs: StudyProgramOption[] = Object.keys(StudyProgram).map(e => new StudyProgramOption(e as StudyProgram))
        .sort(LocalizableOption.getLabelComparator(this.translateService));

    requirePollData: boolean;
    pollData: AddUsagePollDataDTOv2;

    private model: TermsConfirmationSubmitDTO;

    downloadUrl: string;


    constructor(private settingsService: SettingsService,
                private userEndpoint: UserEndpoint,
                private httpClient: HttpClient,
                private translateService: TranslateService,
                private dialogRef: MatDialogRef<TermsConfirmationV2ModalComponent>) {

        const purposes = new UntypedFormGroup({}, requireOneTrue);

        for (const purpose of this.purposes) {
            purposes.addControl(purpose.value, new UntypedFormControl(false));
        }

        const roles = new UntypedFormGroup({}, requireOneTrue);

        for (const role of this.roles) {
            roles.addControl(role.value, new UntypedFormControl(false));
        }

        this.formGroup = new UntypedFormGroup({
            studyPlace: new UntypedFormControl(''),
            studyRoles: roles,
            customRole: new UntypedFormControl(''),
            studyPurposes: purposes,
            customPurpose: new UntypedFormControl(''),
            studyFields: new UntypedFormControl([]),
            studyPrograms: new UntypedFormControl([]),
            description: new UntypedFormControl('')
        });

        this.pollData = {
            customStudyPurpose: undefined,
            customStudyRole: undefined,
            educationPlace: undefined,
            studyFields: [],
            studyPrograms: [],
            studyPurposes: [],
            studyRoles: [],
            usagePurpose: undefined
        };

        this.formGroup.valueChanges.subscribe(formRawValue => {
            this.serializeForm(formRawValue);
        });

        this.model = {agreementIds: [], pollData: undefined, pollDataV2: undefined};

        userEndpoint.getTerms().subscribe(response => {
            this.loading = false;
            this.requirePollData = response.requirePollData;
            if (this.requirePollData) {
                this.model.pollDataV2 = this.pollData;
            }
            this.username = response.userName;
            this.hakaHomeOrganization = response.hakaHomeOrganization;
            this.terms = response.terms.map(t => new TermsTab(t.agreementId, t.terms));
            this.terms[0].viewed = true; // hack: set first tab as viewed
            this.model.agreementIds = response.terms.map(t => t.agreementId);
        });

        this.legalDeposit = settingsService.commonOptions.legalDepositWorkstation;
    }

    get isCustomRoleRequired() {
        return this.formGroup.get('studyRoles').get(StudyRole.MUU).value === true;
    }

    get isCustomPurposeRequired() {
        return this.formGroup.get('studyPurposes').get(StudyPurpose.MUU).value === true;
    }

    private serializeForm(formRawValue: any) {
        this.pollData.educationPlace = formRawValue.studyPlace;
        this.pollData.studyPurposes = toList(formRawValue.studyPurposes) as StudyPurpose[];
        this.pollData.customStudyPurpose = formRawValue.customPurpose;
        this.pollData.studyRoles = toList(formRawValue.studyRoles) as StudyRole[];
        this.pollData.customStudyRole = formRawValue.customRole;
        this.pollData.studyFields = formRawValue.studyFields;
        this.pollData.studyPrograms = formRawValue.studyPrograms;
        this.pollData.usagePurpose = formRawValue.description;
    }

    get showPoll() {
        return this.requirePollData && this.pollData != null;
    }

    get homeOrg(): any {
        return {
            homeOrganization: this.hakaHomeOrganization || '...'
        };
    }

    get allTermsViewed() {
        return this.terms.every(t => t.viewed);
    }

    get formattedResult(): string {
        return this.formatPollData(this.pollData);
    }

    formatPollData(pollData: AddUsagePollDataDTOv2): string {
        const rows: ResultEntry[] = [
            new ResultEntry('education-place', pollData.educationPlace, this.translateService, this.educationPlaces),
            new ResultEntry('study-roles', pollData.studyRoles, this.translateService, this.roles),
            new ResultEntry('custom-study-role', pollData.customStudyRole, this.translateService),
            new ResultEntry('study-purposes', pollData.studyPurposes, this.translateService, this.purposes),
            new ResultEntry('custom-study-purpose', pollData.customStudyPurpose, this.translateService),
            new ResultEntry('research-field-classifications', pollData.studyFields, this.translateService, this.fields),
            new ResultEntry('field-of-education', pollData.studyPrograms, this.translateService, this.programs),
            new ResultEntry('description-short', pollData.usagePurpose, this.translateService),
        ];

        return rows.map(a => a.toString()).join("\n");
    }

    confirm() {
        this.submitting = true;
        this.userEndpoint.confirmTerms(this.model).subscribe((result) => {
            this.submitting = false;
            this.downloadUrl = "/terms/download/" + result.downloadId + "/haka-kayttoehdot.txt?soid=" + this.model.agreementIds.join(";");
        }, () => {
            this.submitting = false;
        });
    }

    close() {
        this.dialogRef.close(ConfirmationResult.CONFIRMED);
    }

    skip() {
        this.dialogRef.close(ConfirmationResult.SKIPPED);
    }

    beforeChangeTab($event: NgbNavChangeEvent) {
        for (const t of this.terms) {
            if (t.templateId === $event.nextId)
                t.viewed = true;
        }
    }

    showInvalid(controlName: string): boolean {
        const control = this.formGroup.get(controlName);
        return control.touched && control.invalid;
    }

    fieldPopulated(field: string): boolean {
        const otherValue = this.formGroup.get(field).value;
        return Array.isArray(otherValue) && otherValue.length > 0 && !!otherValue;
    }

    /*
    private populateValues() {
        this.formGroup.get('studyPlace').setValue('YO');
        this.formGroup.get('studyRoles').get(this.roles[0].value).setValue(true);
        this.formGroup.get('studyPurposes').get(this.purposes[0].value).setValue(true);
        this.formGroup.get('studyFields').setValue(['FOOD_ENGINEERING']);
        this.formGroup.get('studyPrograms').setValue(['ORTHOTICS_AND_PROSTHETICS']);
        this.formGroup.get('description').setValue('Lorem ipsum dolor sit amet');
    }
    */

    onChange($event: StepperSelectionEvent) {
        if ($event.selectedIndex === 1)
            this.step2Viewed = true;
    }
}
