import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {
    ArticleSearchRestEndpoint,
    ArticleSearchResultsBatch,
    BindingSearchCriteria,
    BindingSearchRestEndpoint,
    BindingSearchResultsBatch,
    CollectionRestEndpoint,
    CollectionViewDTO,
    ComponentPartSearchResultsBatch,
    ImportTime,
    MaterialType,
    QueryOrder,
    UserArticleBrowseOrder,
    UserArticleRow,
    UserArticleSearchCriteria
} from "../../apina-digiweb";
import {SettingsService} from "../settings.service";
import {TranslateService} from "@ngx-translate/core";
import {BreadcrumbsService} from "../breadcrumbs/breadcrumbs.service";
import {NavigationService} from "../navigation.service";
import {ProcessedBindingSearchResultRow, ProcessedUserArticleRow, ResultMode} from "../search/result/result-row";
import {SearchService} from "../search/search.service";
import {Subject, Subscription} from "rxjs";
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {AccountService} from "../account/account.service";
import {HttpParams} from "@angular/common/http";
import {PlatformService} from "../platform.service";
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";

const CROP_LENGTH = 500;
const MAX_THUMBS = 200;

@Component({
    selector: 'app-view-collection',
    template: `
        <div><!-- *ngIf prevents access to ViewChild("fttText") -->
            <div *ngIf="details" class="banner kk-bg-rose" [ngStyle]="{'background-image': 'url(' + details.bannerUrl + ')'}">
                <div class="gradient">
                    <div class="container">
                        <h1 style="margin: 0;">{{details.name}}</h1>
                    </div>
                </div>
            </div>
            <div class="kk-bg-dark pt-3 pb-3">
                <div class="container">
                    <div class="ftt-container">
                        <input type="checkbox" id="showEntireDescription" class="ftt-toggle" #fttToggle/>
                        <div class="ftt-text collection-description" [innerHTML]="details?.description" #fttText></div>

                        <div class="ftt-text">
                            <a target="_new" style="text-align:left; text-decoration: underline; font-weight: normal; color:lightgray; " href="{{'oaipmh.link'|translate}}{{this.id}}">OAI-PMH</a>
                            <br>
                        </div>

                        <div class="ftt-gradient" *ngIf="detailsOverflow"></div>
                        
                    </div>
                    
                    <div class="text-center" *ngIf="detailsOverflow">
                        
                        <button type="button" class="button btn btn-kk-auto" (click)="fttToggle.click()">
                            <ng-container *ngIf="fttToggle.checked">
                                <i class="fa fa-angle-double-up"></i>&nbsp;<span translate>collections.minimize-description</span>
                            </ng-container>
                            <ng-container *ngIf="!fttToggle.checked">
                                <i class="fa fa-angle-double-down"></i>&nbsp;<span translate>collections.show-full-description</span>
                            </ng-container>
                        </button>
                    </div>
                </div>
            </div>

            <div class="container mt-5 mb-5" *ngIf="clippingsEnabled">
                <div *ngIf="clippingResults else loading">
                    <h3 translate class="results-header me-3">collections.featured-clippings </h3>

                    <a routerLink="/clippings" [queryParams]="{orderBy: 'PUBLICATION_DATE_DESC', collection: id}">
                        <button *ngIf="clippingResults && clippingResults.totalResults > 0" class="btn btn-kk-blue ms-1" translate>
                            collections.link-to-article-search
                        </button>
                    </a>
                    <a *ngIf="isWindows" [href]="formatDownloadtoolUrl('/clippings?', 'PUBLICATION_DATE_DESC', id)">
                        <button *ngIf="clippingResults && clippingResults.totalResults > 0" #dlToolPopOver="ngbPopover" (click)="activatePopupTimer(dlToolPopOver)" class="btn btn-kk-blue ms-1" [ngbPopover]="downloadtoolPopupText" [popoverTitle]="downloadtoolPopupTitle" translate>
                            collections.link-to-downloadtool
                        </button>
                    </a>

                    <div class="mt-3">
                        <app-search-result-count-badge [total]="clippingResults.totalResults"></app-search-result-count-badge>
                    </div>

                    <app-article-search-results [items]="clippingRows" [resultMode]="resultModes.THUMB"></app-article-search-results>
                    
                    <a routerLink="/clippings" [queryParams]="{orderBy: 'PUBLICATION_DATE_DESC', collection: id}">
                        <button *ngIf="clippingResults && clippingResults.totalResults > 0" class="btn btn-kk-blue ms-1" translate>
                            collections.link-to-article-search
                        </button>
                    </a>
                    <a *ngIf="isWindows" [href]="formatDownloadtoolUrl('/clippings?', 'PUBLICATION_DATE_DESC', id)">
                        <button *ngIf="clippingResults && clippingResults.totalResults > 0" #dlToolPopOver="ngbPopover" (click)="activatePopupTimer(dlToolPopOver)" class="btn btn-kk-blue ms-1" [ngbPopover]="downloadtoolPopupText" [popoverTitle]="downloadtoolPopupTitle" translate>
                            collections.link-to-downloadtool
                        </button>
                    </a>
                </div>
            </div>
        </div>

        <div class="container mt-5 mb-5" *ngIf="bindingsEnabled">
            <div *ngIf="bindingResults else loading">
                <h3 translate class="results-header me-3">collections.featured-bindings</h3>

                <a routerLink="/search" [queryParams]="{orderBy: 'DATE_DESC', collection: id}">
                    <button *ngIf="bindingResults && bindingResults.totalResults > 0" class="btn btn-kk-blue ms-1" translate>
                        collections.link-to-binding-search
                    </button>
                </a>
                <a *ngIf="isWindows" [href]="formatDownloadtoolUrl('/search?', 'DATE_DESC', id)">
                    <button *ngIf="bindingResults && bindingResults.totalResults > 0" #dlToolPopOver="ngbPopover" (click)="activatePopupTimer(dlToolPopOver)" class="btn btn-kk-blue ms-1" [ngbPopover]="downloadtoolPopupText" [popoverTitle]="downloadtoolPopupTitle" translate>
                        collections.link-to-downloadtool
                    </button>
                </a>

                <div class="mt-3">
                    <app-search-result-count-badge [total]="bindingResults.totalResults"></app-search-result-count-badge>
                </div>

                <app-binding-search-results [resultMode]="resultModes.THUMB" [items]="bindingRows"></app-binding-search-results>

                <a routerLink="/search" [queryParams]="{orderBy: 'DATE_DESC', collection: id}">
                    <button *ngIf="bindingResults && bindingResults.totalResults > 0" class="btn btn-kk-blue ms-1" translate>
                        collections.link-to-binding-search
                    </button>
                </a>
                <a *ngIf="isWindows" [href]="formatDownloadtoolUrl('/search?', 'DATE_DESC', id)">
                    <button *ngIf="bindingResults && bindingResults.totalResults > 0" #dlToolPopOver="ngbPopover" (click)="activatePopupTimer(dlToolPopOver)" class="btn btn-kk-blue ms-1" [ngbPopover]="downloadtoolPopupText" [popoverTitle]="downloadtoolPopupTitle" translate>
                        collections.link-to-downloadtool
                    </button>
                </a>
            </div>
        </div>


        <div class="container mt-5 mb-5" *ngIf="cpEnabled">
            <div *ngIf="componentPartResults else loading">
                <h3 translate class="results-header me-3">collections.featured-component-parts</h3>

                <a routerLink="/search" [queryParams]="{orderBy: 'DATE_DESC', collection: id, resultType: 'COMPONENT_PARTS'}">
                    <button *ngIf="componentPartResults && componentPartResults.totalResults > 0" class="btn btn-kk-blue" translate>
                        collections.link-to-component-part-search
                    </button>
                </a>

                <div class="mt-3">
                    <app-search-result-count-badge [total]="componentPartResults.totalResults"></app-search-result-count-badge>
                </div>

                <app-binding-search-results [resultMode]="resultModes.THUMB" [items]="componentPartRows"></app-binding-search-results>

                <a routerLink="/search" [queryParams]="{orderBy: 'DATE_DESC', collection: id, resultType: 'COMPONENT_PARTS'}">
                    <button *ngIf="componentPartResults && componentPartResults.totalResults > 0" class="btn btn-kk-blue" translate>
                        collections.link-to-component-part-search
                    </button>
                </a>
            </div>
        </div>

        <ng-template #downloadtoolPopupTitle><b translate>search.downloadtool.popup-title</b></ng-template>
        <ng-template #downloadtoolPopupText><b [innerHtml]="'search.downloadtool.popup-text' | translate"></b></ng-template>
        
        <ng-template #loading>
            <div class="text-center">
                <app-progress-spinner></app-progress-spinner>
            </div>
        </ng-template>
    `,
    styleUrls: [
        "./view-collection.scss",
        "./banner.scss",
        "./full-text-toggle.scss"
    ]
})

// was IMPORT_DATE

export class ViewCollectionComponent implements OnInit, OnDestroy {
    readonly resultModes = ResultMode;

    id: number;

    public totalShown = MAX_THUMBS;
    details: CollectionViewDTO;

    bindingsEnabled = false;
    cpEnabled = false;
    clippingsEnabled = false;

    bindingResults: BindingSearchResultsBatch;
    componentPartResults: ComponentPartSearchResultsBatch;
    clippingResults: ArticleSearchResultsBatch;
    bindingRows: ProcessedBindingSearchResultRow[] | null;
    componentPartRows: ProcessedBindingSearchResultRow[] | null;
    clippingRows: ProcessedUserArticleRow[] | null;
    materialTypes$ = new Subject<MaterialType[]>();
    detailsOverflow = false;

    @ViewChild("fttText") fttText: ElementRef;

    private readonly sub = new Subscription();
    private isUserHAKAUser: boolean;
    isWindows = false;

    constructor(private readonly collectionsApi: CollectionRestEndpoint,
                private readonly searchService: SearchService,
                private readonly settingsService: SettingsService,
                private readonly bindingSearchRestEndpoint: BindingSearchRestEndpoint,
                private readonly articleSearchRestEndpoint: ArticleSearchRestEndpoint,
                private readonly translateService: TranslateService,
                private readonly breadcrumbsService: BreadcrumbsService,
                private readonly navigationService: NavigationService,
                private readonly sanitizer: DomSanitizer,
                private readonly accountService: AccountService,
                private readonly platformService: PlatformService
    ) {
        this.isWindows = platformService.isWindows();
    }

    ngOnInit() {
        this.isUserHAKAUser = false;

        this.accountService.currentUserInfo$.subscribe(info => {
            this.isUserHAKAUser = info.hakaUser;
        });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    @Input() set collectionId(value: number) {
        this.id = value;
        if (this.id != null)
            this.refresh();
    }

    refresh(): void {
        this.sub.add(this.collectionsApi.getCollection(this.id).subscribe(details => {
            this.details = details;
            this.materialTypes$.next(details.materialTypes);
            this.breadcrumbsService.setLatestLocation([
                {
                    translationKey: "collections.title",
                    commands: [this.navigationService.basePaths.collections]
                },
                {
                    localizedText: this.details.name,
                    commands: [this.navigationService.basePaths.collections],
                    queryParams: {
                        id: this.id
                    }
                }
            ]);

            // we need to render the changes before calculating div sizes
            setTimeout(() => {
                this.calculateTextOverflow();
            })
        }));

        this.sub.add(this.materialTypes$.subscribe((types) => {
            if (types.includes(MaterialType.BINDING)) {
                this.bindingsEnabled = true;
                const activeCriteria = createBindingCriteria(this.id);
                this.bindingSearchRestEndpoint.searchBindings(activeCriteria, 0, MAX_THUMBS).subscribe(results => {
                    this.bindingResults = results;
                    this.bindingRows = this.searchService.processBindingRows(results.rows);
                });
            }
            if (types.includes(MaterialType.COMPONENT_PART)) {
                this.cpEnabled = true;
                const activeCriteria = createBindingCriteria(this.id);
                this.bindingSearchRestEndpoint.searchComponentParts(activeCriteria, 0, MAX_THUMBS).subscribe(results => {
                    this.componentPartResults = results;
                    this.componentPartRows = this.searchService.processComponentPartRows(results.rows);
                });
            }
            if (types.includes(MaterialType.CLIPPING)) {
                this.clippingsEnabled = true;
                const activeCriteria = createClippingCriteria(this.id);
                this.articleSearchRestEndpoint.searchArticles(activeCriteria, 0, MAX_THUMBS).subscribe(results => {
                    this.clippingResults = results;
                    this.clippingRows = processArticleRows(results.rows);
                });
            }
        }));
    }

    private calculateTextOverflow() {
        const el = this.fttText.nativeElement as HTMLDivElement;
        this.detailsOverflow = el.clientHeight < el.scrollHeight;
    }

    formatDownloadtoolUrl(path: string, orderBy: string, collectionId: number): SafeUrl {
        const params = new HttpParams()
            .set('orderBy', orderBy)
            .set('collection', collectionId.toString());
        return this.sanitizer.bypassSecurityTrustUrl("digidownloadtool://?searchUrl=" + encodeURIComponent(window.location.origin + path + params.toString()) + "&haka=" + this.isUserHAKAUser);
    }

    activatePopupTimer(dlToolPopOver: NgbPopover) {
        setTimeout(()  => {
            if(dlToolPopOver.isOpen()){
                dlToolPopOver.close();
            }}, 10000);
    }
}

function processArticleRows(rows: UserArticleRow[]): ProcessedUserArticleRow[] {
    return rows.map(originalRow => {
        const row: ProcessedUserArticleRow = Object.assign({}, originalRow) as any;
        row.highLights = [];
        row.displayOCR = false;
        return row;
    });
}

function createBindingCriteria(collectionId: number): BindingSearchCriteria {
    return {
        authors: [],
        collections: [collectionId],
        districts: [],
        endDate: undefined,
        formats: [],
        fuzzy: false,
        hasIllustrations: false,
        importTime: ImportTime.ANY,
        importStartDate: null,
        languages: [],
        orderBy: QueryOrder.DATE_DESC, // TODO : upper didn't wrk directly?
        pages: "",
        publicationPlaces: [],
        publications: [],
        publishers: [],
        query: "",
        queryTargetsMetadata: false,
        queryTargetsOcrText: false,
        requireAllKeywords: false,
        searchForBindings: false,
        includeUnauthorizedResults: false,
        showLastPage: false,
        startDate: undefined,
        tags: [],
        exactCollectionMaterialType: true
    };
}


function createClippingCriteria(collectionId: number): UserArticleSearchCriteria {
    return {
        categoryIds: [], 
        generalTypes: [], 
        includeCollected: false, 
        keywords: [], 
        onlyCollected: false, 
        subjectIds: [], 
        titles: [],
        collections: [collectionId],
        endDate: undefined,
        fuzzy: false,
        orderBy: UserArticleBrowseOrder.CREATED_DESC, // TODO : upper didn't wrk directly?
        query: "",
        queryTargetsMetadata: false,
        queryTargetsOcrText: false,
        requireAllKeywords: false,
        startDate: undefined,
        exactCollectionMaterialType: true
    }
}
