import {Component, OnDestroy, OnInit} from "@angular/core";
import {AbstractUserArticleSearchComponent, PAGE_SIZE} from "./article-search.component";
import {SettingsService} from "../settings.service";
import {ApinaConfig, ArticleRestEndpoint, ArticleSearchRestEndpoint, ArticleSearchResultsBatch, UserArticleRow, UserArticleSearchCriteria} from "../../apina-digiweb";
import {ErrorService} from "../error.service";
import {Observable} from "rxjs";
import {LoggingService} from "../logging.service";
import {NavigationService} from "../navigation.service";
import {TranslateService} from "@ngx-translate/core";
import {SearchService} from "./search.service";
import {excelFileName} from "../../search/excel-utils";
import {buildQueryString} from "../../utils/url";
import {BasicInfoService} from "../basic-info.service";
import {BreadcrumbsService} from "../breadcrumbs/breadcrumbs.service";
import {shareReplay} from "rxjs/operators";
import {StringInMemoryProvider} from "../popover-picker/in-memory-provider";
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {AccountService} from "../account/account.service";
import {PlatformService} from "../platform.service";
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";

export const SAVED_CONTENT_COMPONENT_NAME = 'savedContent';

@Component({
    selector: "app-saved-content",
    templateUrl: './article-search.html',
    styleUrls: [
        "./binding-search.scss",
        "./article-search.scss"
    ]
})
export class SavedContentComponent extends AbstractUserArticleSearchComponent implements OnInit, OnDestroy {
    private isUserHAKAUser: boolean;
    isWindows = false;

    constructor($log: LoggingService,
                navigationService: NavigationService,
                articleSearchRest: ArticleSearchRestEndpoint,
                errorService: ErrorService,
                settingsService: SettingsService,
                $translate: TranslateService,
                searchService: SearchService,
                basicInfoService: BasicInfoService,
                private sanitizer:DomSanitizer,
                private articleRest: ArticleRestEndpoint,
                private apinaConfig: ApinaConfig,
                private readonly accountService: AccountService,
                private readonly platformService: PlatformService,
                breadcrumbs: BreadcrumbsService) {
        super($log, navigationService, articleSearchRest, errorService, settingsService, searchService, basicInfoService, $translate);

        this.isWindows = platformService.isWindows();

        breadcrumbs.setLocalizedText('scrapbook.saved-content');
    }

    /** For generic template */
    public allowDelete = true;

    ngOnInit() {
        super.$onInit();

        this.articleSearchRest.getStaticReferenceData().subscribe(referenceData => {
            this.referenceData = referenceData;
            this.loading = false;
        }, this.errorService.showUnexpectedError.bind(this.errorService));

        // Most users have very little keywords, but a few have thousands. Filtering the results live on the server is not fast enough, so
        // fetch all the results upon page load (or to be more precise, when the component is rendered), and store them in javascript, where
        // we can filter them quickly.
        const userKeywords$ = this.articleSearchRest.getUserKeywords().pipe(shareReplay(1));

        this.keywordProvider = new StringInMemoryProvider(userKeywords$);

        this.isUserHAKAUser = false;

        this.accountService.currentUserInfo$.subscribe(info => {
            this.isUserHAKAUser = info.hakaUser;
        });
    }

    ngOnDestroy(): void {
        super.$onDestroy();
    }

    protected searchForPage(params: UserArticleSearchCriteria, offset: number): Observable<ArticleSearchResultsBatch> {
        if (!params)
            throw Error("params must not be empty, was: " + params);

        return this.articleSearchRest.searchSavedArticles(params, offset, PAGE_SIZE);
    }
    
    deleteRow(row: UserArticleRow) {
        const deleteConfirmMessage = this.$translate.instant("scrapbook.saved-content.delete-confirm");

        if (confirm(deleteConfirmMessage + '\n"' + (row.title || '-')  + '"?')) {
            // FIXME page doesn't refresh
            this.results.rows = this.results.rows.filter(elem => elem !== row);
            this.articleRest.unlinkArticle(row.articleId).subscribe();
        }
    }

    excelDownloadUrl(): string {
        const filename = excelFileName("articles", this.resultsCriteria.query, this.resultsCriteria.startDate, this.resultsCriteria.endDate);
        return `/search/download/saved-articles/${filename}.xlsx` + buildQueryString({
            criteria: JSON.stringify(this.apinaConfig.serialize(this.resultsCriteria, "UserArticleSearchCriteria"))
        });
    }

    formatDownloadtoolUrl(): SafeUrl {
        return ""; // this.sanitizer.bypassSecurityTrustUrl("digidownloadtool://?searchUrl=" + encodeURIComponent(window.location.href) + "&haka=" + this.isUserHAKAUser);
    }

    activatePopupTimer(dlToolPopOver: NgbPopover) {
        setTimeout(()  => {
            if(dlToolPopOver.isOpen()){
                dlToolPopOver.close();
            }}, 10000);
    }
}

